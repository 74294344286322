import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Autocomplete,
  Switch,
  FormHelperText,
  CircularProgress,
  InputAdornment
} from '@mui/material';
import React, {Fragment, useEffect, useState} from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import Modal from '../../utility/Modal';
import {Link, useNavigate, useParams} from 'react-router-dom';
import UploadDoc from '../../utility/UploadDoc';
import DeleteIcon from '@mui/icons-material/Delete';
import {AddLocationAlt} from '@mui/icons-material';
import FileIcon from '../../../../../assets/document.svg';
import {Country, State, City} from 'country-state-city';
import axios from 'axios';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import {Controller, useForm, useFieldArray} from 'react-hook-form';
import CancelConfirmModal from '../../utility/CancelConfirmModal';
import {AlertColor} from '@mui/material/Alert';
import GoogleMaps from '../../utility/GoogleMaps';
import removeFalsyKeys from 'Shared/Utils/removeFalseyKeys';

type AddCustomerProps = {
  setOpenSnackbar: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      status: AlertColor;
      message: string;
    }>
  >;
};

const AddCustomer = ({setOpenSnackbar}: AddCustomerProps) => {
  const [open, setOpen] = useState(false);
  const [countryId, setCountryId] = useState<string>();
  const [stateId, setStateId] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [isUpdate, setIsUpdate] = useState<boolean>();
  const [customerDetails, setCustomerDetails] = useState<any>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isDeletingAddress, setIsDeletingAddress] = useState(false);
  const [showValue, setShowValue] = useState<any>([
    {
      builtUpArea: {value: '', unit: 'Sq mtr'},
      averageRoomBuildUp: {value: '', unit: 'Sq mtr'}
    }
  ]);

  // const [pincodeValid, setPincodeValid] = useState<any[]>([]);

  const [addressProps, setAddressProps] = useState({
    index: 0,
    addressId: ''
  });
  const [openDatePicker, setOpenDatePicker] = useState<{
    from: boolean;
    to: boolean;
  }>({
    from: false,
    to: false
  });

  const {id} = useParams();

  const todayYear = moment();
  const lastYear = moment().subtract(1, 'years');
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  const pastTwoYears = moment(lastYear).subtract(1, 'years');

  const navigate = useNavigate();

  const defaultValue: formType = {
    customerId: '',
    firstName: '',
    lastName: '',
    email: '',
    userType: 'user',
    phone: '',
    source: 'platform',
    address: 'test',
    taxIdentityNumber: '',
    identityProof: '',
    typeOfBusiness: '',
    profilePhoto: 'userA.png',
    footprintingPeriod: ['', ''],
    category: '',
    incorporateCertificate: '',
    identityProofDocument: '',
    hotelRating: '',
    natureOfBusiness: '',
    businessName: '',
    deletedAddresses: [''],
    isDraft: false,
    addresses: [
      {
        title: '',
        addressLine1: '',
        addressLine2: '',
        branchGst: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        averageCustomer: '',
        builtUpArea: '',
        empCount: '',
        includeInScope: true,
        occupancyRate: '',
        totalRooms: '',
        averageNoOfUnits: '',
        averageRoomBuildUp: '',
        airConditioned: '',
        averageMeals: ''
      }
    ]
  };

  const googleMapsAddress = (data: string[], index: number) => {
    setValue(`addresses.${index}.title`, data?.[0]);
    setValue(`addresses.${index}.addressLine1`, data?.[1]);
    setValue(`addresses.${index}.addressLine2`, data?.[2]);
    setValue(`addresses.${index}.country`, data?.[5], {shouldTouch: true});
    setValue(`addresses.${index}.city`, data?.[3]);
    setValue(`addresses.${index}.state`, data?.[4]);
    setValue(`addresses.${index}.zipCode`, data?.[6]);
  };

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };
    const fetchApi = async () => {
      if (!!id) {
        await axios
          .get(`${BACKEND_ENDPOINT}/user?customerId=${id}`, config)
          .then((res) => {
            if (res?.data?.addresses?.length === 0) {
              setCustomerDetails(res?.data);
              reset({
                ...res?.data,
                addresses: [
                  {
                    title: '',
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    country: '',
                    zipCode: '',
                    averageCustomer: '',
                    builtUpArea: '',
                    empCount: '',
                    includeInScope: false,
                    averageRoomBuildUp: '',
                    averageMeals: ''
                  }
                ]
              });
            } else {
              setCustomerDetails(res?.data);
              reset(res?.data);
              // setPincodeValid(
              //   res?.data.addresses.map((item: any, index: number) => {
              //     return countryZipCodeValide(item.country);
              //   })
              // );
              setShowValue(
                res?.data.addresses.map((item: any) => {
                  return {
                    builtUpArea: {value: item.builtUpArea, unit: 'Sq mtr'},
                    averageRoomBuildUp: {
                      value: item.averageRoomBuildUp,
                      unit: 'Sq mtr'
                    }
                  };
                })
              );
            }
            setIsUpdate(true);
            setValue('customerId', id);
            setFile1(res?.data?.incorporateCertificate?.split('/').pop());
            setFile2(res?.data?.identityProofDocument?.split('/').pop());
            return;
          });
      }
    };

    fetchApi().catch((err) => {
      setOpenSnackbar((prev) => {
        return {
          ...prev,
          open: true,
          status: 'error',
          message: err?.response?.data?.message
        };
      });
    });
  }, [id]);

  const singleCountry = (name: string) => {
    return Country.getAllCountries().filter((value, i) => {
      return value.name === name;
    });
  };

  const singleState = (countryId: string, name: string) => {
    return State.getStatesOfCountry(countryId).filter((value, i) => {
      return value?.name === name;
    });
  };

  const cat = [
    {name: 'Hotel', value: 'hotel'},
    {name: 'Restaurant', value: 'restaurant'},
    {name: 'Retail', value: 'retail'},
    {name: 'Automotive Industry', value: 'automotive'}
  ];
  const hotelRating = [
    {name: '1 Star', value: '1 Star'},
    {name: '2 Star', value: '2 Star'},
    {name: '3 Star', value: '3 Star'},
    {name: '4 Star', value: '4 Star'},
    {name: '5 Star', value: '5 Star'}
  ];

  const hotelSelect = [
    {name: 'Business Hotel', value: 'Business Hotel'},
    {name: 'Resort', value: 'Resort'},
    {name: 'Villa', value: 'Villa'},
    {name: 'Inn', value: 'Inn'},
    {name: 'Service Apartment', value: 'service Apartment'}
  ];
  const retailSelect = [
    {name: 'Clothing', value: 'clothing'},
    {name: 'Footwear', value: 'footwear'},
    {name: 'Hardware', value: 'hardware'},
    {name: 'Consumer Goods', value: 'consumer Goods'},
    {name: 'Departmental Store', value: 'departmental Store'},
    {name: 'Grocery', value: 'grocery'}
  ];
  const restuarantSelect = [
    {name: 'Fine Dining', value: 'fine Dining'},
    {name: 'Pub or Bar ', value: 'Pub or Bar'},
    {name: 'Quick Service Restuarant', value: 'quick Service Restaurant'},
    {name: 'Casual Dining', value: 'casual Dining'},
    {name: 'Dine In', value: 'dine In'},
    {name: 'Cafe', value: 'cafe'}
  ];

  const typeOfBusiness = [
    {name: 'Sole Proprietorship', value: 'sole proprietorship'},
    {name: 'Partnership', value: 'partnership'},
    {
      name: 'Limited Liability Partnership',
      value: 'limited liability partnership'
    },
    {name: 'Private Limited Company', value: 'private limited company'},
    {name: 'Public Limited Company', value: 'public limited company'},
    {name: 'Other', value: 'other'}
  ];

  const retailAddressSelect = [
    {name: 'Less Than 50% ', value: 'lessThan50'},
    {name: 'More Than 50% ', value: 'moreThan50'}
  ];

  type addressType = {
    title: string;
    addressLine1: string;
    addressLine2: string;
    branchGst: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    builtUpArea: string;
    empCount: string;
    averageCustomer: string;
    averageNoOfUnits: string;
    includeInScope: boolean;
    occupancyRate?: string;
    totalRooms?: string;
    averageRoomBuildUp: string;
    airConditioned: string;
    averageMeals: string;
  };

  type formType = {
    customerId?: string;
    firstName: string;
    lastName: string;
    email: string;
    userType: string;
    isDraft: boolean;
    phone: string;
    source: string;
    address: string;
    taxIdentityNumber: string;
    identityProof: string;
    profilePhoto: string;
    footprintingPeriod: any[];
    identityProofDocument: string;
    incorporateCertificate: string;
    category: string;
    hotelRating: string;
    deletedAddresses: any[];
    createdBy?: string;
    updatedBy?: string;
    natureOfBusiness: string;
    typeOfBusiness: string;
    businessName: string;
    addresses: addressType[];
  };

  const onDraft = (data: formType) => {
    data.isDraft = true;

    const filteredData = removeFalsyKeys(data);

    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      const method = !!id ? 'put' : 'post';

      await axios
        .request({
          method: method,
          url: `${BACKEND_ENDPOINT}/user`,
          data: filteredData,
          headers: config.headers
        })
        .then((res) => {
          if (res?.data?.status === 'error') {
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'error',
                message: res?.data?.message
              };
            });
          } else {
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: isUpdate
                  ? 'Customer details have been updated as Draft'
                  : 'Customer added successfully as Draft'
              };
            });
            reset(defaultValue);
            navigate('/customer/list');
          }
        });
    };

    fetchApi().catch((err) => {
      setOpenSnackbar((prev) => {
        return {
          ...prev,
          open: true,
          status: 'error',
          message: err?.response?.data?.message
        };
      });
    });
  };

  const onSubmit = (data: any) => {
    const atleastOneIncluded = data.addresses.some(
      (item: addressType) => item.includeInScope
    );

    if (!atleastOneIncluded) {
      data.addresses[0].includeInScope = true;
    }

    data.isDraft = false;
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      const method = !!id ? 'put' : 'post';

      await axios
        .request({
          method: method,
          url: `${BACKEND_ENDPOINT}/user`,
          data: data,
          headers: config.headers
        })
        .then((res) => {
          if (res?.data?.status === 'error') {
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'error',
                message: res?.data?.message
              };
            });
          } else {
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: isUpdate
                  ? 'Customer details have been updated'
                  : 'Customer added successfully'
              };
            });
            reset(defaultValue);
            navigate(
              `/customer/${
                isUpdate
                  ? customerDetails?.companyId
                  : res?.data?.companyObj?._id
              }/payment`,
              {
                state: getValues()
              }
            );
          }
        });
    };
    fetchApi().catch((err) => {
      setOpenSnackbar((prev) => {
        return {
          ...prev,
          open: true,
          status: 'error',
          message: err?.response?.data?.message
        };
      });
    });
  };

  const {
    register,
    control,
    watch,
    reset,
    trigger,
    getValues,
    setValue,
    clearErrors,
    formState: {defaultValues, errors, dirtyFields}
  } = useForm<formType>({
    defaultValues: defaultValue,
    mode: 'onChange'
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'addresses'
  });

  const fileUploadObject = [
    {
      name: 'Incorporation Certificate',
      fileName: file1
    },
    {
      name: 'Company PAN',
      fileName: file2
    }
  ];

  const handleCancel = () => {
    setShowDialog(false);
    navigate('/customer/list');
    return;
  };

  const handleCloseDeleteAddress = () => {
    return setIsDeletingAddress(false);
  };

  const handleDeleteAddress = (i: number, id: string) => {
    const deleted = [...watch('deletedAddresses', [])];
    deleted.push(id);
    setValue('deletedAddresses', deleted);
    remove(i);
    setIsDeletingAddress(false);
    showValue.splice(i, 1);
  };

  // new feature
  const units = ['Sq mtr', 'Sq ft'];

  // const countryZipCodeValide = (country: string) => {
  //   console.log('country:', country);
  //   const isUSA = country === 'United States';
  //   console.log('isUSA:', isUSA);
  //   const isUk = country === 'United Kingdom';
  //   console.log('isUk:', isUk);
  //   const minValue = isUSA ? 5 : isUk ? 6 : 6;
  //   const maxValue = isUSA ? 5 : isUk ? 8 : 6;
  //   // console.log('reg', isUSA ? '^d{5}$' : '^[1-9][0-9]{5}$');
  //   return {
  //     regex: isUSA
  //       ? '^d{5}$'
  //       : isUk
  //       ? '^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][A-Za-z]{2}$'
  //       : '^[1-9][0-9]{5}$',
  //     min: {
  //       value: minValue,
  //       message: `Pincode Should Not be Less Than ${minValue} digit`
  //     },
  //     max: {
  //       value: maxValue,
  //       message: `Pincode Should Not be More Than ${maxValue} digit`
  //     }
  //   };
  // };

  const handleValueChange = (event: any, i: number) => {
    const {value, id, name} = event.target;
    setShowValue(
      (
        prevItems: {
          builtUpArea: {value: string; unit: string};
          averageRoomBuildUp: {value: string; unit: string};
        }[]
      ) => {
        return prevItems?.map((item: any, index: number) => {
          return index === i
            ? {...item, [id]: {...item[id], value: value}}
            : item;
        });
      }
    );

    setValue(name, setNewValue(showValue?.[i]?.[id]?.unit, event.target.value));
  };

  const handleUnitChange = (event: any, i: number, type: string) => {
    const {value, name} = event.target;
    setShowValue((prev: any) => {
      return prev.map((item: any, index: number) => {
        return index === i
          ? {...item, [type]: {...item[type], unit: value}}
          : item;
      });
    });
    setValue(
      name as any,
      setNewValue(event.target.value, showValue[i][type].value)
    );
  };

  const setNewValue = (unit: string, value: number) => {
    return Math.round(value * (unit === 'Sq mtr' ? 1 : 0.092903));
  };

  return !(id === undefined) && !customerDetails ? (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress disableShrink color='success' />
    </Box>
  ) : (
    <Box className='innerPage'>
      <Modal
        open={isDirty && showDialog}
        setOpen={setShowDialog}
        children={
          <CancelConfirmModal
            handleOk={async () => {
              clearErrors();
              if (
                await trigger([
                  'firstName',
                  'lastName',
                  'email',
                  'phone',
                  'businessName'
                ])
              ) {
                onDraft(watch());
              } else {
                setShowDialog(false);
              }
            }}
            handleCancel={handleCancel}
            textProps={{
              mainText:
                'Data will be lost if not saved! Save the form before you leave!',
              cancelText: 'Cancel',
              confirmText: 'Save as draft'
            }}
          />
        }
      />

      {isDeletingAddress && (
        <Modal
          open={isDeletingAddress}
          setOpen={setIsDeletingAddress}
          children={
            <CancelConfirmModal
              handleOk={() =>
                handleDeleteAddress(
                  addressProps?.index,
                  addressProps?.addressId
                )
              }
              handleCancel={handleCloseDeleteAddress}
              textProps={{
                mainText: 'Are you sure you want to delete this address?',
                cancelText: 'No',
                confirmText: 'Yes'
              }}
            />
          }
        />
      )}
      <Box>
        <Button
          className='back-btn'
          sx={{padding: '0', marginBottom: '10px'}}
          onClick={() => {
            setShowDialog(true);
          }}
        >
          <Link to={isDirty ? '#' : '/customer/list'}>
            <KeyboardArrowLeftIcon /> Back
          </Link>
        </Button>
      </Box>
      <h1 className='pageTitle' style={{marginBottom: '20px'}}>
        {`${isUpdate ? 'Edit' : 'Add'} Customer's Info`}
      </h1>
      <Box className='formData-view formSection'>
        <Box className='data-title'>Personal Info</Box>
        <Box className='form-view'>
          <Box className='form-data'>
            <Controller
              name='firstName'
              control={control}
              defaultValue=''
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                  label='First Name *'
                  variant='outlined'
                  value={value.charAt(0).toUpperCase() + value.slice(1)}
                  {...register('firstName', {
                    required: 'First Name is required',
                    minLength: {
                      value: 2,
                      message: 'First Name Should be more than 1 letters'
                    },
                    maxLength: {
                      value: 100,
                      message: 'First Name Should not be more than 100 letters'
                    },
                    validate: {
                      reg: (value) =>
                        RegExp(/^[A-Za-z]+$/).test(value) ||
                        'No special characters , Empty spaces or Numbers are Allowed'
                    }
                  })}
                  onChange={onChange}
                  error={!!error}
                  helperText={!!error ? error?.message : null}
                />
              )}
            />
          </Box>
          <Box className='form-data'>
            <Controller
              name='lastName'
              control={control}
              defaultValue=''
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                  label='Last Name  *'
                  variant='outlined'
                  value={value.charAt(0).toUpperCase() + value.slice(1)}
                  {...register('lastName', {
                    required: 'Last Name is required',
                    minLength: {
                      value: 2,
                      message: 'Last Name Should be more than 1 letters'
                    },
                    maxLength: {
                      value: 100,
                      message: 'Last Name Should not be more than 100 letters'
                    },
                    validate: {
                      reg: (value) =>
                        RegExp(/^[A-Za-z]+$/).test(value) ||
                        'No special characters , Empty spaces or Numbers are Allowed'
                    }
                  })}
                  onChange={onChange}
                  error={!!error}
                  helperText={!!error ? error?.message : null}
                />
              )}
            />
          </Box>
          <Box className='form-data'>
            <Controller
              name='phone'
              control={control}
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                  id='mobileNumber'
                  label='Mobile Number *'
                  variant='outlined'
                  value={value.replace(/[^+\d]/g, '')}
                  {...register('phone', {
                    required: 'Mobile Number is required',
                    validate: {
                      reg: (value) =>
                        RegExp(/^(?:\+91|0)?[6-9]\d{9}$/).test(value) ||
                        'Please Enter Valid Mobile Number'
                    }
                  })}
                  onKeyDown={(event) => {
                    if (
                      event.code === 'ArrowDown' ||
                      event.code === 'ArrowUp' ||
                      event.code === 'Enter'
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onFocus={(e) => {
                    e.target.addEventListener(
                      'wheel',
                      function (e) {
                        e.preventDefault();
                      },
                      {passive: false}
                    );
                  }}
                  onChange={onChange}
                  error={!!error}
                  helperText={!!error ? error?.message : null}
                />
              )}
            />
          </Box>
          <Box className='form-data'>
            <Controller
              name='email'
              control={control}
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                  label='Email ID *'
                  variant='outlined'
                  value={value}
                  {...register('email', {
                    required: 'Email ID is required',
                    minLength: {
                      value: 5,
                      message: 'Email ID Should be more than 4 letters'
                    },
                    maxLength: {
                      value: 320,
                      message: 'Email ID Should not be more than 320 letters'
                    },
                    validate: {
                      reg: (value) =>
                        RegExp(
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
                        ).test(value) || 'Please Enter Valid Email ID'
                    }
                  })}
                  onChange={onChange}
                  error={!!error}
                  helperText={!!error ? error?.message : null}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box className='formData-view formSection'>
        <Box className='data-title'>Business Details</Box>
        <Box className='form-view'>
          <Box className='form-data'>
            <Controller
              name='businessName'
              control={control}
              defaultValue=''
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                  label='Business Name *'
                  variant='outlined'
                  value={
                    value.replace(/\b\w/g, (match) => match.toUpperCase()) || ''
                  }
                  {...register('businessName', {
                    required: 'Business Name is required',
                    minLength: {
                      value: 3,
                      message: 'Business Name Should be more than 2 letters'
                    },
                    maxLength: {
                      value: 100,
                      message:
                        'Business Name Should not be more than 100 letters'
                    },
                    validate: {
                      reg: (value) =>
                        RegExp(`^[A-Za-z0-9&',.() -]+$`).test(value) ||
                        "No special characters are allowed Excluding &',.() -"
                    }
                  })}
                  onChange={onChange}
                  error={!!error}
                  helperText={!!error ? error?.message : null}
                />
              )}
            />
          </Box>
          <Box className='form-data'>
            <Controller
              name='taxIdentityNumber'
              control={control}
              defaultValue=''
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                  label='GST Number *'
                  variant='outlined'
                  value={value.toUpperCase()}
                  {...register('taxIdentityNumber', {
                    required: 'GST Number is required',
                    validate: {
                      reg: (value) =>
                        RegExp(
                          '^(?!.*00)([0-3]{1}[0-8]{1}|99|97)[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Za-z]{1}$'
                        ).test(value) ||
                        'Please Enter 15 Digit Valid GST Number'
                    }
                  })}
                  onChange={onChange}
                  // InputProps={{
                  //   endAdornment: (
                  //     <Button sx={{ fontSize: "10px" }}>
                  //       <Typography>Verify</Typography>
                  //     </Button>
                  //   ),
                  // }}
                  error={!!error}
                  helperText={!!error ? error?.message : null}
                />
              )}
            />
          </Box>
          <Box className='form-data'>
            <FormControl error={!!errors.category ? true : false}>
              <InputLabel id='category'>Category *</InputLabel>
              <Controller
                name='category'
                control={control}
                defaultValue={defaultValues?.category || ''}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                  <>
                    <Select
                      labelId='category'
                      label='Category'
                      {...register('category', {
                        min: {
                          value: 2,
                          message: 'Category is Required'
                        },
                        validate: {
                          noValue: (value) => {
                            return value !== '' || 'Category is Required';
                          }
                        }
                      })}
                      value={value || ''}
                      onChange={onChange}
                      variant='outlined'
                    >
                      {cat.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el.value}>
                            {el.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {error ? error?.message : null}
                    </FormHelperText>
                  </>
                )}
              />
            </FormControl>
          </Box>
          <Box className='form-data'>
            <FormControl error={!!errors.typeOfBusiness ? true : false}>
              <InputLabel id='category'>Type of Business *</InputLabel>
              <Controller
                name='typeOfBusiness'
                control={control}
                defaultValue=''
                render={({field: {onChange, value}, fieldState: {error}}) => (
                  <>
                    <Select
                      id='typeOfBusiness'
                      labelId='category'
                      label='Type of Business *'
                      value={value}
                      {...register('typeOfBusiness', {
                        min: {
                          value: 2,
                          message: 'Type of Business is Required'
                        },
                        validate: {
                          noValue: (value) => {
                            return (
                              value !== '' || 'Type of Business is Required'
                            );
                          }
                        }
                      })}
                      onChange={onChange}
                      variant='outlined'
                    >
                      {watch('category') === '' ? (
                        <MenuItem disabled>
                          Please Select Category First
                        </MenuItem>
                      ) : (
                        typeOfBusiness.map((item, i) => {
                          return (
                            <MenuItem key={i} value={item.value}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                    <FormHelperText>
                      {!!error ? error?.message : null}
                    </FormHelperText>
                  </>
                )}
              />
            </FormControl>
          </Box>
          {watch('category') === 'hotel' && (
            <Box className='form-data'>
              <FormControl error={!!errors.hotelRating ? true : false}>
                <InputLabel id='category'>Rating *</InputLabel>
                <Controller
                  name='hotelRating'
                  control={control}
                  defaultValue=''
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <>
                      <Select
                        id='typeOfBusiness'
                        labelId='category'
                        label='Rating *'
                        value={value || ''}
                        {...register('hotelRating', {
                          min: {
                            value: 2,
                            message: 'Hotel Rating is Requidred'
                          },
                          validate: {
                            noValue: (value) => {
                              return value !== '' || 'Hotel Rating is Required';
                            }
                          }
                        })}
                        onChange={onChange}
                        variant='outlined'
                      >
                        {hotelRating.map((item, i) => {
                          return (
                            <MenuItem key={i} value={item.value}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {error ? error?.message : null}
                      </FormHelperText>
                    </>
                  )}
                />
              </FormControl>
            </Box>
          )}

          <Box className='form-data'>
            <FormControl error={!!errors.natureOfBusiness ? true : false}>
              <InputLabel id='category'>Nature of Business *</InputLabel>
              <Controller
                name='natureOfBusiness'
                control={control}
                defaultValue=''
                render={({field: {onChange, value}, fieldState: {error}}) => (
                  <>
                    <Select
                      labelId='category'
                      label='Nature of Business *'
                      value={value || ''}
                      {...register('natureOfBusiness', {
                        min: {
                          value: 2,
                          message: 'Nature of Business is Required'
                        },
                        validate: {
                          noValue: (value) => {
                            return (
                              value !== '' || 'Nature of Business is Required'
                            );
                          }
                        }
                      })}
                      onChange={onChange}
                      variant='outlined'
                      error={!!error}
                    >
                      {watch('category') === '' ? (
                        <MenuItem disabled>
                          Please Select Category First
                        </MenuItem>
                      ) : watch('category') === 'hotel' ? (
                        hotelSelect.map((item: any, i: any) => {
                          return (
                            <MenuItem key={i} value={item.value}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      ) : watch('category') === 'restaurant' ? (
                        restuarantSelect.map((item, i) => {
                          return (
                            <MenuItem key={i} value={item.value}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        retailSelect.map((item, i) => {
                          return (
                            <MenuItem key={i} value={item.value}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                    <FormHelperText error>
                      {!!error ? error?.message : null}
                    </FormHelperText>
                  </>
                )}
              />
            </FormControl>
          </Box>
          <Box className='form-data'>
            <Controller
              name='identityProof'
              control={control}
              defaultValue=''
              render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                  label='PAN Number *'
                  variant='outlined'
                  value={value.toUpperCase() || ''}
                  {...register('identityProof', {
                    required: 'PAN Number is required',
                    validate: {
                      reg: (value) =>
                        RegExp(`^[A-Z]{5}[0-9]{4}[A-Z]$`).test(
                          value.toUpperCase()
                        ) || 'Please Enter 10 Digit Valid PAN Number'
                    }
                  })}
                  onChange={onChange}
                  error={!!error}
                  helperText={!!error ? error?.message : null}
                />
              )}
            />
          </Box>
          <Box className='form-data'>
            <TextField
              disabled
              id='NumberOfLocation'
              label='Outlet Count'
              value={
                watch('addresses')?.length === 0
                  ? 1
                  : watch('addresses')?.length
              }
              variant='outlined'
            />
          </Box>
          <Box className='form-data'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name='footprintingPeriod.0'
                control={control}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                  <DesktopDatePicker
                    open={openDatePicker?.from}
                    onClose={() =>
                      setOpenDatePicker({...openDatePicker, from: false})
                    }
                    label='Footprinting From *'
                    inputFormat='YYYY'
                    openTo='year'
                    views={['year']}
                    minDate={pastTwoYears}
                    maxDate={lastYear}
                    value={value || ''}
                    {...register('footprintingPeriod.0', {
                      min: {
                        value: 2,
                        message: 'This Field is Required'
                      },
                      validate: {
                        noValue: (value) => {
                          return value !== '' || 'This Field is Required';
                        },
                        same: (value) => {
                          return (
                            moment(watch('footprintingPeriod.1')).year() !==
                              moment(value).year() ||
                            "From and To Year can't be the same"
                          );
                        }
                      }
                    })}
                    onChange={(event) =>
                      onChange(
                        (event = moment(event)
                          .utc()
                          .year(Number(event?.year()))
                          .month(3)
                          .date(0))
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onClick={() =>
                          setOpenDatePicker({...openDatePicker, from: true})
                        }
                        InputLabelProps={{
                          shrink: value ? true : false
                        }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box className='form-data'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name='footprintingPeriod.1'
                control={control}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                  <DesktopDatePicker
                    open={openDatePicker?.to}
                    onClose={() =>
                      setOpenDatePicker({...openDatePicker, to: false})
                    }
                    label='Footprinting To *'
                    inputFormat='YYYY'
                    openTo='year'
                    views={['year']}
                    minDate={
                      moment(watch('footprintingPeriod.0') || pastTwoYears).add(
                        1,
                        'years'
                      ) || pastTwoYears
                    }
                    maxDate={todayYear}
                    {...register('footprintingPeriod.1', {
                      min: {
                        value: 2,
                        message: 'This Field is Required'
                      },
                      validate: {
                        noValue: (value) => {
                          return value !== '' || 'This Field is Required';
                        },
                        same: (value) => {
                          return (
                            moment(value).year() !==
                              moment(watch('footprintingPeriod.0')).year() ||
                            "From and To Year can't be same"
                          );
                        }
                      }
                    })}
                    onChange={(event) =>
                      onChange(
                        (event = moment(event)
                          .utc()
                          .year(Number(event?.year()))
                          .month(2)
                          .date(30))
                      )
                    }
                    value={value || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onClick={() =>
                          setOpenDatePicker({...openDatePicker, to: true})
                        }
                        InputLabelProps={{
                          shrink: value ? true : false
                        }}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true
                        }}
                        error={!!error}
                        helperText={error ? 'This Field is Required' : null}
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box className='address-container add-address-container'>
            {fields.map((address: any, i: number) => {
              return (
                <Box key={address?.id} className='address-data'>
                  <Box className='address-title'>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      gap={120}
                    >
                      {`Outlet ${i + 1} `}
                    </Box>
                    {customerDetails?._id && i !== 0 ? (
                      <IconButton
                        onClick={() => {
                          setIsDeletingAddress(true);
                          // handleDeleteAddress(i, address._id);
                          setAddressProps((prev: any) => {
                            return {
                              ...prev,
                              index: i,
                              addressId: address?._id
                            };
                          });
                        }}
                      >
                        <DeleteIcon color='inherit' />
                      </IconButton>
                    ) : i === 0 ? null : (
                      <IconButton
                        onClick={() => {
                          // handleDeleteAddress(i, address._id);
                          setIsDeletingAddress(true);
                          setAddressProps((prev: any) => {
                            return {
                              ...prev,
                              index: i,
                              addressId: address?._id
                            };
                          });
                        }}
                      >
                        <DeleteIcon color='inherit' />
                      </IconButton>
                    )}
                  </Box>
                  <Box className='form-address'>
                    <Box className='full-width flex-box'>
                      <>
                        <Box className='form-data'>
                          <Controller
                            name={`addresses.${i}.builtUpArea`}
                            control={control}
                            render={({field: {onChange, value}}) => (
                              <TextField
                                id='builtUpArea'
                                type='number'
                                label='Built up Area *'
                                variant='outlined'
                                value={showValue?.[i].builtUpArea.value}
                                {...register(
                                  `addresses.${i}.builtUpArea` as const,
                                  {
                                    required: 'Built Up Area is required',
                                    valueAsNumber: true,
                                    min: {
                                      value: 11,
                                      message:
                                        'built Up Should Be More Than 10 Sq mts'
                                    },
                                    validate: {
                                      reg: (value: any) =>
                                        RegExp('^[0-9]+$').test(value) ||
                                        'Decimal are not allowed.'
                                    },
                                    onChange: (event) =>
                                      handleValueChange(event, i)
                                  }
                                )}
                                onKeyDown={(event) => {
                                  if (
                                    event.code === 'ArrowDown' ||
                                    event.code === 'ArrowUp' ||
                                    event.code === 'Enter'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onFocus={(e) => {
                                  e.target.addEventListener(
                                    'wheel',
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    {passive: false}
                                  );
                                }}
                                error={!!errors?.addresses?.[i]?.builtUpArea}
                                onWheel={(event) => {
                                  event.currentTarget.blur();
                                }}
                                helperText={
                                  !!errors.addresses?.[i]?.builtUpArea
                                    ? errors.addresses?.[i]?.builtUpArea
                                        ?.message
                                    : null
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <TextField
                                        // id='builtUpArea'
                                        name={`addresses.${i}.builtUpArea`}
                                        select
                                        value={showValue[i].builtUpArea.unit}
                                        onChange={(event) =>
                                          handleUnitChange(
                                            event,
                                            i,
                                            'builtUpArea'
                                          )
                                        }
                                        size='small'
                                        variant='standard'
                                        InputProps={{disableUnderline: true}}
                                        className='unitSelect'
                                      >
                                        {units.map((unit) => (
                                          <MenuItem key={unit} value={unit}>
                                            {unit}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )}
                          />
                        </Box>
                        {/* </Box> */}
                        {/* <TextField
                              autoFocus={false}
                              type='number'
                              label='Built up Area *'
                              variant='outlined'
                              defaultValue={address.builtUpArea}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    Sq mts
                                  </InputAdornment>
                                )
                              }}
                              {...register(
                                `addresses.${i}.builtUpArea` as const,
                                {
                                  required: 'Built Up Area is required',
                                  valueAsNumber: true,
                                  min: {
                                    value: 11,
                                    message:
                                      'built Up Should Be More Than 10 Sq mts'
                                  },
                                  validate: {
                                    reg: (value: any) =>
                                      RegExp('^[0-9]+$').test(value) ||
                                      'Decimal are not allowed.'
                                  }
                                }
                              )}
                              onKeyDown={(event) => {
                                if (
                                  event.code === 'ArrowDown' ||
                                  event.code === 'ArrowUp' ||
                                  event.code === 'Enter'
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              onFocus={(e) => {
                                e.target.addEventListener(
                                  'wheel',
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  {passive: false}
                                );
                              }}
                              error={!!errors?.addresses?.[i]?.builtUpArea}
                              onWheel={(event) => {
                                event.currentTarget.blur();
                              }}
                              helperText={
                                !!errors.addresses?.[i]?.builtUpArea
                                  ? errors.addresses?.[i]?.builtUpArea?.message
                                  : null
                              }
                            />
                          </Box> */}
                        <Box className='form-data'>
                          <TextField
                            label='Number of Employees *'
                            variant='outlined'
                            type='number'
                            {...register(`addresses.${i}.empCount` as const, {
                              required: 'Number of Employees is required',
                              min: {
                                value: 1,
                                message: 'Minimium 1 Employee should be added'
                              },
                              validate: {
                                reg: (value: any) =>
                                  RegExp('^[0-9]+$').test(value) ||
                                  'Decimal are not allowed.'
                              },
                              valueAsNumber: true
                            })}
                            onKeyDown={(event) => {
                              if (
                                event.code === 'ArrowDown' ||
                                event.code === 'ArrowUp' ||
                                event.code === 'Enter'
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onFocus={(e) => {
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                {passive: false}
                              );
                            }}
                            error={!!errors.addresses?.[i]?.empCount}
                            helperText={
                              !!errors.addresses?.[i]?.empCount
                                ? errors.addresses?.[i]?.empCount?.message
                                : null
                            }
                          />
                        </Box>
                      </>
                      <Box className='form-data'>
                        <Box
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                        >
                          <Controller
                            name={`addresses.${i}.includeInScope`}
                            control={control}
                            render={({field: {onChange, value}}) => (
                              <Switch
                                checked={value || false}
                                {...register(
                                  `addresses.${i}.includeInScope` as const
                                )}
                                onChange={onChange}
                              />
                            )}
                          />
                          <Typography>Include In Scope</Typography>
                        </Box>
                      </Box>
                      {watch('category') !== 'automotive' ? (
                        <Box className='form-data'>
                          <Controller
                            name={`addresses.${i}.averageCustomer`}
                            control={control}
                            defaultValue=''
                            render={({
                              field: {onChange, value},
                              fieldState: {error}
                            }) => (
                              <TextField
                                label='Average Patrons'
                                variant='outlined'
                                type='number'
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      Per month
                                    </InputAdornment>
                                  )
                                }}
                                {...register(
                                  `addresses.${i}.averageCustomer` as const,
                                  {
                                    required: false,
                                    min: {
                                      value: 1,
                                      message:
                                        'Average Patrons per Month should be more than 0'
                                    },
                                    validate: {
                                      reg: (value: any) => {
                                        if (!value) return true;
                                        return (
                                          RegExp('^[0-9]+$').test(value) ||
                                          'Decimal are not allowed.'
                                        );
                                      }
                                    },
                                    valueAsNumber: true
                                  }
                                )}
                                onKeyDown={(event) => {
                                  if (
                                    event.code === 'ArrowDown' ||
                                    event.code === 'ArrowUp' ||
                                    event.code === 'Enter'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onFocus={(e) => {
                                  e.target.addEventListener(
                                    'wheel',
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    {passive: false}
                                  );
                                }}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Box>
                      ) : (
                        <Box className='form-data'>
                          <Controller
                            name={`addresses.${i}.averageNoOfUnits`}
                            control={control}
                            defaultValue=''
                            render={({
                              field: {onChange, value},
                              fieldState: {error}
                            }) => (
                              <TextField
                                label='Units Produced'
                                variant='outlined'
                                type='number'
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      Per Year
                                    </InputAdornment>
                                  )
                                }}
                                {...register(
                                  `addresses.${i}.averageNoOfUnits` as const,
                                  {
                                    required: false,
                                    min: {
                                      value: 1,
                                      message:
                                        'Average Units Produced should be more than 0'
                                    },
                                    validate: {
                                      reg: (value: any) => {
                                        if (!value) return true;
                                        return (
                                          RegExp('^[0-9]+$').test(value) ||
                                          'Decimal are not allowed.'
                                        );
                                      }
                                    },
                                    valueAsNumber: true
                                  }
                                )}
                                onKeyDown={(event) => {
                                  if (
                                    event.code === 'ArrowDown' ||
                                    event.code === 'ArrowUp' ||
                                    event.code === 'Enter'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onFocus={(e) => {
                                  e.target.addEventListener(
                                    'wheel',
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    {passive: false}
                                  );
                                }}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box className='form-data'>
                      <Controller
                        name={`addresses.${i}.branchGst`}
                        control={control}
                        defaultValue=''
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <TextField
                            label='Outlet GST Number '
                            variant='outlined'
                            value={value.toUpperCase()}
                            {...register(`addresses.${i}.branchGst`, {
                              required: false,
                              validate: {
                                reg: (value) => {
                                  if (!value) return true;
                                  return (
                                    RegExp(
                                      '^(?!.*00)([0-3]{1}[0-8]{1}|99|97)[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Za-z]{1}$'
                                    ).test(value) ||
                                    'Please Enter 15 Digit Valid GST Number'
                                  );
                                }
                              }
                            })}
                            onChange={onChange}
                            // InputProps={{
                            //   endAdornment: (
                            //     <Button sx={{ fontSize: "10px" }}>
                            //       <Typography>Verify</Typography>
                            //     </Button>
                            //   ),
                            // }}
                            error={!!error}
                            helperText={error ? error?.message : null}
                          />
                        )}
                      />
                    </Box>
                    {watch('category') === 'hotel' && (
                      <>
                        <Box className='form-data'>
                          <TextField
                            autoFocus={false}
                            type='number'
                            label='Occupancy Rate per Month *'
                            variant='outlined'
                            defaultValue={address.occupanyRate || ''}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  %
                                </InputAdornment>
                              )
                            }}
                            {...register(
                              `addresses.${i}.occupancyRate` as const,
                              {
                                required: 'Occupancy Rate is required',
                                valueAsNumber: true,
                                min: {
                                  value: 1,
                                  message:
                                    'Occupancy Rate Should Be More Than 0'
                                },
                                max: {
                                  value: 100,
                                  message:
                                    'Occupancy Rate Should Be Less Than 100'
                                },
                                validate: {
                                  reg: (value: any) =>
                                    RegExp('^[0-9]+$').test(value) ||
                                    'Decimal are not allowed.'
                                }
                              }
                            )}
                            onKeyDown={(event) => {
                              if (
                                event.code === 'ArrowDown' ||
                                event.code === 'ArrowUp' ||
                                event.code === 'Enter'
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onFocus={(e) => {
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                {passive: false}
                              );
                            }}
                            error={!!errors?.addresses?.[i]?.occupancyRate}
                            onWheel={(event) => {
                              event.currentTarget.blur();
                            }}
                            helperText={
                              !!errors.addresses?.[i]?.occupancyRate
                                ? errors.addresses?.[i]?.occupancyRate?.message
                                : null
                            }
                          />
                        </Box>
                        <Box className='form-data'>
                          <TextField
                            autoFocus={false}
                            type='number'
                            label='Total Number of Rooms *'
                            variant='outlined'
                            defaultValue={address.totalRooms || ''}
                            {...register(`addresses.${i}.totalRooms` as const, {
                              required: 'Total Number of Rooms is required',
                              valueAsNumber: true,
                              min: {
                                value: 1,
                                message:
                                  'Total Number of Rooms Should Be More Than 0'
                              },
                              validate: {
                                reg: (value: any) =>
                                  RegExp('^[0-9]+$').test(value) ||
                                  'Decimal are not allowed.'
                              }
                            })}
                            onKeyDown={(event) => {
                              if (
                                event.code === 'ArrowDown' ||
                                event.code === 'ArrowUp' ||
                                event.code === 'Enter'
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onFocus={(e) => {
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                {passive: false}
                              );
                            }}
                            error={!!errors?.addresses?.[i]?.totalRooms}
                            onWheel={(event) => {
                              event.currentTarget.blur();
                            }}
                            helperText={
                              !!errors.addresses?.[i]?.totalRooms
                                ? errors.addresses?.[i]?.totalRooms?.message
                                : null
                            }
                          />
                        </Box>
                        <Box className='form-data'>
                          <Controller
                            name={`addresses.${i}.averageRoomBuildUp`}
                            control={control}
                            render={({field: {onChange, value}}) => (
                              <TextField
                                id='averageRoomBuildUp'
                                type='number'
                                label='Avg Room Size *'
                                variant='outlined'
                                value={showValue?.[i].averageRoomBuildUp.value}
                                {...register(
                                  `addresses.${i}.averageRoomBuildUp` as const,
                                  {
                                    required: 'Avg Room Size is required',
                                    valueAsNumber: true,
                                    min: {
                                      value: 1,
                                      message:
                                        'Avg Room Size Should Be More Than 0 Sq mts'
                                    },
                                    validate: {
                                      reg: (value: any) =>
                                        RegExp('^[0-9]+$').test(value) ||
                                        'Decimal are not allowed.'
                                    },
                                    onChange: (event) =>
                                      handleValueChange(event, i)
                                  }
                                )}
                                onKeyDown={(event) => {
                                  if (
                                    event.code === 'ArrowDown' ||
                                    event.code === 'ArrowUp' ||
                                    event.code === 'Enter'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onFocus={(e) => {
                                  e.target.addEventListener(
                                    'wheel',
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    {passive: false}
                                  );
                                }}
                                error={
                                  !!errors?.addresses?.[i]?.averageRoomBuildUp
                                }
                                onWheel={(event) => {
                                  event.currentTarget.blur();
                                }}
                                helperText={
                                  !!errors.addresses?.[i]?.averageRoomBuildUp
                                    ? errors.addresses?.[i]?.averageRoomBuildUp
                                        ?.message
                                    : null
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <TextField
                                        name={`addresses.${i}.averageRoomBuildUp`}
                                        id='averageRoomBuildUp'
                                        select
                                        value={
                                          showValue[i].averageRoomBuildUp.unit
                                        }
                                        onChange={(event) =>
                                          handleUnitChange(
                                            event,
                                            i,
                                            'averageRoomBuildUp'
                                          )
                                        }
                                        size='small'
                                        variant='standard'
                                        InputProps={{disableUnderline: true}}
                                        className='unitSelect'
                                      >
                                        {units.map((unit) => (
                                          <MenuItem key={unit} value={unit}>
                                            {unit}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            )}
                          />
                          {/* <TextField
                            autoFocus={false}
                            type='number'
                            label='Avg Room Size *'
                            variant='outlined'
                            defaultValue={address.averageRoomBuildUp}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  Sq mts
                                </InputAdornment>
                              )
                            }}
                            {...register(
                              `addresses.${i}.averageRoomBuildUp` as const,
                              {
                                required: 'Avg Room Size is required',
                                valueAsNumber: true,
                                min: {
                                  value: 1,
                                  message:
                                    'Avg Room Size Should Be More Than 0 Sq mts'
                                },
                                validate: {
                                  reg: (value: any) =>
                                    RegExp('^[0-9]+$').test(value) ||
                                    'Decimal are not allowed.'
                                }
                              }
                            )}
                            onKeyDown={(event) => {
                              if (
                                event.code === 'ArrowDown' ||
                                event.code === 'ArrowUp' ||
                                event.code === 'Enter'
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onFocus={(e) => {
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault();
                                },
                                {passive: false}
                              );
                            }}
                            error={!!errors?.addresses?.[i]?.averageRoomBuildUp}
                            onWheel={(event) => {
                              event.currentTarget.blur();
                            }}
                            helperText={
                              !!errors.addresses?.[i]?.averageRoomBuildUp
                                ? errors.addresses?.[i]?.averageRoomBuildUp
                                    ?.message
                                : null
                            }
                          /> */}
                        </Box>
                      </>
                    )}
                    {watch('category') === 'retail' && (
                      <Box className='form-data'>
                        <Controller
                          name={`addresses.${i}.airConditioned`}
                          control={control}
                          defaultValue=''
                          render={({
                            field: {onChange, value},
                            fieldState: {error}
                          }) => (
                            <>
                              <FormControl error={!!error ? true : false}>
                                <InputLabel id='category'>
                                  Air Conditioned Area *
                                </InputLabel>
                                <Select
                                  id='airConditioned'
                                  labelId='category'
                                  label='Air Conditioned Area *'
                                  value={value || ''}
                                  {...register(
                                    `addresses.${i}.airConditioned`,
                                    {
                                      min: {
                                        value: 2,
                                        message:
                                          'Air Conditioned Area is Required'
                                      },
                                      validate: {
                                        noValue: (value) => {
                                          return (
                                            value !== '' ||
                                            'Air Conditioned Area is Required'
                                          );
                                        }
                                      }
                                    }
                                  )}
                                  onChange={onChange}
                                  variant='outlined'
                                >
                                  {retailAddressSelect.map((item, i) => {
                                    return (
                                      <MenuItem key={i} value={item.value}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <FormHelperText>
                                  {error ? error?.message : null}
                                </FormHelperText>
                              </FormControl>
                            </>
                          )}
                        />
                      </Box>
                    )}
                    {watch('category') === 'restaurant' && (
                      <Box className='form-data'>
                        <TextField
                          autoFocus={false}
                          type='number'
                          label='Average Meals *'
                          variant='outlined'
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                Per year
                              </InputAdornment>
                            )
                          }}
                          defaultValue={address.averageMeals || ''}
                          {...register(`addresses.${i}.averageMeals` as const, {
                            required: 'Average Meals is required',
                            valueAsNumber: true,
                            min: {
                              value: 1,
                              message: 'Average Meals Should Be More Than 0'
                            },
                            validate: {
                              reg: (value: any) =>
                                RegExp('^[0-9]+$').test(value) ||
                                'Decimal are not allowed.'
                            }
                          })}
                          onKeyDown={(event) => {
                            if (
                              event.code === 'ArrowDown' ||
                              event.code === 'ArrowUp' ||
                              event.code === 'Enter'
                            ) {
                              event.preventDefault();
                            }
                          }}
                          onFocus={(e) => {
                            e.target.addEventListener(
                              'wheel',
                              function (e) {
                                e.preventDefault();
                              },
                              {passive: false}
                            );
                          }}
                          error={!!errors?.addresses?.[i]?.averageMeals}
                          onWheel={(event) => {
                            event.currentTarget.blur();
                          }}
                          helperText={
                            !!errors.addresses?.[i]?.averageMeals
                              ? errors.addresses?.[i]?.averageMeals?.message
                              : null
                          }
                        />
                      </Box>
                    )}
                    <Box className='form-data'>
                      <GoogleMaps setState={googleMapsAddress} index={i} />
                    </Box>
                    <Box className='form-data'>
                      <Controller
                        name={`addresses.${i}.title`}
                        control={control}
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <TextField
                            label='Outlet Name *'
                            variant='outlined'
                            value={
                              value.replace(/\b\w/g, (match) =>
                                match.toUpperCase()
                              ) || ''
                            }
                            {...register(`addresses.${i}.title` as const, {
                              required: 'Outlet Name is required',
                              minLength: {
                                value: 3,
                                message:
                                  'Outlet Name Should Be more than 2 letters'
                              },
                              maxLength: {
                                value: 100,
                                message:
                                  'Outlet Name Should Not Be more than 100 letters'
                              },
                              validate: {
                                reg: (value) =>
                                  RegExp(`^[A-Za-z0-9/_ -]+$`).test(value) ||
                                  'Please Enter Valid Outlet Name'
                              }
                            })}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error?.message : null}
                          />
                        )}
                      />
                    </Box>
                    <Box className='form-data w40'>
                      <Controller
                        name={`addresses.${i}.addressLine1`}
                        control={control}
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <TextField
                            label='Address Line 1 *'
                            variant='outlined'
                            value={
                              value.charAt(0).toUpperCase() + value.slice(1) ||
                              ''
                            }
                            {...register(
                              `addresses.${i}.addressLine1` as const,
                              {
                                required: 'Address line 1 is required'
                              }
                            )}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error?.message : null}
                          />
                        )}
                      />
                    </Box>
                    <Box className='form-data w40'>
                      <Controller
                        name={`addresses.${i}.addressLine2`}
                        control={control}
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <TextField
                            label='Address Line 2 *'
                            variant='outlined'
                            value={
                              value.charAt(0).toUpperCase() + value.slice(1) ||
                              ''
                            }
                            {...register(
                              `addresses.${i}.addressLine2` as const,
                              {
                                required: 'Address line 2 is required'
                              }
                            )}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error?.message : null}
                          />
                        )}
                      />
                    </Box>
                    <Box className='form-data'>
                      <Controller
                        name={`addresses.${i}.country`}
                        control={control}
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <Autocomplete
                            defaultValue=''
                            id='country-select-demo'
                            sx={{width: 300}}
                            options={(Country.getAllCountries() as any) || ''}
                            {...register(`addresses.${i}.country` as const, {
                              required: 'Country is Required',
                              min: {
                                value: 2,
                                message: 'Country is Required'
                              },
                              validate: {
                                noValue: (value) => {
                                  return value !== '' || 'Country is Required';
                                }
                              }
                            })}
                            onChange={(_, event) => {
                              onChange(event?.name);
                              setCountryId(event?.isoCode);
                              setValue(`addresses.${i}.state`, '');
                              setValue(`addresses.${i}.city`, '');
                            }}
                            noOptionsText={'No Country with this name exists'}
                            autoHighlight
                            getOptionLabel={(option) => option.name || value}
                            value={(value as any) || ''}
                            isOptionEqualToValue={(option, value) => {
                              return value
                                ? value === option?.name
                                : value === '';
                            }}
                            renderOption={(props, option) => (
                              <Box
                                component='li'
                                sx={{'& > img': {mr: 2, flexShrink: 0}}}
                                {...props}
                              >
                                <img
                                  loading='lazy'
                                  width='20'
                                  src={`https://flagcdn.com/w20/${option.isoCode.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.isoCode.toLowerCase()}.png 2x`}
                                  alt=''
                                />
                                {option.name} ({option.isoCode})
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Country *'
                                inputProps={{
                                  ...params.inputProps
                                }}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <Box className='form-data'>
                      <Controller
                        name={`addresses.${i}.state`}
                        control={control}
                        rules={{
                          min: {
                            value: 2,
                            message: 'State new is Required'
                          },
                          validate: {
                            noValue: (value) => {
                              return value !== '' || 'State is Required';
                            }
                            // wrongStateOnCountry: (value) => {
                            //   return (
                            //     singleState(
                            //       countryId ? countryId : "",
                            //       watch(`addresses.${i}.state`)
                            //     ).length !== 0 ||
                            //     "State doesnt exists on selected country"
                            //   );
                            // },
                          }
                        }}
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <Autocomplete
                            id='country-select-demo'
                            sx={{width: 300}}
                            defaultValue=''
                            options={
                              (State.getStatesOfCountry(
                                countryId ? countryId : ''
                              ) as any) || ''
                            }
                            noOptionsText={
                              countryId
                                ? 'No state with this name exists'
                                : 'Please First Select Country'
                            }
                            onChange={(event, value) => {
                              onChange(value?.name);
                              setStateId(value?.isoCode || '');
                              setValue(`addresses.${i}.city`, '');
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return value
                                ? value === option?.name
                                : value === '';
                            }}
                            onFocus={() => {
                              return setCountryId(
                                singleCountry(
                                  watch(`addresses.${i}.country`)
                                )[0]?.isoCode
                              );
                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name || value}
                            value={(value as any) || ''}
                            renderOption={(props, option) => (
                              <Box
                                component='li'
                                sx={{'& > img': {mr: 2, flexShrink: 0}}}
                                {...props}
                              >
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='State *'
                                {...register(`addresses.${i}.state` as const)}
                                value={value || ''}
                                inputProps={{
                                  ...params.inputProps
                                }}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <Box className='form-data'>
                      <Controller
                        name={`addresses.${i}.city`}
                        control={control}
                        rules={{
                          min: {
                            value: 2,
                            message: 'City is Required'
                          },
                          validate: {
                            noValue: (value) => {
                              return value !== '' || 'City is Required';
                            }
                          }
                        }}
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <Autocomplete
                            defaultValue=''
                            id='country-select-demo'
                            sx={{width: 300}}
                            options={
                              (City.getCitiesOfState(
                                countryId ||
                                  singleCountry(
                                    watch(`addresses.${i}.country`)
                                  )[0]?.isoCode,
                                stateId ||
                                  singleState(
                                    countryId ? countryId : '',
                                    watch(`addresses.${i}.state`)
                                  )[0]?.isoCode
                              ) as any) || ''
                            }
                            noOptionsText={
                              countryId
                                ? stateId
                                  ? 'No city with this name exists'
                                  : 'Please First Select State'
                                : 'Please First Select Country'
                            }
                            onChange={(event, value) => {
                              onChange(value?.name);
                            }}
                            onFocus={() => {
                              setCountryId(
                                singleCountry(
                                  watch(`addresses.${i}.country`)
                                )[0]?.isoCode
                              );
                              setStateId(
                                singleState(
                                  countryId ? countryId : '',
                                  watch(`addresses.${i}.state`)
                                )[0]?.isoCode
                              );
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return value
                                ? value === option?.name
                                : value === '';
                            }}
                            autoHighlight
                            getOptionLabel={(option) =>
                              option.name || value || ''
                            }
                            value={(value as any) || ''}
                            renderOption={(props, option) => (
                              <Box
                                component='li'
                                sx={{'& > img': {mr: 2, flexShrink: 0}}}
                                {...props}
                              >
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='City *'
                                value={value || ''}
                                inputProps={{
                                  ...params.inputProps
                                }}
                                error={!!error}
                                helperText={error ? error?.message : null}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <Box className='form-data'>
                      <Controller
                        name={`addresses.${i}.zipCode`}
                        control={control}
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <TextField
                            label='Pin Code *'
                            variant='outlined'
                            value={value || ''}
                            {...register(`addresses.${i}.zipCode`, {
                              required: 'Pincode is required',
                              minLength: {
                                value: 6,
                                message:
                                  'Pincode Should Not be Less Than 6 digit'
                              },
                              maxLength: {
                                value: 6,
                                message:
                                  'Pincode Should Not be More Than 6 digit'
                              },
                              validate: {
                                num: (value) =>
                                  RegExp('^[1-9][0-9]{5}$').test(value) ||
                                  'Pincode Cannot start with 0 or be negative number or be a Decimal Values'
                              }
                            })}
                            error={!!error}
                            helperText={error ? error?.message : null}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Box>
                    <Box className='form-data'>
                      {i === fields.length - 1 && (
                        <Button
                          onClick={() => {
                            append(
                              {
                                title: '',
                                addressLine1: '',
                                addressLine2: '',
                                branchGst: '',
                                city: '',
                                state: '',
                                country: '',
                                zipCode: '',
                                averageCustomer: '',
                                includeInScope: false,
                                builtUpArea: '',
                                empCount: '',
                                occupancyRate: '',
                                averageNoOfUnits: '',
                                totalRooms: '',
                                averageRoomBuildUp: '',
                                airConditioned: '',
                                averageMeals: ''
                              },
                              {shouldFocus: false}
                            );
                            showValue.push({
                              builtUpArea: {value: '', unit: 'Sq mtr'},
                              averageRoomBuildUp: {value: '', unit: 'Sq mtr'}
                            });
                          }}
                          startIcon={<AddLocationAlt color='success' />}
                        >
                          Add More Outlet
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      {isUpdate &&
        (customerDetails?.appStatus === 'documents pending' ||
          customerDetails?.appStatus === 'verification pending') && (
          <Box className='formData-view formSection'>
            <Box className='data-title'>Document Details</Box>
            <Box className='form-view upload-doc'>
              {fileUploadObject.map((Type, i) => {
                return (
                  <Fragment key={i}>
                    <Button
                      onClick={() => {
                        setOpen(true);
                        setFileName(Type.name);
                      }}
                      className='form-data'
                    >
                      <Box className='fileUpload'>
                        <Box>
                          <img src={FileIcon} alt='fileIcon' />
                        </Box>
                        <Box>
                          <Typography className='fileTitle'>
                            {Type.name}
                          </Typography>

                          {Type?.fileName ? (
                            <Typography className='fileType'>
                              {Type?.fileName}
                            </Typography>
                          ) : (
                            <Typography className='fileType'>
                              pdf, jpg, png.
                            </Typography>
                          )}
                        </Box>
                        <Box className='fileIcon'>
                          <UploadFileRoundedIcon color='inherit' />
                          <Typography className='fileType'>
                            Max size 5mb
                          </Typography>
                        </Box>
                      </Box>
                    </Button>
                  </Fragment>
                );
              })}
            </Box>
            {open && (
              <Modal
                children={
                  <Controller
                    name={`${
                      fileName === 'Incorporation Certificate'
                        ? 'incorporateCertificate'
                        : 'identityProofDocument'
                    }`}
                    control={control}
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <UploadDoc
                        filename={fileName ? fileName : ''}
                        handleFile={(event) => {
                          onChange(event?.url);
                          fileName === 'Incorporation Certificate'
                            ? setFile1(event?.file?.name)
                            : setFile2(event?.file?.name);
                        }}
                        setOpen={setOpen}
                        fileView={value}
                      />
                    )}
                  />
                }
                open={open}
                setOpen={setOpen}
              />
            )}
          </Box>
        )}

      <Box className='df-right'>
        <Button
          className='btn secondary-btn '
          name='saveAsDraft'
          onClick={async () => {
            clearErrors();
            if (
              await trigger([
                'firstName',
                'lastName',
                'email',
                'phone',
                'businessName'
              ])
            ) {
              onDraft(watch());
            }
          }}
        >
          Save as Draft
        </Button>
        <Button
          className='btn-primary'
          name='submit'
          onClick={async () => {
            clearErrors();
            if (await trigger()) {
              onSubmit(getValues());
            }
          }}
        >
          {id ? 'Update and Pay Now' : 'Pay Now'}
        </Button>
      </Box>
    </Box>
  );
};

export default AddCustomer;
