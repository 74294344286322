import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

type canceledProps = {
  setCancel: any;
  companyId: string;
};

const CanceledModal = ({ setCancel, companyId }: canceledProps) => {
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;
  type cancelDataType = {
    companyId: string;
    reason: string;
    description?: string;
    appStatus: string;
  };

  const navigate = useNavigate();

  const [cancelData, setCancelData] = useState<cancelDataType>({
    companyId: companyId,
    reason: '',
    description: '',
    appStatus: 'cancelled'
  });

  const handleCancel = (e: any) => {
    setCancelData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const cancelLead = () => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      await axios
        .post(`${BACKEND_ENDPOINT}/company/status`, cancelData, config)
        .then((res) => {
          setCancel(false);
          setTimeout(() => {
            navigate('/customer/list');
          }, 1000);
        });
    };
    fetchApi().catch((err) => {
      console.log('err:', err);
    });
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      className='leadPopup'
    >
      <Box className='lp-icon'>!</Box>
      <Box className='lp-title'>
        Before you cancel the lead, please tell us the reason for cancelation.
      </Box>
      <FormControl>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue='Payment Not Made'
          name='reason'
          className='lp-radio'
          onChange={handleCancel}
        >
          <FormControlLabel
            value='Payment Not Made'
            control={<Radio />}
            label='Payment Not Made'
          />
          <FormControlLabel
            value='No Response'
            control={<Radio />}
            label='No Response'
          />
          <FormControlLabel
            value='Document Not Provided'
            control={<Radio />}
            label='Document Not Provided'
          />
          <FormControlLabel value='other' control={<Radio />} label='Other' />
        </RadioGroup>
      </FormControl>
      {cancelData.reason === 'other' && (
        <TextField
          name='description'
          placeholder='Please Provide us your reason , so we could provide you with better service'
          multiline
          rows={4}
          onChange={handleCancel}
        />
      )}
      <Button onClick={cancelLead} className='btn-primary'>
        Cancel Lead
      </Button>
      <Button onClick={() => setCancel(false)} className='lp-cancel'>
        Nevermind, I dont want to cancel
      </Button>
    </Box>
  );
};

export default CanceledModal;
