import {Box} from '@mui/material';
import {Link} from 'react-router-dom';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

const ScopeInfo = () => {
  return (
    <Box className='scopeSec'>
      <h4>Scope Info</h4>
      <Box className='section'>
        <Box className='count'>
          <Box className='cnumber'>1</Box>
          <Box className='label'>SCOPE</Box>
        </Box>
        <Box className='pointers'>
          <Link to='#' className='filled'>
            <CheckIcon />
            <span>Fuel consumption in kitchen</span>
          </Link>
          <Link to='#'>
            <CheckIcon />
            <span>Fuel consumption by company owned vehicle</span>
          </Link>
          <Link to='#'>
            <CheckIcon />
            <span>Fuel consumption by D G Set</span>
          </Link>
          <Link to='#'>
            <CheckIcon />
            <span>Fuel consumption by refrigerant</span>
          </Link>
        </Box>
      </Box>
      <Box className='section'>
        <Box className='count'>
          <Box className='cnumber'>2</Box>
          <Box className='label'>SCOPE</Box>
        </Box>
        <Box className='pointers'>
          <Link to='#'>
            <CheckIcon />
            <span>Electricity Consumption From Grid</span>
          </Link>
        </Box>
      </Box>
      <Box className='section'>
        <Box className='count'>
          <Box className='cnumber'>3</Box>
          <Box className='label'>SCOPE</Box>
        </Box>
        <Box className='pointers'>
          <Link to='#'>
            <CheckIcon />
            <span>Employee commute</span>
          </Link>
          <Link to='#'>
            <CheckIcon />
            <span>Raw material transport</span>
          </Link>
          <Link to='#'>
            <CheckIcon />
            <span>Fresh water consumption</span>
          </Link>
          <Link to='#'>
            <CheckIcon />
            <span>Waste water generation</span>
          </Link>
          <Link to='#'>
            <CheckIcon />
            <span>Solid waste generation</span>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ScopeInfo;
