import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from 'axios';
import {LinearProgress} from '@mui/material';
import moment from 'moment';
import DataGrid from './DataGrid';
import LinearLoader from '../utility/LinearLoader';
import getAppStatus from 'Shared/Utils/getAppStatus';
import PartnerConstant from 'Shared/Constants/PartnerConstant';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface MainTabProp {
  search: string;
  refresh: boolean;
}

export interface Data {
  id: number;
  customerId: string;
  businessName: string;
  entities: string;
  isGuest: Boolean;
  shared: Boolean;
  financialYear: String;
  verified: boolean;
  callScheduled: boolean;
  paymentPending: boolean;
  documentsPending: boolean;
  verificationPending: boolean;
  footprintPending: boolean;
  status: boolean;
  mainStatus: string;
  canceled: boolean;
  isEdit: string;
  isDraft: string;
  companyId: string;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      className='tableui'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 0}}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const MainTab = ({search, refresh}: MainTabProp) => {
  const [valueTab, setValueTab] = React.useState(0);
  const [companys, setCompanys] = React.useState<any>();
  // const [allData, setAllData] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<any>(true);
  const [dataType, setDataType] = React.useState<string | null>('');
  const [allDataType, setAllDataType] = React.useState<any>();
  const [rowPerPage, setRowPerPage] = React.useState(5);
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;
  const status = PartnerConstant.APP_STATUS;

  React.useEffect(() => {}, [refresh]);

  const DataTypeChange = (index: string) => {
    const returnedValue = {
      '0': '',
      '1': 'call scheduled',
      '2': 'invite and pay',
      '3': 'documents pending',
      '4': 'verification pending',
      '5': 'footprinting pending',
      '6': 'completed',
      '7': 'cancelled'
    };

    setDataType(returnedValue[index as keyof typeof returnedValue]);
    return dataType;
  };

  React.useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      setIsLoading(true);
      await axios
        .get(
          `${BACKEND_ENDPOINT}/users?userType=user&appStatus=${dataType}&term=${search}`,
          config
        )
        .then((res) => {
          // setAllData(res?.data?.customers);
          if (res?.data?.customers.length === 0) {
            setIsLoading(false);
          }
          const object = res?.data?.customers.map(
            (singlecustomer: any, customerId: number) => {
              const resObject: Data = {
                id: customerId,
                customerId: singlecustomer?._id,
                businessName: singlecustomer?.company?.businessName,
                entities: singlecustomer?.company?.locations?.length,
                isGuest: singlecustomer?.isGuest,
                shared: Boolean(Math.floor(Math.random() * 2)),
                financialYear:
                  singlecustomer?.company?.footprintingPeriod[0] &&
                  singlecustomer?.company?.footprintingPeriod[1]
                    ? `${moment(
                        singlecustomer?.company?.footprintingPeriod[0]
                      ).format('YYYY')} - ${moment(
                        singlecustomer?.company?.footprintingPeriod[1]
                      ).format('YYYY')}`
                    : '-',
                verified: singlecustomer?.isVerified,
                callScheduled: getAppStatus(
                  singlecustomer?.company?.status,
                  status.CALL_SCHEDULED
                ),
                paymentPending: getAppStatus(
                  singlecustomer?.company?.status,
                  status.PAYMENT_PENDING
                ),
                documentsPending: getAppStatus(
                  singlecustomer?.company?.status,
                  status.DOC_PENDING
                ),
                verificationPending: getAppStatus(
                  singlecustomer?.company?.status,
                  status.VERIFICATION_PENDING
                ),
                footprintPending: getAppStatus(
                  singlecustomer?.company?.status,
                  status.FOOTPRINTING_PENDING
                ),
                status: getAppStatus(
                  singlecustomer?.company?.status,
                  status.COMPLETED
                ),
                mainStatus: singlecustomer?.company?.status,
                canceled: getAppStatus(
                  singlecustomer?.company?.status,
                  status.CANCELLED
                ),
                isEdit: singlecustomer?.isEdit,
                isDraft: singlecustomer?.company?.isDraft,
                companyId: singlecustomer?.company?._id
              };
              setIsLoading(false);
              return resObject;
            }
          );

          if (dataType === '') {
            setAllDataType(object);
          }
          return setCompanys(object);
        });
    };

    fetchApi().catch((err) => {
      console.log('errMainTab:', err);
    });
  }, [dataType, search]);

  const [numberRows, setNumberRows] = React.useState({
    all: 0,
    call: 0,
    doc: 0,
    invite: 0,
    veri: 0,
    FP: 0,
    comp: 0,
    can: 0
  });

  React.useEffect(() => {
    if (dataType === '') {
      setNumberRows({
        all: allDataType?.length,
        doc: allDataType?.filter((company: any) => {
          return company?.mainStatus === 'documents pending';
        })?.length,
        call: allDataType?.filter((company: any) => {
          return company?.mainStatus === 'call scheduled';
        })?.length,
        invite: allDataType?.filter((company: any) => {
          return company?.mainStatus === 'invite and pay' && !company?.isDraft;
        })?.length,
        veri: allDataType?.filter((company: any) => {
          return company?.mainStatus === 'verification pending';
        })?.length,
        FP: allDataType?.filter((company: any) => {
          return company?.mainStatus === 'footprinting pending';
        })?.length,
        comp: allDataType?.filter((company: any) => {
          return company?.mainStatus === 'completed';
        })?.length,
        can: allDataType?.filter((company: any) => {
          return company?.mainStatus === 'cancelled';
        })?.length
      });
    } else {
      return;
    }
  }, [allDataType]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
    DataTypeChange(String(newValue));
  };

  return !companys ? (
    <LinearProgress
      color='success'
      sx={{
        marginTop: '15px',
        backgroundColor: 'white',
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#a2d87b'
        }
      }}
    />
  ) : (
    <Box sx={{mt: '1rem', width: '100%'}}>
      <Box sx={{borderColor: 'divider'}}>
        <Tabs
          value={valueTab}
          onChange={handleChange}
          // variant="fullWidth"
          className='tabui'
          // centered={true}
          aria-label='basic tabs example'
          TabIndicatorProps={{
            style: {
              backgroundColor: '#3BB3C1',
              color: '#3BB3C1'
            }
          }}
        >
          <Tab
            aria-labelledby={`All`}
            aria-label={`${numberRows.all}`}
            sx={{
              color: '#3BB3C1'
            }}
            {...a11yProps(0)}
          />
          <Tab
            aria-labelledby={`Call Scheduled`}
            aria-label={`${numberRows.call}`}
            sx={{
              color: '#d3a3c9'
            }}
            {...a11yProps(1)}
          />

          <Tab
            aria-labelledby={`Payment Pending`}
            aria-label={`${numberRows.invite}`}
            sx={{
              color: '#26AF5F'
            }}
            {...a11yProps(2)}
          />
          <Tab
            aria-labelledby={`Documentation Pending`}
            aria-label={`${numberRows.doc}`}
            sx={{
              color: '#BA3A21'
            }}
            {...a11yProps(3)}
          />

          <Tab
            aria-labelledby={`Verification Pending`}
            aria-label={`${numberRows.veri}`}
            sx={{
              color: '#622CA2'
            }}
            {...a11yProps(4)}
          />

          <Tab
            aria-labelledby={`Foot Printing Pending`}
            aria-label={`${numberRows.FP}`}
            sx={{
              color: '#0F6767'
            }}
            {...a11yProps(5)}
          />
          <Tab
            aria-labelledby={`Completed`}
            aria-label={`${numberRows?.comp}`}
            sx={{
              color: '#2343A9'
            }}
            {...a11yProps(6)}
          />
          <Tab
            aria-labelledby={`Canceled`}
            aria-label={`${numberRows?.can}`}
            sx={{
              color: '#8C2822'
            }}
            {...a11yProps(7)}
          />
        </Tabs>
      </Box>
      {isLoading ? (
        <LinearLoader />
      ) : (
        <>
          <TabPanel value={valueTab} index={0}>
            <DataGrid rows={companys} rowsPerPage={rowPerPage} />
          </TabPanel>
          <TabPanel value={valueTab} index={1}>
            <DataGrid rows={companys} rowsPerPage={rowPerPage} />
          </TabPanel>
          <TabPanel value={valueTab} index={2}>
            <DataGrid rows={companys} rowsPerPage={rowPerPage} />
          </TabPanel>
          <TabPanel value={valueTab} index={3}>
            <DataGrid rows={companys} rowsPerPage={rowPerPage} />
          </TabPanel>
          <TabPanel value={valueTab} index={4}>
            <DataGrid rows={companys} rowsPerPage={rowPerPage} />
          </TabPanel>
          <TabPanel value={valueTab} index={5}>
            <DataGrid rows={companys} rowsPerPage={rowPerPage} />
          </TabPanel>
          <TabPanel value={valueTab} index={6}>
            <DataGrid rows={companys} rowsPerPage={rowPerPage} />
          </TabPanel>
          <TabPanel value={valueTab} index={7}>
            <DataGrid rows={companys} rowsPerPage={rowPerPage} />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default MainTab;
