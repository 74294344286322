import React from "react";
import { LineChart, Line, ResponsiveContainer } from "recharts";

type AllChart = {
  data: any;
};

export default function AllChart({ data }: AllChart) {
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      minWidth="120px"
      minHeight="70px"
    >
      <LineChart data={data}>
        <Line
          type="monotone"
          dataKey={(value) => value}
          stroke="#42B3C0"
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
