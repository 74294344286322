import PartnerConstant from 'Shared/Constants/PartnerConstant';
const getAppStatus: (query: string, status: string) => boolean = (
  query,
  status
) => {
  const statusString = PartnerConstant.APP_STATUS;
  switch (status) {
    case statusString.CALL_SCHEDULED:
      return query === statusString.CALL_SCHEDULED;
    case statusString.PAYMENT_PENDING:
      return (
        query === statusString.CALL_SCHEDULED ||
        query === statusString.PAYMENT_PENDING
      );
    case statusString.DOC_PENDING:
      return query === statusString.CALL_SCHEDULED
        ? true
        : query === statusString.PAYMENT_PENDING
          ? true
          : query === statusString.DOC_PENDING;

    case statusString.VERIFICATION_PENDING:
      return query === statusString.CALL_SCHEDULED
        ? true
        : query === statusString.PAYMENT_PENDING
          ? true
          : query === statusString.DOC_PENDING
            ? true
            : query === statusString.VERIFICATION_PENDING;
    case statusString.FOOTPRINTING_PENDING:
      return query === statusString.CALL_SCHEDULED
        ? true
        : query === statusString.PAYMENT_PENDING
          ? true
          : query === statusString.DOC_PENDING
            ? true
            : query === statusString.VERIFICATION_PENDING
              ? true
              : query === statusString.FOOTPRINTING_PENDING;
    case statusString.COMPLETED:
      return query === statusString.COMPLETED;
    case statusString.CANCELLED:
      return query === statusString.CANCELLED;
    default:
      return false;
  }
};

export default getAppStatus;
