import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  AlertColor,
  LinearProgress,
  TextField,
  IconButton
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from 'axios';
import EmployeeQRCode from './EmployeeQRCode';
import RawMaterialQRCode from './RawMaterialQRCode';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Aws from '../../../../../Libraries/Aws';
import LinearWithValueLabel from '../../utility/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import {AES} from 'crypto-js';
import Modal from '../../utility/Modal';
import EmpCommuteModal from '../../utility/EmpCommuteModal';
import ScopeFields from './ScopeFields';
import Tooltip from 'Shared/Layouts/Tooltip';
import Informatives from 'Shared/Informatives';
import PartnerConstant from 'Shared/Constants/PartnerConstant';
import {ScopeTitleArrayType} from './Interface/Scope1';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

// import DownloadQR from "../../utility/DownloadQR";

type scope3Props = {
  locationId: string;
  otherData: any;
  scopeStart: any;
  scopeEnd: any;
  natureOfbusiness: string;
  locationState: any;
  setOpenSnackbar: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      status: AlertColor;
      message: string;
    }>
  >;
  setValue: any;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  isDirty: boolean;
};

const Scope3 = ({
  setValue,
  locationId,
  otherData,
  scopeStart,
  scopeEnd,
  natureOfbusiness,
  setOpenSnackbar,
  locationState,
  setIsDirty,
  isDirty
}: scope3Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cat1, setCat1] = useState<{sub: string; unit: string; desc: string}[]>(
    []
  );
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [isLoadingOther, setIsLoadingOther] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [fileUpload1, setFileUpload1] = useState(false);
  const [fileUpload2, setFileUpload2] = useState(false);
  const [progressState, setProgressState] = useState<any>(null);
  const [file1, setFile1] = useState<any>(null);
  const [file2, setFile2] = useState<any>(null);
  const [submitted, setSubmitted] = useState(false);
  const [empData, setEmpData] = useState<any>();
  const [rawData, setRawData] = useState<any>();
  const [openEmpModal, setOpenEmpModal] = useState(false);
  const [openRawModal, setOpenRawModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;
  const REACT_APP_DATA_COLLECTOR_APP_BASE_URL =
    process.env.REACT_APP_DATA_COLLECTOR_APP_BASE_URL;

  type leaseObject = {
    propertyName: string;
    timeOfRentedProperty: string | null;
    size: string;
  };
  type DefaultValue = {
    upStreamLeasedAssests: leaseObject[];
  };

  const defaultValue = {
    upStreamLeasedAssests: [
      {
        propertyName: '',
        timeOfRentedProperty: null,
        size: ''
      }
    ]
  };

  const {register, control, watch} = useForm<DefaultValue>({
    defaultValues: defaultValue,
    mode: 'onSubmit'
  });

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'upStreamLeasedAssests'
  });

  const cat1Options = [
    {
      sub: 'Steel',
      unit: 'Ton',
      desc: '(e.g., cold-rolled steel sheets for body panels)'
    },
    {
      sub: 'Aluminum',
      unit: 'Kg',
      desc: '(e.g., aluminum alloy for engine blocks)'
    },
    {
      sub: 'Iron',
      unit: 'Ton',
      desc: '(e.g., cast iron for engine blocks)'
    },
    {
      sub: 'Copper',
      unit: 'Kg',
      desc: '(e.g., pure copper wiring for electrical systems)'
    },
    {
      sub: 'Lead',
      unit: 'Ton',
      desc: '(e.g., lead-acid batteries)'
    },
    {
      sub: 'Polypropylene',
      unit: 'Kg',
      desc: '(e.g., bumpers and dashboards)'
    },
    {
      sub: 'Polyvinyl chloride',
      unit: 'Kg',
      desc: '(e.g., wire insulation)'
    },
    {
      sub: 'Natural rubber',
      unit: 'Kg',
      desc: '(e.g., tire production)'
    },
    {
      sub: 'Synthetic rubber',
      unit: 'Kg',
      desc: '(e.g., hoses and seals)'
    },
    {
      sub: 'Tempered glass',
      unit: 'Ton',
      desc: '(e.g., side windows)'
    },
    {
      sub: 'Laminated glass',
      unit: 'Ton',
      desc: '(e.g., windshields)'
    },
    {
      sub: 'Polyester',
      unit: 'Kg',
      desc: '(e.g., seat covers)'
    },
    {
      sub: 'Nylon',
      unit: 'Kg',
      desc: '(e.g., airbags)'
    },
    {
      sub: 'Motor oil',
      unit: 'Kg',
      desc: '(e.g., 10W-30 for engine lubrication)'
    },
    {
      sub: 'Ethylene glycol',
      unit: 'Ton',
      desc: '(e.g., coolant)'
    },
    {
      sub: 'Acrylic paint',
      unit: 'Kg',
      desc: '(e.g., exterior paint)'
    },
    {
      sub: 'Electro-coating',
      unit: 'Ton',
      desc: '(e.g., primer for corrosion protection)'
    },
    {
      sub: 'Lead-acid batteries',
      unit: 'kWh',
      desc: '(e.g., starter batteries)'
    },
    {
      sub: 'Lithium-ion batteries',
      unit: 'kWh',
      desc: '(e.g., for electric vehicles)'
    },
    {
      sub: 'Silicon chips',
      unit: 'Kg',
      desc: '(e.g., microcontrollers for the engine control unit)'
    },
    {
      sub: 'OLED displays',
      unit: 'Sq cm',
      desc: '(e.g., for infotainment screens)'
    },
    {
      sub: 'Halogen bulbs',
      unit: 'kWh',
      desc: '(e.g., headlights)'
    },
    {
      sub: 'Cast iron',
      unit: 'Kg',
      desc: '(e.g., brake discs)'
    },
    {
      sub: 'Gasoline',
      unit: 'Ltr',
      desc: '(e.g., for smaller delivery vehicles)'
    },
    {
      sub: 'Acetylene',
      unit: 'Ton',
      desc: '(e.g., for cutting and welding)'
    }
  ];

  let config = {
    headers: {
      authorization: `Bearer ${
        localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }`
    }
  };

  const sampleScope3Data = {
    locationId: locationId,
    companyId: locationState.companyId,
    data: [
      {
        index: '',
        category: '',
        subCategory: '',
        type: '',
        subType: '',
        value: '',
        metric: '',
        fromDate: '',
        toDate: ''
      }
    ],
    upStreamLeasedAssests: [],
    employeeCommuteUrl: '',
    supplyChainUrl: ''
  };

  const [scope3Data, setScope3Data] =
    useState<scope3DataType>(sampleScope3Data);

  const handleDocChange = (e: any, filename: any) => {
    const files = e.target.files;

    const file = files[0];

    const url = `https://${process.env.REACT_APP_AMAZON_BUCKET}.s3.${process.env.REACT_APP_AMAZON_REGION}.amazonaws.com/${file.name}`;

    Aws.uploadImageToS3(file)
      .on('httpUploadProgress', (progress) => {
        setProgressState(Math.round((progress.loaded / progress.total) * 100));
      })
      .send((err) => {
        console.log('err:', err);
      });

    if (filename === 'emp') {
      setFileUpload1(true);
      setFile1(file);
      scope3Data.employeeCommuteUrl = url;
    } else {
      setFileUpload2(true);
      setFile2(file);
      scope3Data.supplyChainUrl = url;
    }

    setScope3Data((prev) => {
      return {...prev};
    });
  };

  // const handleSubmitCloseModal = () => {
  //   setValue(3);
  //   setSubmitted(false);
  // };

  type dataType = {
    _id?: string;
    index: string;
    category: string;
    subCategory?: string;
    type: string;
    subType: string;
    value: string;
    metric: string;
    fromDate: string;
    toDate: string;
  };

  type scope3DataType = {
    locationId: string;
    companyId: string;
    data: dataType[];
    upStreamLeasedAssests: leaseObject[];
    employeeCommuteUrl: string;
    supplyChainUrl: string;
  };

  useEffect(() => {
    if (isDirty) {
      const autosave = setInterval(() => {
        document.getElementById('submit')?.click();
        setIsUpdate(true);
      }, PartnerConstant.autoSaveInterval);
      return () => {
        clearInterval(autosave);
      };
    }
  }, [isDirty]);

  const date = [
    '30',
    '31',
    '30',
    '31',
    '31',
    '30',
    '31',
    '30',
    '31',
    '30',
    '28',
    '30'
  ];

  const months = [
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar'
  ];

  useEffect(() => {
    const fetchApi = async () => {
      await axios
        .get(
          `${BACKEND_ENDPOINT}/employeeCommute/count?locationId=${locationId}&fromYear=${scopeStart}&toYear=${scopeEnd}`,
          config
        )
        .then((res) => {
          setEmpData(res?.data);
        });
      await axios
        .get(
          `${BACKEND_ENDPOINT}/supplyChain/count?locationId=${locationId}&fromYear=${scopeStart}&toYear=${scopeEnd}`,
          config
        )
        .then((res) => {
          setRawData(res?.data);
        });
    };

    fetchApi()
      .then(() => setIsLoadingOther(false))
      .catch((err) => {
        console.log('errEmpCount:', err);
      });
  }, [locationId, scopeStart, openEmpModal]);

  useEffect(() => {}, [openEmpModal]);

  useEffect(() => {
    const fetchApi = async () => {
      if (locationId && scopeStart) {
        await axios
          .get(
            `${BACKEND_ENDPOINT}/footprinting/scope3?locationId=${locationId}&fromDate=${scopeStart}-04-01 00:00:00&toDate=${scopeEnd}-03-31 23:59:59`,
            config
          )
          .then((res) => {
            setIsUpdate(res?.data?.scope3 !== null);

            setScope3Data(isUpdate ? res?.data?.scope3 : sampleScope3Data);
          })
          .then(() => setIsLoading(false));
      }
    };

    fetchApi().catch((err) => {
      console.log('errScope3:', err);
    });
  }, [locationId, scopeStart, scopeEnd, isUpdate]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    scope3Data.data = [];
    scope3Data.companyId = locationState.companyId;
    scope3Data.upStreamLeasedAssests = [...watch('upStreamLeasedAssests')];

    for (let i = 0; i < e.target.length; i++) {
      if (
        e.target.elements[i].getAttribute('type') === 'number' &&
        e.target.elements[i].value
      ) {
        const getAttribute = (prop: string) => {
          return e.target.elements[i].getAttribute(prop);
        };
        const input: any = {
          index: getAttribute('id'),
          category: getAttribute('category'),
          subCategory: getAttribute('sub'),
          type: getAttribute('typename'),
          value: e.target.elements[i].value,
          metric: getAttribute('metric'),
          fromDate:
            getAttribute('yearly') === 'true'
              ? `1-${months[0]}-${scopeStart}`
              : getAttribute('id') >= 9
              ? `1-${months[getAttribute('id')]}-${scopeEnd}`
              : `1-${months[getAttribute('id')]}-${scopeStart}`,
          toDate:
            getAttribute('yearly') === 'true'
              ? `${date[11]}-${months[11]}-${scopeEnd}`
              : getAttribute('id') >= 9
              ? `${date[getAttribute('id')]}-${
                  months[getAttribute('id')]
                }-${scopeEnd}`
              : `${date[getAttribute('id')]}-${
                  months[getAttribute('id')]
                }-${scopeStart}`
        };

        scope3Data.locationId = locationId ? locationId : '';

        let isExist = scope3Data?.data?.findIndex(function (currentValue) {
          return (
            currentValue?.category === input?.category &&
            currentValue?.type === input?.type &&
            currentValue?.index === input?.index
          );
        });

        if (isExist !== -1) {
          scope3Data.data[isExist] = input;
        } else {
          scope3Data?.data?.push(input);
        }
      }
    }

    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      const method = isUpdate ? 'put' : 'post';

      await axios
        .request({
          method: method,
          url: `${BACKEND_ENDPOINT}/footprinting/scope3`,
          data: scope3Data,
          headers: config.headers
        })
        .then(() => {
          setIsDirty(false);
          if (isSubmit) {
            setIsUpdate(true);
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: 'Scope 3 fields added successfully!'
              };
            });
            setValue(3);
            setSubmitted(true);
          } else {
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: 'Scope 3 fields Auto Saved successfully!'
              };
            });
          }
        });
    };
    fetchApi().catch((err) =>
      setOpenSnackbar((prev) => {
        return {
          ...prev,
          open: true,
          status: 'error',
          message: err?.message
        };
      })
    );
  };

  const mergedId = `${locationState?.companyId},${locationId},${scopeStart}, ${scopeEnd}`;
  const secretKey = 'bc-secret-key';

  const ciphertext = AES.encrypt(mergedId, secretKey).toString();
  const encodedCiphertext = btoa(ciphertext)
    .replace(/\//g, '_')
    .replace(/\+/g, '-');

  const employeeUrl = `${REACT_APP_DATA_COLLECTOR_APP_BASE_URL}/employee-commute/${encodedCiphertext}`;
  const businessUrl = `${REACT_APP_DATA_COLLECTOR_APP_BASE_URL}/business-travel/${encodedCiphertext}`;
  const upSupplyUrl = `${REACT_APP_DATA_COLLECTOR_APP_BASE_URL}/up-supply-chain/${encodedCiphertext}`;
  const downSupplyUrl = `${REACT_APP_DATA_COLLECTOR_APP_BASE_URL}/down-supply-chain/${encodedCiphertext}`;

  const Scope3TitleArray: ScopeTitleArrayType = [
    {
      title: 'Fresh water consumption',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'Tap water',
          unit: 'Ltr',
          desc: Informatives?.scope3?.freshWater?.tap
        },
        {
          sub: 'Ultra pure water',
          unit: 'Ltr',
          desc: Informatives?.scope3?.freshWater?.ultra
        },
        {
          sub: 'Decarbonised water',
          unit: 'Ltr',
          desc: Informatives?.scope3?.freshWater?.decarb
        }
      ]
    },
    {
      title: 'Waste water generation',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'Waste water',
          unit: 'Ltr',
          desc: Informatives?.scope3?.wasteWater
        }
      ]
    },
    {
      title: 'Solid waste generation',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {sub: 'Solid waste', unit: 'Kg', desc: Informatives?.scope3?.solidWaste}
      ]
    },

    {
      title: 'Laundry cleaned',
      type: ['hotel'],
      subData: [
        {
          sub: 'laundry cleaned',
          unit: 'Kg',
          desc: Informatives?.scope3?.laundry
        }
      ]
    },
    {
      title: 'Purchased goods and services',
      type: ['hotel', 'restaurant', 'retail'],
      selectOption: {
        options: cat1Options,
        setData: setCat1,
        label: 'Select Products'
      },
      subData: cat1
    },
    {
      title: 'Capital goods',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'CNC machines',
          unit: 'kg',
          desc: '(e.g., for precise metalwork)'
        },
        {
          sub: 'Hydraulic presses',
          unit: 'kg',
          desc: '(e.g., for shaping metal sheets)'
        },
        {
          sub: 'Injection molding machines',
          unit: 'kg',
          desc: '(e.g., for plastic components)'
        },
        {
          sub: 'Diesel forklifts',
          unit: 'hour',
          desc: '(e.g., for moving heavy components)'
        }
      ]
    },
    {
      title: `Fuel and energy related emissions
      (not included in Scope 1 or 2)`,
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'Coal',
          unit: 'kg',
          desc: 'Coal'
        },
        {
          sub: 'Natural gas',
          unit: 'Ton',
          desc: 'Natural gas'
        }
      ]
    },
    {
      title: `Waste Processing`,
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'Incineration',
          unit: 'kg',
          desc: 'Incineration'
        },
        {
          sub: 'Recycling',
          unit: 'kg',
          desc: 'Recycling'
        },
        {
          sub: 'Land Fill',
          unit: 'kg',
          desc: 'Land Fill'
        }
      ]
    },
    {
      title: 'Processing of sold products',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'Natural Gas',
          unit: 'Ltr',
          desc: 'Natural Gas'
        },
        {
          sub: 'Electricity',
          unit: 'kWh',
          desc: 'Electricity'
        },
        {
          sub: 'Waste Products',
          unit: 'kg',
          desc: 'Waste Products'
        }
      ]
    },
    {
      title: `End of life treatment of sold products`,
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'Incineration',
          unit: 'kg',
          desc: 'Incineration'
        },
        {
          sub: 'Recycling',
          unit: 'kg',
          desc: 'Recycling'
        },
        {
          sub: 'Land Fill',
          unit: 'kg',
          desc: 'Land Fill'
        }
      ]
    }
  ];

  return isLoading && locationId && scopeStart ? (
    <LinearProgress
      color='success'
      sx={{
        marginTop: '15px',
        backgroundColor: 'white',
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#a2d87b'
        }
      }}
    />
  ) : (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Box>
            <Box className='qr-section'>
              <Box className='formData-view formSection'>
                <Box className='data-title'>Employee Commute</Box>
                <br></br>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Button
                      className='flex-center-h'
                      sx={{color: '#3bb3c1', textDecoration: 'underline'}}
                    >
                      <a
                        href={`https://${process.env.REACT_APP_AMAZON_BUCKET}.s3.${process.env.REACT_APP_AMAZON_REGION}.amazonaws.com/sample/sample_employee_commute.csv`}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{color: '#3BB3C1', textDecoration: 'none'}}
                        download
                      >
                        Download the Sample template
                      </a>
                    </Button>
                    <Tooltip message={Informatives?.scope3?.empDetails} />
                  </Box>

                  <Box>
                    {locationId && scopeStart && !isLoadingOther && (
                      <Button
                        className='flex-center-h'
                        sx={{color: '#3bb3c1', textDecoration: 'underline'}}
                        onClick={() => {
                          setOpenEmpModal(true);
                        }}
                      >{`${empData?.employeeCommuteCount}/${empData?.totalCount} Employees Added`}</Button>
                    )}
                  </Box>
                </Box>

                <br></br>
                <Box className='flex'>
                  <Box className='upload-section data-section'>
                    <Button component='label' className='drag-upload'>
                      <input
                        type='file'
                        hidden
                        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={(e: any) => {
                          const fileName = 'emp';
                          handleDocChange(e, fileName);
                        }}
                      />
                      <Box className='upload-icon'>
                        <FileUploadIcon />
                      </Box>
                      <Box>Drag files to upload</Box>
                    </Button>
                    <Box sx={{color: '#677489'}}>OR</Box>
                    {fileUpload1 && <Box>{file1.name}</Box>}
                    {fileUpload1 && (
                      <Box>
                        <LinearWithValueLabel
                          value={progressState}
                          file={file1}
                        />
                      </Box>
                    )}
                    <Button
                      component='label'
                      className='btn-primary upload-btn'
                    >
                      Choose file
                      <input
                        type='file'
                        hidden
                        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={(e) => {
                          const fileName = 'emp';
                          handleDocChange(e, fileName);
                        }}
                      />
                    </Button>
                  </Box>
                  <Box className='scanner data-section'>
                    <Box sx={{color: '#99b1c7', alignSelf: 'flex-start'}}>
                      Scan Code
                    </Box>
                    <EmployeeQRCode url={employeeUrl} />
                    <Box sx={{color: '#3bb3c1', textDecoration: 'underline'}}>
                      Share the code
                    </Box>
                    {/* <Box>
                      <DownloadQR url={employeeUrl} type="emp" />
                    </Box> */}
                  </Box>
                </Box>
              </Box>
              <Box className='formData-view formSection'>
                <Box className='data-title'>
                  Upstream transportation and distribution
                </Box>
                <br></br>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Button
                      className='flex-center-h'
                      sx={{color: '#3BB3C1', textDecoration: 'underline'}}
                    >
                      <a
                        href={`https://${process.env.REACT_APP_AMAZON_BUCKET}.s3.${process.env.REACT_APP_AMAZON_REGION}.amazonaws.com/sample/sample_supply_chain.csv
                    `}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{color: '#3BB3C1', textDecoration: 'none'}}
                        download
                      >
                        Download the Sample template
                      </a>
                    </Button>
                    <Tooltip message={Informatives?.scope3?.rawDetails} />
                  </Box>
                  <Box>
                    {locationId && scopeStart && !isLoadingOther && (
                      <Button
                        className='flex-center-h'
                        sx={{color: '#3bb3c1', textDecoration: 'underline'}}
                        onClick={() => {
                          setOpenRawModal(true);
                        }}
                      >{`${rawData?.supplyChainCount} Upstream Supply Chain Added`}</Button>
                    )}
                  </Box>
                </Box>

                <br></br>
                <Box className='flex'>
                  <Box className='upload-section data-section'>
                    <Button component='label' className='drag-upload'>
                      <input
                        type='file'
                        hidden
                        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={(e) => {
                          const fileName = 'raw';
                          handleDocChange(e, fileName);
                        }}
                      />
                      <Box className='upload-icon'>
                        <FileUploadIcon />
                      </Box>
                      <Box>Drag files to upload</Box>
                    </Button>
                    <Box sx={{color: '#677489'}}>OR</Box>
                    {fileUpload2 && <Box>{file2.name}</Box>}
                    {fileUpload2 && (
                      <Box>
                        <LinearWithValueLabel
                          value={progressState}
                          file={file2}
                        />
                      </Box>
                    )}
                    <Button
                      component='label'
                      className='btn-primary upload-btn'
                    >
                      <input
                        type='file'
                        hidden
                        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={(e) => {
                          const fileName = 'raw';
                          handleDocChange(e, fileName);
                        }}
                      />
                      Choose file
                    </Button>
                  </Box>
                  <Box className='scanner data-section'>
                    <Box sx={{color: '#99b1c7', alignSelf: 'flex-start'}}>
                      Scan Code
                    </Box>
                    <RawMaterialQRCode url={upSupplyUrl} />
                    <Box sx={{color: '#3bb3c1', textDecoration: 'underline'}}>
                      Share the code
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className='qr-section'>
              <Box className='formData-view formSection'>
                <Box className='data-title'>Business Travel</Box>
                <br></br>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Button
                      className='flex-center-h'
                      sx={{color: '#3bb3c1', textDecoration: 'underline'}}
                    >
                      <a
                        href={`https://${process.env.REACT_APP_AMAZON_BUCKET}.s3.${process.env.REACT_APP_AMAZON_REGION}.amazonaws.com/sample/sample_employee_commute.csv`}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{color: '#3BB3C1', textDecoration: 'none'}}
                        download
                      >
                        Download the Sample template
                      </a>
                    </Button>
                    <Tooltip message={Informatives?.scope3?.empDetails} />
                  </Box>

                  <Box>
                    {locationId && scopeStart && !isLoadingOther && (
                      <Button
                        className='flex-center-h'
                        sx={{color: '#3bb3c1', textDecoration: 'underline'}}
                        onClick={() => {
                          setOpenEmpModal(true);
                        }}
                      >{`${empData?.employeeCommuteCount}/${empData?.totalCount} Employees Added`}</Button>
                    )}
                  </Box>
                </Box>

                <br></br>
                <Box className='flex'>
                  <Box className='upload-section data-section'>
                    <Button component='label' className='drag-upload'>
                      <input
                        type='file'
                        hidden
                        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={(e: any) => {
                          const fileName = 'emp';
                          handleDocChange(e, fileName);
                        }}
                      />
                      <Box className='upload-icon'>
                        <FileUploadIcon />
                      </Box>
                      <Box>Drag files to upload</Box>
                    </Button>
                    <Box sx={{color: '#677489'}}>OR</Box>
                    {fileUpload1 && <Box>{file1.name}</Box>}
                    {fileUpload1 && (
                      <Box>
                        <LinearWithValueLabel
                          value={progressState}
                          file={file1}
                        />
                      </Box>
                    )}
                    <Button
                      component='label'
                      className='btn-primary upload-btn'
                    >
                      Choose file
                      <input
                        type='file'
                        hidden
                        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={(e) => {
                          const fileName = 'emp';
                          handleDocChange(e, fileName);
                        }}
                      />
                    </Button>
                  </Box>
                  <Box className='scanner data-section'>
                    <Box sx={{color: '#99b1c7', alignSelf: 'flex-start'}}>
                      Scan Code
                    </Box>
                    <EmployeeQRCode url={businessUrl} />
                    <Box sx={{color: '#3bb3c1', textDecoration: 'underline'}}>
                      Share the code
                    </Box>
                    {/* <Box>
                      <DownloadQR url={employeeUrl} type="emp" />
                    </Box> */}
                  </Box>
                </Box>
              </Box>
              <Box className='formData-view formSection'>
                <Box className='data-title'>
                  Downstream transportation and distribution
                </Box>
                <br></br>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Button
                      className='flex-center-h'
                      sx={{color: '#3bb3c1', textDecoration: 'underline'}}
                    >
                      <a
                        href={`https://${process.env.REACT_APP_AMAZON_BUCKET}.s3.${process.env.REACT_APP_AMAZON_REGION}.amazonaws.com/sample/sample_employee_commute.csv`}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{color: '#3BB3C1', textDecoration: 'none'}}
                        download
                      >
                        Download the Sample template
                      </a>
                    </Button>
                    <Tooltip message={Informatives?.scope3?.empDetails} />
                  </Box>

                  <Box>
                    {locationId && scopeStart && !isLoadingOther && (
                      <Button
                        className='flex-center-h'
                        sx={{color: '#3bb3c1', textDecoration: 'underline'}}
                        onClick={() => {
                          setOpenEmpModal(true);
                        }}
                      >{`${rawData?.supplyChainCount} Downstream Supply Chain Added`}</Button>
                    )}
                  </Box>
                </Box>

                <br></br>
                <Box className='flex'>
                  <Box className='upload-section data-section'>
                    <Button component='label' className='drag-upload'>
                      <input
                        type='file'
                        hidden
                        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={(e: any) => {
                          const fileName = 'emp';
                          handleDocChange(e, fileName);
                        }}
                      />
                      <Box className='upload-icon'>
                        <FileUploadIcon />
                      </Box>
                      <Box>Drag files to upload</Box>
                    </Button>
                    <Box sx={{color: '#677489'}}>OR</Box>
                    {fileUpload1 && <Box>{file1.name}</Box>}
                    {fileUpload1 && (
                      <Box>
                        <LinearWithValueLabel
                          value={progressState}
                          file={file1}
                        />
                      </Box>
                    )}
                    <Button
                      component='label'
                      className='btn-primary upload-btn'
                    >
                      Choose file
                      <input
                        type='file'
                        hidden
                        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={(e) => {
                          const fileName = 'emp';
                          handleDocChange(e, fileName);
                        }}
                      />
                    </Button>
                  </Box>
                  <Box className='scanner data-section'>
                    <Box sx={{color: '#99b1c7', alignSelf: 'flex-start'}}>
                      Scan Code
                    </Box>
                    <EmployeeQRCode url={downSupplyUrl} />
                    <Box sx={{color: '#3bb3c1', textDecoration: 'underline'}}>
                      Share the code
                    </Box>
                    {/* <Box>
                      <DownloadQR url={employeeUrl} type="emp" />
                    </Box> */}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              {Scope3TitleArray.map((value, index) => {
                return (
                  value?.type?.includes(natureOfbusiness) && (
                    <ScopeFields
                      key={index}
                      title={value?.title}
                      type={value?.type}
                      subData={value?.subData}
                      locationId={locationId}
                      scopeStart={scopeStart}
                      scopeData={scope3Data}
                      setData={setScope3Data}
                      setIsDirty={setIsDirty}
                      loading={isLoading}
                      value={value}
                    />
                  )
                );
              })}
            </Box>
            <Box className='formData-view formSection'>
              <Box className='data-title'>Upstream Leased Assets</Box>
              <br></br>
              {fields.map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        m: '10px 50px 10px 0px',
                        font: 'normal normal 600 16px/23px Quicksand',
                        color: '#99B1C7'
                      }}
                    >
                      {index + 1}
                    </Box>
                    <Box className='form-data' sx={{m: '10px 50px 10px 0px'}}>
                      <Controller
                        name={`upStreamLeasedAssests.${index}.propertyName`}
                        control={control}
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <TextField
                            label='Property Name'
                            variant='outlined'
                            value={
                              value.replace(/\b\w/g, (match) =>
                                match.toUpperCase()
                              ) || ''
                            }
                            {...register(
                              `upStreamLeasedAssests.${index}.propertyName` as const
                            )}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Box>
                    <Box className='form-data' sx={{m: '10px 50px 10px 0px'}}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          name={`upStreamLeasedAssests.${index}.timeOfRentedProperty`}
                          control={control}
                          render={({field: {onChange, value}}) => (
                            <DesktopDatePicker
                              open={openDatePicker}
                              onClose={() => setOpenDatePicker(false)}
                              label='Rented From'
                              openTo='year'
                              views={['year']}
                              value={value || null}
                              maxDate={new Date()}
                              disableFuture
                              {...register(
                                `upStreamLeasedAssests.${index}.timeOfRentedProperty`
                              )}
                              onChange={onChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  onClick={() => setOpenDatePicker(true)}
                                  InputLabelProps={{
                                    shrink: value ? true : false
                                  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true
                                  }}
                                />
                              )}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box className='form-data' sx={{m: '10px 50px 10px 0px'}}>
                      <Controller
                        name={`upStreamLeasedAssests.${index}.size`}
                        control={control}
                        render={({
                          field: {onChange, value},
                          fieldState: {error}
                        }) => (
                          <TextField
                            label='Size (Sq mts)'
                            variant='outlined'
                            value={
                              value.replace(/\b\w/g, (match) =>
                                match.toUpperCase()
                              ) || ''
                            }
                            {...register(
                              `upStreamLeasedAssests.${index}.propertyName` as const
                            )}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Box>
                    {index !== 0 && (
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <DeleteIcon color='inherit' />
                      </IconButton>
                    )}
                    {index === watch('upStreamLeasedAssests').length - 1 && (
                      <Box>
                        <IconButton
                          onClick={() => {
                            append(
                              {
                                propertyName: '',
                                timeOfRentedProperty: null,
                                size: ''
                              },
                              {shouldFocus: false}
                            );
                          }}
                        >
                          <AddCircleIcon color='primary' />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        <Box className='df-right'>
          {/* <Button onClick={handleDraft} className="btn secondary-btn ">
            Save as Draft
          </Button> */}
          <Button
            type='submit'
            id='submit'
            sx={{cursor: 'none', pointerEvents: 'none'}}
          ></Button>
          <Button
            type='submit'
            className='btn secondary-btn'
            onClick={() => setIsSubmit(true)}
          >
            Save
          </Button>
        </Box>
        {/* {submitted && isSubmit && (
          <Modal
            open={submitted}
            setOpen={setSubmitted}
            children={
              <CancelConfirmModal
                setOpen={setSubmitted}
                textProps={{
                  mainText:
                    'The footprinting data has been submitted successfully! Thank you for taking the effort to save the planet!',
                  confirmText: 'OK'
                }}
                handleOk={handleSubmitCloseModal}
              />
            }
          />
        )} */}
        {openEmpModal && (
          <Modal
            open={openEmpModal}
            max={true}
            setOpen={setOpenEmpModal}
            children={
              <EmpCommuteModal
                empData={empData}
                locationId={locationId}
                type='emp'
                scopeEnd={scopeEnd}
                scopeStart={scopeStart}
              />
            }
          />
        )}
        {openRawModal && (
          <Modal
            open={openRawModal}
            max={true}
            setOpen={setOpenRawModal}
            children={
              <EmpCommuteModal
                empData={rawData}
                locationId={locationId}
                scopeEnd={scopeEnd}
                scopeStart={scopeStart}
                type=''
              />
            }
          />
        )}
      </form>
    </Box>
  );
};

export default Scope3;
