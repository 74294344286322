import React, {useEffect, useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import salesChart from '../../../../assets/sales.svg';
import carbonChart from '../../../../assets/carbon-chart.svg';
import mailImage from '../../../../assets/mail-icon.png';
import customerIcon from '../../../../assets/customer.svg';
import customerIconActive from '../../../../assets/customer-active.svg';
import customerTon from '../../../../assets/ton.svg';
import customerTonActive from '../../../../assets/ton-active.svg';
import customerTop from '../../../../assets/top.svg';
import customerTopActive from '../../../../assets/top-active.svg';
import leaderBoardImg from '../../../../assets/leaderboard.png';
import trendingUp from '../../../../assets/trending_up.svg';
import trendingDown from '../../../../assets/trending_down.svg';
import currentLevel1 from '../../../../assets/Levels/currentLevel1.svg';
import currentLevel2 from '../../../../assets/Levels/currentLevel2.svg';
import currentLevel3 from '../../../../assets/Levels/currentLevel3.svg';
import activeLevel1 from '../../../../assets/Levels/activeLevel1.svg';
import activeLevel2 from '../../../../assets/Levels/activeLevel2.svg';
import activeLevel3 from '../../../../assets/Levels/activeLevel3.svg';
import inActiveLevel1 from '../../../../assets/Levels/inActiveLevel1.svg';
import inActiveLevel2 from '../../../../assets/Levels/inActiveLevel2.svg';
import inActiveLevel3 from '../../../../assets/Levels/inActiveLevel3.svg';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import {useAuth0} from '@auth0/auth0-react';
import axios from 'axios';
import AllChart from '../utility/charts/CustomerChart';
import Tooltip from 'Shared/Layouts/Tooltip';
import Informatives from '../../../../Shared/Informatives';
import Loader from '../utility/Loading';

const DashCon: React.FC = () => {
  const [value, setValue] = useState('');
  const [customer, setCustomer] = useState<any>();
  const [partner, setPartner] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      await axios
        .get(`${BACKEND_ENDPOINT}/users/count?userType=user`, config)
        .then((res) => {
          setCustomer(res.data);
        });
      await axios
        .get(`${BACKEND_ENDPOINT}/users/count?userType=partner`, config)
        .then((res) => {
          setPartner(res.data);
        });
    };

    fetchApi().catch((err) => {
      console.log('dash', err);
    });
  }, []);

  const [alignment, setAlignment] = React.useState<string | null>('AllTime');
  const {user} = useAuth0();

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setAlignment(newAlignment);
  };

  const userProfile = partner?.photo || user?.picture;

  const userLevel: string = 'novice';

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return !customer && !partner ? (
    <Loader />
  ) : (
    <Box className='dashboard'>
      <Box className='leftSection'>
        <Paper className='section'>
          <Box className='userBadge'>
            <Box>Current Level</Box>
            <Box>
              <Box
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup='true'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <Box className='badge'>
                  <img
                    src={
                      userLevel === 'novice'
                        ? activeLevel1
                        : userLevel === 'vet'
                        ? activeLevel2
                        : activeLevel3
                    }
                    alt='currentLevel'
                  />
                  <Box>{userLevel}</Box>
                </Box>
                <Popover
                  id='mouse-over-popover'
                  sx={{
                    pointerEvents: 'none'
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Box className='badges'>
                    <img
                      src={
                        userLevel === 'novice' ? currentLevel1 : inActiveLevel1
                      }
                      alt=''
                    />
                    <span>NOVICE</span>
                    <img
                      src={userLevel === 'vet' ? currentLevel2 : inActiveLevel2}
                      alt=''
                    />
                    <span>VET</span>
                    <img
                      src={
                        userLevel === 'expert' ? currentLevel3 : inActiveLevel3
                      }
                      alt=''
                    />
                    <span>EXPERT</span>
                  </Box>
                </Popover>
              </Box>
            </Box>
          </Box>
          <Box className='userCard'>
            <Box>
              <img
                src={userProfile}
                alt='avatorImg'
                className='userImg'
                referrerPolicy='no-referrer'
              />
            </Box>
            <Box>{user?.name}</Box>
            <Typography>ID: 998787</Typography>
            <Typography>Email: {user?.email}</Typography>
            <br></br>
            <h4>
              Badges
              <Tooltip message={Informatives.dashboard.badge} />
            </h4>
          </Box>
          <Box className='badges-section'>
            <Box
              className={
                customer?.count >= 3 ? 'badges-list active' : 'badges-list'
              }
            >
              <img
                src={customer?.count >= 3 ? customerIconActive : customerIcon}
                alt='sale'
              />
              <Box className='count'>3</Box>
              <Box className='label'>Customers</Box>
            </Box>
            <Box
              className={
                customer?.count >= 5 ? 'badges-list active' : 'badges-list'
              }
            >
              <img
                src={customer?.count >= 5 ? customerIconActive : customerIcon}
                alt='sale'
              />
              <Box className='count'>5</Box>
              <Box className='label'>Customers</Box>
            </Box>
            <Box
              className={
                customer?.count >= 10 ? 'badges-list active' : 'badges-list'
              }
            >
              <img
                src={customer?.count >= 10 ? customerIconActive : customerIcon}
                alt='sale'
              />
              <Box className='count'>10</Box>
              <Box className='label'>Customers</Box>
            </Box>
            <Box
              className={
                customer?.count >= 20 ? 'badges-list active' : 'badges-list'
              }
            >
              <img
                src={customer?.count >= 20 ? customerIconActive : customerIcon}
                alt='sale'
              />
              <Box className='count'>20</Box>
              <Box className='label'>Customers</Box>
            </Box>
            <Box
              className={
                customer?.count >= 50 ? 'badges-list active' : 'badges-list'
              }
            >
              <img
                src={customer?.count >= 50 ? customerIconActive : customerIcon}
                alt='sale'
              />
              <Box className='count'>50</Box>
              <Box className='label'>Customers</Box>
            </Box>
          </Box>
          <Box className='badges-section'>
            <Box className='badges-list'>
              <img src={customerTon} alt='sale' />
              <Box className='count'>100</Box>
              <Box className='label'>Ton</Box>
            </Box>
            <Box className='badges-list'>
              <img src={customerTon} alt='sale' />
              <Box className='count'>250</Box>
              <Box className='label'>Ton</Box>
            </Box>
            <Box className='badges-list active'>
              <img src={customerTonActive} alt='sale' />
              <Box className='count'>500</Box>
              <Box className='label'>Ton</Box>
            </Box>
            <Box className='badges-list'>
              <img src={customerTon} alt='sale' />
              <Box className='count'>1000</Box>
              <Box className='label'>Ton</Box>
            </Box>
            <Box className='badges-list'>
              <img src={customerTon} alt='sale' />
              <Box className='count'>2000</Box>
              <Box className='label'>Ton</Box>
            </Box>
          </Box>
          <Box className='badges-section'>
            <Box className='badges-list active'>
              <img src={customerTopActive} alt='sale' />
              <Box className='count'>1</Box>
              <Box className='label'>Top</Box>
            </Box>
            <Box className='badges-list'>
              <img src={customerTop} alt='sale' />
              <Box className='count'>3</Box>
              <Box className='label'>Top</Box>
            </Box>
            <Box className='badges-list'>
              <img src={customerTop} alt='sale' />
              <Box className='count'>5</Box>
              <Box className='label'>Top</Box>
            </Box>
            <Box className='badges-list'>
              <img src={customerTop} alt='sale' />
              <Box className='count'>10</Box>
              <Box className='label'>Top</Box>
            </Box>
            <Box className='badges-list'>
              <img src={customerTop} alt='sale' />
              <Box className='count'>20</Box>
              <Box className='label'>Top</Box>
            </Box>
          </Box>
          <a href='https://web.telegram.org/' target='_blank' rel='noreferrer'>
            <img src={mailImage} alt='sale' className='mailImg' />
          </a>
        </Paper>
      </Box>
      <Grid container>
        <Box className='dashboard-tiles'>
          <Grid item md={8}>
            <Paper sx={{marginBottom: '20px'}}>
              <Box>
                <h4>
                  Sales
                  <Tooltip message={Informatives.dashboard.sales} />
                </h4>
              </Box>
              <Box>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label='text alignment'
                  sx={{
                    '& .MuiToggleButton-root': {
                      backgroundColor: 'transparent',
                      border: 'none',
                      color: '#677489',
                      textTransform: 'capitalize',
                      '&.Mui-selected': {
                        backgroundColor: '#DDF7FA',
                        color: '#3BB3C1'
                      }
                    }
                  }}
                >
                  <ToggleButton value='AllTime' aria-label='left aligned'>
                    <h5>All Time</h5>
                  </ToggleButton>
                  <ToggleButton value='Year' aria-label='centered'>
                    <h5>This Year</h5>
                  </ToggleButton>
                  <ToggleButton value='Week' aria-label='right aligned'>
                    <h5>This Week</h5>
                  </ToggleButton>
                  <ToggleButton value='Today' aria-label='justified'>
                    <h5>Today</h5>
                  </ToggleButton>
                </ToggleButtonGroup>
                <Box>
                  <Box>
                    <img src={salesChart} alt='sale'></img>
                  </Box>
                </Box>
              </Box>
            </Paper>
            <Paper>
              <Box>
                <h4>
                  Carbon Offsets Achieved
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Box className='chartDropdown'>
                      <FormControl fullWidth>
                        <InputLabel id='timePeriod'>Time Period</InputLabel>
                        <Select
                          className='dropdown'
                          sx={{padding: '0'}}
                          id='timePeriod'
                          value={value}
                          label='Time Period'
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>All Time</MenuItem>
                          <MenuItem value={20}>This Year</MenuItem>
                          <MenuItem value={30}>This Week</MenuItem>
                          <MenuItem value={40}>Today</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Tooltip
                      message={Informatives.dashboard.carbonOffsetAchieved}
                    />
                  </Box>
                </h4>
              </Box>
              <Box>
                <Grid container>
                  <Grid item md={7} sx={{paddingLeft: '0px'}}>
                    <Box>
                      <img
                        src={carbonChart}
                        alt=''
                        style={{marginLeft: '-35px'}}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={5}>
                    <Box>
                      <List
                        className='company-list'
                        sx={{
                          width: '100%',
                          maxWidth: 360,
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 300
                          // "& ul": { padding: 0 },
                        }}
                        // subheader={<li />}
                      >
                        <ListItem key={1}>
                          <ListItemText primary={`Company name`} />
                          <ListItemText
                            sx={{textAlign: 'right'}}
                            primary={`12T Co2`}
                          />
                        </ListItem>
                        <ListItem key={2}>
                          <ListItemText primary={`Company name`} />
                          <ListItemText
                            sx={{textAlign: 'right'}}
                            primary={`12T Co2`}
                          />
                        </ListItem>
                        <ListItem key={3}>
                          <ListItemText primary={`Company name`} />
                          <ListItemText
                            sx={{textAlign: 'right'}}
                            primary={`12T Co2`}
                          />
                        </ListItem>
                        <ListItem key={4}>
                          <ListItemText primary={`Company name`} />
                          <ListItemText
                            sx={{textAlign: 'right'}}
                            primary={`12T Co2`}
                          />
                        </ListItem>
                        <ListItem key={5}>
                          <ListItemText primary={`Company name`} />
                          <ListItemText
                            sx={{textAlign: 'right'}}
                            primary={`12T Co2`}
                          />
                        </ListItem>
                        <ListItem key={6}>
                          <ListItemText primary={`Company name`} />
                          <ListItemText
                            sx={{textAlign: 'right'}}
                            primary={`12T Co2`}
                          />
                        </ListItem>
                        <ListItem key={7}>
                          <ListItemText primary={`Company name`} />
                          <ListItemText
                            sx={{textAlign: 'right'}}
                            primary={`12T Co2`}
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={4}>
            <Box className='dashTwoCol'>
              <Paper sx={{marginBottom: '20px'}}>
                <h4>
                  Total Customers
                  <Tooltip message={Informatives.dashboard.totalCustomers} />
                </h4>
                {!customer ? (
                  <CircularProgress color='success' />
                ) : (
                  <>
                    <Typography
                      sx={{
                        color: '#99B1C7',
                        fontSize: '2.375rem',
                        width: '22px'
                      }}
                    >
                      {customer?.count || 0}
                    </Typography>
                    <Box display='flex'>
                      <img
                        src={customer?.growth > 0 ? trendingUp : trendingDown}
                        alt=''
                      />
                      <Typography
                        color={customer?.growth > 0 ? '#26AF5F' : '#BA3A21'}
                      >{`${Math.floor(customer?.growth) || 0} %`}</Typography>
                    </Box>
                    <Box>
                      <AllChart data={customer?.chartData} />
                    </Box>
                  </>
                )}
              </Paper>
              <Paper sx={{marginBottom: '20px'}}>
                <h4>
                  Total Partners
                  <Tooltip message={Informatives.dashboard.totalPartners} />
                </h4>
                {!partner ? (
                  <CircularProgress color='success' />
                ) : (
                  <>
                    <Typography
                      sx={{
                        color: '#99B1C7',
                        fontSize: '2.375rem',
                        width: '22px'
                      }}
                    >
                      {partner?.count || 0}
                    </Typography>
                    <Box display='flex'>
                      <img
                        src={
                          partner?.growth > 0
                            ? trendingUp
                            : partner?.growth === 0
                            ? ''
                            : trendingDown
                        }
                        alt=''
                      />
                      <Typography
                        color={partner?.growth > 0 ? '#26AF5F' : '#BA3A21'}
                      >{`${Math.floor(partner?.growth) || 0} %`}</Typography>
                    </Box>
                    <Box>
                      <AllChart data={partner?.chartData} />
                    </Box>
                  </>
                )}
              </Paper>
            </Box>
            <Paper>
              <h4>
                Leadership Board
                <Tooltip message={Informatives.dashboard.leaderBoard} />
              </h4>
              <img src={leaderBoardImg} alt='' style={{width: '100%'}}></img>
            </Paper>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default DashCon;
