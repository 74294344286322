/* eslint-disable array-callback-return */
import React, {memo, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import axios from 'axios';
import ScopeFieldsView from './ScopeFieldsView';

type scope1ViewProps = {
  locationId: any;
  year?: string;
  customer: any;
  openOn: boolean;
};

const Scope1View = ({locationId, year, customer, openOn}: scope1ViewProps) => {
  const [scope1ViewData, setScope1ViewData] = useState<any>();
  const [refrigerant, setRefrigerant] = React.useState<any[]>([]);
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  type Scope1TitleArrayType = {
    title: string;
    type: string[];
    subData: {sub: string; unit: string}[];
  }[];

  const findRefrigerant = (data: any) => {
    const filteredData = data?.filter((value: any, id: number) => {
      return value?.category === 'Fuel consumption by refrigerant';
    });
    const set = [
      ...new Set(
        filteredData?.map((item: any) =>
          JSON.stringify({sub: item?.type, unit: item?.metric})
        )
      )
    ].map((item: any) => JSON.parse(item));
    return set;
  };

  const Scope1TitleArray: Scope1TitleArrayType = [
    {
      title: 'Fuel consumption in kitchen',
      type: ['hotel', 'restaurant'],
      subData: [
        {sub: 'coal', unit: 'Kg'},
        {sub: 'LPG', unit: 'Kg'}
      ]
    },
    {
      title: 'Fuel consumption by company owned vehicle',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {sub: 'diesel', unit: 'Ltr'},
        {sub: 'petrol', unit: 'Ltr'},
        {sub: 'LPG', unit: 'Kg'},
        {sub: 'CNG', unit: 'Ltr'}
      ]
    },
    {
      title: 'Fuel consumption by diesel generator',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [{sub: 'diesel', unit: 'Ltr'}]
    },
    {
      title: 'Fuel consumption by refrigerant',
      type: ['hotel', 'restaurant', 'retail'],
      subData: refrigerant
    },
    {
      title: 'Fuel consumption by fire extinguisher (CO2 type)',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [{sub: `CO2`, unit: 'Kg'}]
    }
  ];

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };
    if (openOn) {
      const fetchApi = async () => {
        if (locationId && year) {
          await axios
            .get(
              `${BACKEND_ENDPOINT}/footprinting/scope1?locationId=${
                locationId ? locationId : ''
              }&fromDate=${year?.slice(
                0,
                4
              )}-04-01 00:00:00&toDate=${year?.slice(5, 9)}-03-31 00:00:00`,
              config
            )
            .then((res) => {
              setScope1ViewData(res.data.scope1);
              setRefrigerant(findRefrigerant(res?.data?.scope1?.data));
            });
        }
      };

      fetchApi().catch((err) => {
        console.log('errScope1View:', err);
      });
    }
  }, [locationId, year, openOn]);

  const category = customer?.category;

  return (
    <Box>
      <Box>
        {Scope1TitleArray.map((value, index) => {
          return (
            value?.type?.includes(category) && (
              <ScopeFieldsView
                key={index}
                year={year}
                title={value?.title}
                type={value?.type}
                subData={value?.subData}
                locationId={locationId}
                customer={customer}
                scopeData={scope1ViewData}
                scopeType='scope1'
              />
            )
          );
        })}
      </Box>
    </Box>
  );
};

export default memo(Scope1View);
