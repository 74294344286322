import { Paper, Typography } from "@mui/material";
import React from "react";

type FileViewProp = {
  file?: any;
  fileType?: any;
};

const ViewDoc = ({ file, fileType }: FileViewProp) => {
  return (
    <>
      <h4>{fileType}</h4>
      {file ? (
        <img src={file} alt={file.split("/").pop()} />
      ) : (
        <Typography>No Document Uploaded</Typography>
      )}
    </>
  );
};

export default ViewDoc;
