import React, {useEffect, useState} from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import moment from 'moment';

type YearProps = {
  fullYear?: any;
  startPeriod?: any;
  endPeriod?: any;
  handleStartEnd: any;
};

export default function Year({
  fullYear,
  startPeriod,
  endPeriod,
  handleStartEnd
}: YearProps) {
  const startDate = !!startPeriod
    ? Number(moment(startPeriod).format('YYYY'))
    : 0;

  const endDate = !!endPeriod ? Number(moment(endPeriod).format('YYYY')) : 0;

  const [start, setStart] = useState<number>();
  const [end] = useState<number>(endDate);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    setStart(end - 1);
    handleStartEnd(start, end);
  }, [handleStartEnd, start, end, startDate, endDate]);

  useEffect(() => {
    let years = [];
    for (let year: any = startDate; year <= endDate - 1; year++) {
      let value = `${year}-${year + 1}`;
      years.push(value);
    }

    setSelectOptions(years);
  }, [startDate, endDate]);

  useEffect(() => {
    setSelectedOption(fullYear);
  }, [fullYear]);

  useEffect(() => {
    let startValue = selectedOption?.slice(0, 4);

    let endValue = selectedOption?.slice(5, 9);

    handleStartEnd(startValue, endValue);
  }, [selectedOption, selectOptions, handleStartEnd]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as string);
  };

  return (
    <Box className='App'>
      <Box display='flex' justifyContent='center' alignItems='center'>
        <FormControl fullWidth>
          <InputLabel id='year'>Year</InputLabel>
          <Select
            labelId='year'
            id='demo-simple-select'
            defaultValue=''
            value={selectedOption || ''}
            label='Year'
            onChange={handleChange}
            sx={{width: '150px', mb: '20px'}}
          >
            {selectOptions.map((year, i) => {
              return (
                <MenuItem key={i} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
