const formattedAddress = (
  address: string,
  name: string,
  address_components: any
) => {
  const arrayString = address.split(',').map((item) => item?.trim());
  let mainAddress: string[] = [];
  let requiredPart: string[] = [];
  let string: string[] = [];

  const stringFunction = (mainString: string, isUk?: boolean) => {
    var match = mainString.match(
      isUk
        ? /^([A-Za-z\s]+?)\s+([A-Z0-9]{1,4}\s*[0-9][A-Z]{2})$/
        : /^([A-Za-z\s]+)([A-Za-z0-9\s]+)$/
    );
    if (match) {
      const location = match[1];
      const code = match[2];
      return [location, code];
    } else {
      return [mainString];
    }
  };

  if (arrayString[arrayString.length - 1] === 'UK') {
    mainAddress = arrayString.slice(0, arrayString.length - 2);

    requiredPart = arrayString.slice(arrayString.length - 2);

    string = stringFunction(requiredPart[requiredPart.length - 2], true);

    requiredPart.splice(requiredPart.length - 2, 1, ...string);

    // requiredPart[requiredPart.length - 2] =
    //   requiredPart[requiredPart.length - 3] +
    //   ' ' +
    //   requiredPart[requiredPart.length - 2];

    requiredPart.splice(requiredPart.length - 3, 1);
    requiredPart.splice(requiredPart.length - 2, 0, '');
  } else {
    mainAddress = arrayString.slice(0, arrayString.length - 3);

    requiredPart = arrayString.slice(arrayString.length - 3);

    string = stringFunction(requiredPart[requiredPart.length - 2]).filter(
      (item) => item !== ''
    );

    requiredPart.splice(requiredPart.length - 2, 1, ...string);
  }

  if (requiredPart[requiredPart.length - 1] === 'UK') {
    requiredPart[requiredPart.length - 1] = 'United Kingdom';
  } else if (requiredPart[requiredPart.length - 1] === 'USA') {
    requiredPart[requiredPart.length - 1] = 'United States';
    requiredPart[requiredPart.length - 3] = address_components.find(
      (item: any) =>
        item.types.includes('administrative_area_level_1') &&
        item.short_name ===
          requiredPart[requiredPart.length - 3].replace(/ /g, '')
    )?.long_name;
  }
  const combinedArray: string[] = ['', ''];

  const fullLength = Boolean(mainAddress.length % 2)
    ? Math.floor(mainAddress.length / 2) + 1
    : Math.floor(mainAddress.length / 2);

  mainAddress.forEach((address, index) => {
    if (fullLength > index) {
      combinedArray[0] += combinedArray[0] === '' ? address : ',' + address;
    } else {
      combinedArray[1] += combinedArray[1] === '' ? address : ',' + address;
    }
  });

  combinedArray.push(...requiredPart);
  combinedArray.unshift(name.replace(/[^\w\/\- ]/g, ','));
  combinedArray[combinedArray.length - 1] = combinedArray.splice(
    combinedArray.length - 2,
    1,
    combinedArray[combinedArray.length - 1]
  )[0];
  return combinedArray.map((item) => item?.trim());
};

export default formattedAddress;
