import {Box, Button, Tooltip} from '@mui/material';
import React from 'react';
import {Link} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ScopeSingleFieldView from './ScopeSingleFieldView';

type ScopeFieldView = {
  year?: string;
  title: string;
  type: string[];
  subData: {sub: string; unit: string}[];
  locationId: string;
  customer: any;
  scopeData: any;
  scopeType: string;
};

const ScopeFieldsView = ({
  year,
  title,
  subData,
  locationId,
  customer,
  scopeData,
  scopeType
}: ScopeFieldView) => {
  const months = [
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar'
  ];

  return (
    <Box className='formData-view formSection'>
      <Box className='title-w-btn'>
        <Box className='data-title' id='fuleC'>
          {title.charAt(0).toUpperCase() + title.slice(1)}
        </Box>
        <Box className='mline'></Box>
        <Button
          component={Link}
          to={`/customer/${customer?._id}/footprint/${
            scopeType === 'scope1' ? 0 : scopeType === 'scope2' ? 1 : 2
          }/${year}/${locationId}`}
          state={{
            year,
            locationId,
            index: scopeType === 'scope1' ? 0 : scopeType === 'scope2' ? 1 : 2,
            customer
          }}
          disabled={
            customer?.appStatus === 'call scheduled' ||
            customer?.appStatus === 'invite and pay'
          }
        >
          <Tooltip
            title={
              customer?.appStatus === 'call scheduled' ||
              customer?.appStatus === 'invite and pay'
                ? 'Please pay before you proceed'
                : ''
            }
          >
            <>
              <EditIcon color='inherit' /> Edit Item
            </>
          </Tooltip>
        </Button>
      </Box>
      <Box className='carbon-section'>
        <Box className='cs-section head'>
          <Box>Fuel</Box>

          {/* <Box className="cs-flex"> */}
          <Box>Units</Box>
          {/* <Box className="cs-month"> */}
          {months.map((month, i) => {
            return <Box key={i}>{month}</Box>;
          })}
        </Box>
        {subData?.map((value: any, index: number) => {
          return (
            <ScopeSingleFieldView
              key={index}
              title={title}
              subData={value}
              scopeData={scopeData}
            />
          );
        })}
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default ScopeFieldsView;
