import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useForm, useFieldArray, Controller} from 'react-hook-form';
import LinearLoader from '../utility/LinearLoader';

type EmpRawProps = {
  empRawDetails: any;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
};

const RawSupplyCommute = ({empRawDetails, setUpdated}: EmpRawProps) => {
  const [editIndex, setEditIndex] = useState<any>(null);
  let config = {
    headers: {
      authorization: `Bearer ${
        localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }`
    }
  };
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  useEffect(() => {
    setValue('RawDetails', empRawDetails);
  }, [empRawDetails]);

  const frequencySelect = [
    {
      name: 'Weekly'
    },
    {
      name: 'Monthly'
    },
    {
      name: 'Yearly'
    }
  ];

  const modeOfTransport = [
    {
      name: 'Auto Petrol'
    },
    {
      name: 'Auto CNG'
    },
    {
      name: 'Auto LPG'
    },
    {
      name: 'Passenger Train'
    },
    {
      name: 'Local Train'
    },
    {
      name: 'Bike or Scooter'
    },
    {
      name: 'Electric Scooter'
    },
    {
      name: 'Small Car on Petrol'
    },
    {
      name: 'Small Car on CNG'
    },
    {
      name: 'Medium Car on Petrol'
    },
    {
      name: 'Medium Car on CNG'
    },
    {
      name: 'Medium Car on LPG'
    },
    {
      name: 'Medium Car on Diesel'
    },
    {
      name: 'Large Car on Petrol'
    },
    {
      name: 'Large Car on CNG'
    },
    {
      name: 'Large Car on Diesel'
    },
    {
      name: 'Light Commercial Vehicle'
    },
    {
      name: 'Transport Freight Lorry'
    },
    {
      name: 'Transport Freight Lorry with Cooling System'
    }
  ];

  const {
    control,
    register,
    setValue,
    watch,
    trigger,
    formState: {errors}
  } = useForm({
    defaultValues: {
      RawDetails: [
        {
          vendorCode: '',
          firstName: '',
          lastName: '',
          frequency: '',
          email: '',
          phone: '',
          quantityOfMaterial: '',
          mode: '',
          vendorAddress: '',
          supplyAddress: ''
        }
      ]
    },
    shouldFocusError: false,
    mode: 'onChange'
  });

  const handleDeleteEmp = (data: any, i: number) => {
    const fetchApi = async () => {
      await axios
        .delete(`${BACKEND_ENDPOINT}/supplyChain`, {
          data: {_id: data?._id},
          headers: config.headers
        })
        .then((res) => {
          remove(i);
          setUpdated((prev) => !prev);
        });
    };
    fetchApi().catch((err) => {
      console.log('err:', err);
    });
    setEditIndex(null);
  };

  const handleUpdateEmp = (data: any, errors: any, i: number) => {
    const empData = data?.RawDetails?.[i];

    if (!trigger()) {
      return;
    }

    const fetchApi = async () => {
      await axios
        .put(`${BACKEND_ENDPOINT}/supplyChain`, empData, config)
        .then((res) => {
          setUpdated((prev) => !prev);
        });
    };

    fetchApi().catch((err) => {
      console.log('err:', err);
    });

    setEditIndex(null);
  };

  const {fields, remove} = useFieldArray({control, name: 'RawDetails'});

  return !empRawDetails ? (
    <LinearLoader />
  ) : (
    <TableContainer sx={{width: '100%'}}>
      <Table stickyHeader>
        <TableHead>
          <TableRow style={{backgroundColor: '#f5f5f5', height: '35px'}}>
            <TableCell>#</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Vender Code</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Frequency</TableCell>
            <TableCell>Mode of Transport</TableCell>
            <TableCell>Quantity of Material</TableCell>
            <TableCell>Vendor Address</TableCell>
            <TableCell>Supply Address</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item: any, index: number) => {
            return editIndex === index ? (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <Controller
                    name={`RawDetails.${index}.firstName`}
                    control={control}
                    defaultValue={item.firstName}
                    rules={{
                      required: 'First Name is Required'
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        {...register(`RawDetails.${index}.firstName`, {
                          required: 'First Name is Required'
                        })}
                        label='First Name *'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`RawDetails.${index}.lastName`}
                    control={control}
                    defaultValue={item.lastName}
                    rules={{
                      required: 'Last Name is Required'
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        {...register(`RawDetails.${index}.lastName`)}
                        label='Last Name *'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`RawDetails.${index}.vendorCode`}
                    control={control}
                    defaultValue={item.vendorCode}
                    render={({field: {onChange, value}}) => (
                      <TextField
                        type='text'
                        {...register(`RawDetails.${index}.vendorCode`)}
                        label='Vendor Code'
                        variant='outlined'
                        value={value}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`RawDetails.${index}.email`}
                    control={control}
                    defaultValue={item.email}
                    rules={{
                      required: false,
                      minLength: {
                        value: 5,
                        message: 'Email ID Should be more than 4 letters'
                      },
                      maxLength: {
                        value: 320,
                        message: 'Email ID Should not be more than 320 letters'
                      },
                      validate: {
                        reg: (value) => {
                          if (!value) return;
                          return (
                            RegExp(
                              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
                            ).test(value) || 'Please Enter Valid Email ID'
                          );
                        }
                      }
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        {...field}
                        label='Email'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>

                <TableCell>
                  <Controller
                    name={`RawDetails.${index}.phone`}
                    control={control}
                    rules={{
                      required: false,
                      validate: {
                        reg: (value) => {
                          if (!value) return true;
                          return (
                            RegExp(/^(?:\+91|0)?[6-9]\d{9}$/).test(value) ||
                            'Please Enter 10 Digit Valid Mobile Number'
                          );
                        }
                      }
                    }}
                    defaultValue={item.phone}
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <TextField
                        type='number'
                        value={value?.replace(/\D/g, '')}
                        onChange={onChange}
                        label='Mobile'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`RawDetails.${index}.frequency`}
                    control={control}
                    defaultValue=''
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <FormControl error={error ? true : false}>
                        <InputLabel id='category'>Frequency *</InputLabel>
                        <Select
                          id='typeOfBusiness'
                          labelId='category'
                          label='Frequency *'
                          value={value || ''}
                          {...register(`RawDetails.${index}.frequency`, {
                            validate: {
                              noValue: (value) => {
                                return value !== '' || 'Frequency is Required';
                              }
                            }
                          })}
                          onChange={onChange}
                          variant='outlined'
                        >
                          {frequencySelect.map((item, i) => {
                            return (
                              <MenuItem key={i} value={item.name}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {error ? error?.message : null}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`RawDetails.${index}.mode`}
                    control={control}
                    defaultValue=''
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <FormControl error={error ? true : false}>
                        <InputLabel id='category'>
                          Mode of Transport *
                        </InputLabel>
                        <Select
                          id='typeOfBusiness'
                          labelId='category'
                          label='Mode of Transport *'
                          value={value || ''}
                          {...register(`RawDetails.${index}.mode`, {
                            validate: {
                              noValue: (value) => {
                                return (
                                  value !== '' ||
                                  'Mode of Transport is Required'
                                );
                              }
                            }
                          })}
                          onChange={onChange}
                          variant='outlined'
                        >
                          {modeOfTransport.map((item, i) => {
                            return (
                              <MenuItem key={i} value={item.name}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {error ? error?.message : null}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </TableCell>
                <TableCell className='form-data'>
                  <Controller
                    name={`RawDetails.${index}.quantityOfMaterial`}
                    control={control}
                    defaultValue=''
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <TextField
                        id='quantityOfMaterial'
                        label='Quantity of Material Supplied (Tons) *'
                        variant='outlined'
                        type='number'
                        {...register(`RawDetails.${index}.quantityOfMaterial`, {
                          required: 'Quantity of Material is required',
                          validate: {
                            nonZero: (value) => {
                              return (
                                Number(value) > 0 ||
                                'Quantity cannot be negative'
                              );
                            }
                          },
                          valueAsNumber: true
                        })}
                        onKeyDown={(event) => {
                          if (
                            event.code === 'ArrowDown' ||
                            event.code === 'ArrowUp' ||
                            event.code === 'Enter'
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onFocus={(e) => {
                          e.target.addEventListener(
                            'wheel',
                            function (e) {
                              e.preventDefault();
                            },
                            {passive: false}
                          );
                        }}
                        error={!!error}
                        helperText={!!error ? error.message : ''}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`RawDetails.${index}.vendorAddress`}
                    control={control}
                    defaultValue={item.homeAddress}
                    rules={{
                      required: 'Vendor Address is Required'
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        label='Vendor Address *'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`RawDetails.${index}.supplyAddress`}
                    control={control}
                    defaultValue={item.officeAddress}
                    rules={{
                      required: 'Supply Address is Required'
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        label='Supply Address *'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    onClick={async () => {
                      if (await trigger()) {
                        handleUpdateEmp(watch(), errors, index);
                      }
                    }}
                  >
                    Save
                  </Button>
                </TableCell>
                <TableCell>
                  <Button onClick={() => setEditIndex(null)}>Cancel</Button>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.firstName}</TableCell>
                <TableCell>{item.lastName}</TableCell>
                <TableCell>{item.vendorCode}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>{item.frequency}</TableCell>
                <TableCell>{item.mode}</TableCell>
                <TableCell>{item.quantityOfMaterial}</TableCell>
                <TableCell>{item.vendorAddress}</TableCell>
                <TableCell>{item.supplyAddress}</TableCell>
                <TableCell>
                  <Button onClick={() => setEditIndex(index)}>Edit</Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleDeleteEmp(item, index);
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          {empRawDetails?.length === 0 && (
            <TableRow key={0}>
              <TableCell style={{textAlign: 'center'}} colSpan={12}>
                No Records Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RawSupplyCommute;
