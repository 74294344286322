import {
  AlertColor,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import tooltipMsg from 'Shared/Informatives';
import Tooltip from 'Shared/Layouts/Tooltip';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import LinearLoader from '../../utility/LinearLoader';
import Modal from '../../utility/Modal';
import CancelConfirmModal from '../../utility/CancelConfirmModal';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import {useForm, useFieldArray, Controller} from 'react-hook-form';
import PartnerConstant from 'Shared/Constants/PartnerConstant';

interface ReductionProps {
  locationId: string;
  scopeStart: any;
  scopeEnd: any;
  natureOfbusiness: string;
  locationState: any;
  setOpenSnackbar: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      status: AlertColor;
      message: string;
    }>
  >;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  isDirty: boolean;
}

const Reductions = ({
  locationId,
  scopeStart,
  scopeEnd,
  natureOfbusiness,
  setOpenSnackbar,
  locationState,
  setIsDirty,
  isDirty
}: ReductionProps) => {
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState<boolean[]>([false]);
  const [initiator, setInitiator] = useState<any>([]);
  const [isSubmit, setIsSubmit] = useState(false);

  interface reductionType {
    companyId: string;
    locationId: string;
    initiators: {
      initiatorId: string;
      initiatorValue: string;
      startDate: string;
      unit: string;
    }[];
    creditPurchased: string;
    fromYear: string;
    toYear: string;
    __id: string;
  }

  const defaultValue: reductionType = {
    companyId: locationState._id,
    locationId: locationId,
    initiators: [
      {initiatorId: '', initiatorValue: '', startDate: '', unit: ''}
    ],
    creditPurchased: '',
    fromYear: scopeStart,
    toYear: scopeEnd,
    __id: ''
  };

  useEffect(() => {
    if (isDirty) {
      const autosave = setInterval(() => {
        document.getElementById('submit')?.click();
        setIsUpdate(true);
      }, PartnerConstant.autoSaveInterval);
      return () => {
        clearInterval(autosave);
      };
    }
  }, [isDirty]);

  let config = {
    headers: {
      authorization: `Bearer ${
        localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }`
    }
  };

  const {register, control, watch, reset, getValues, setValue, handleSubmit} =
    useForm<reductionType>({
      defaultValues: defaultValue,
      mode: 'onSubmit'
    });

  const {fields, append} = useFieldArray({
    control,
    name: 'initiators'
  });

  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  useEffect(() => {
    const fetchApi = async () => {
      await axios
        .get(
          `${BACKEND_ENDPOINT}/footprinting/initiators?industry=${natureOfbusiness}`,
          config
        )
        .then((res) => {
          const {data} = res.data;
          setInitiator(() => {
            return data.map((item: any) => {
              return {
                _id: item._id,
                name: item.name,
                initiatorValue: '',
                unit: item.unit || 'kg'
              };
            });
          });
          setIsUpdate(!isUpdate);
        })
        .catch((err) => {
          console.log('err:', err);
        });
    };
    fetchApi();
  }, []);

  useEffect(() => {
    if (locationId && scopeStart) {
      const fetchData = async () => {
        await axios
          .get(
            `${BACKEND_ENDPOINT}/footprinting/reductions?type=locationYear&locationId=${locationId}&fromYear=${scopeStart}&toYear=${scopeEnd}`,
            config
          )
          .then((res) => {
            setIsLoading(true);
            if (res.data.data) {
              const {initiators, creditPurchased, _id, locationId, companyId} =
                res.data.data;
              reset({
                locationId: locationId,
                companyId: companyId,
                creditPurchased: creditPurchased,
                initiators:
                  initiators.length === 0
                    ? defaultValue.initiators
                    : initiators.map((item: any, index: number) => {
                        const newObj = initiator?.find((obj: any) => {
                          return obj._id === item.initiatorId;
                        });
                        return {
                          initiatorId: item.initiatorId || '',
                          initiatorValue: item.initiatorValue || '',
                          startDate: item.startDate || '',
                          unit: newObj?.unit || ''
                        };
                      }),
                fromYear: scopeStart,
                toYear: scopeEnd,
                __id: _id
              });
            } else {
              reset(defaultValue);
            }
          })
          .catch((err) => {
            console.log('err:', err);
          })
          .then(() => {
            setIsLoading(false);
          });
      };

      fetchData();
    } else {
      reset(defaultValue);
    }
  }, [locationId, scopeStart, isUpdate]);

  const handleSubmitCloseModal = () => {
    setSubmitted(false);
  };

  const handleSubmitReduction = async (
    submitData: reductionType,
    event: any
  ) => {
    event.preventDefault();

    if (!isDirty) {
      setIsLoading(true);
      setIsUpdate(!isUpdate);
      return setSubmitted(true);
    }

    setValue(
      'initiators',
      getValues('initiators').filter((item) => {
        return item.initiatorValue;
      })
    );

    const fetchApi = async () => {
      await axios
        .post(`${BACKEND_ENDPOINT}/footprinting/reductions`, watch(), config)
        .then((res) => {
          setIsLoading(true);
          if (res.data.data.initiators.length === 0) {
            setValue('initiators', defaultValue.initiators);
          }
          setIsDirty(false);
          setSubmitted(true);
        })
        .then(() => {
          if (isSubmit) {
            setOpenSnackbar((prev: any) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: 'Reductions fields added successfully!'
              };
            });
          } else {
            setOpenSnackbar((prev: any) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: 'Reductions fields Auto Saved successfully!'
              };
            });
          }
        })
        .catch((err) => {})
        .finally(() => {
          setIsUpdate(!isUpdate);
          setIsLoading(false);
        });
    };
    fetchApi();
  };

  return !(initiator.length > 0) && !isLoading ? (
    <LinearLoader />
  ) : (
    <form onSubmit={handleSubmit(handleSubmitReduction)}>
      <Box sx={{display: 'flex', width: '100%'}}>
        <Box className='qr-section' sx={{width: '1500px'}}>
          <Box className='formData-view formSection'>
            <Box className='data-title'>Reduction</Box>
            <br></br>
            <Box
              sx={{display: 'flex', flexDirection: 'column', padding: '20px'}}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '300px',
                  margin: '0px 0px 10px 0px'
                }}
              >
                <Box
                  sx={{
                    color: '#99B1C7 !important',
                    font: 'normal normal 600 16px/23px Quicksand'
                  }}
                >
                  Choose your initiator
                </Box>
                <Box>
                  <Tooltip message={tooltipMsg.reductions.reduction} />
                </Box>
              </Box>
              {fields?.map((select: any, index: number) => (
                <Box
                  key={index}
                  sx={{width: '500px', display: 'flex', marginBottom: '10px'}}
                >
                  <Controller
                    name={`initiators.${index}.initiatorId`}
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>
                          Initiator
                        </InputLabel>
                        <Select
                          defaultValue=''
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          disabled={!locationId || !scopeStart}
                          value={value || ''}
                          label='Initiator'
                          {...register(`initiators.${index}.initiatorId`, {
                            onChange: (event: any) => {
                              setValue(
                                `initiators.${index}.unit`,
                                initiator.find((obj: any) => {
                                  return obj._id === event.target.value;
                                }).unit
                              );
                            }
                          })}
                          sx={{marginRight: '10px'}}
                        >
                          {initiator?.map((item: any, index: number) => {
                            return (
                              <MenuItem
                                key={index}
                                value={item._id}
                                disabled={
                                  !!watch('initiators').find(
                                    (selectItem: any) =>
                                      selectItem.initiatorId === item._id
                                  )
                                }
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name={`initiators.${index}.initiatorValue`}
                    control={control}
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <TextField
                        type='number'
                        disabled={
                          !Boolean(watch(`initiators.${index}.initiatorId`))
                        }
                        label={
                          watch(`initiators.${index}.unit`)
                            ? `Qty in ${getValues(`initiators.${index}.unit`)}`
                            : 'Quantity'
                        }
                        {...register(`initiators.${index}.initiatorValue`, {
                          validate: {
                            reg: (value: any) => {
                              if (value === '' || isNaN(value)) return;
                              return (
                                RegExp('^[0-9]+$').test(value) ||
                                'Decimal and negative numbers are not allowed.'
                              );
                            }
                          },
                          valueAsNumber: true,
                          onChange: () => {
                            setIsDirty(true);
                          }
                        })}
                        variant='outlined'
                        sx={{marginRight: '10px'}}
                        value={value || ''}
                        onKeyDown={(event) => {
                          if (
                            event.code === 'ArrowDown' ||
                            event.code === 'ArrowUp' ||
                            event.code === 'Enter'
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onFocus={(e) => {
                          e.target.addEventListener(
                            'wheel',
                            function (e) {
                              e.preventDefault();
                            },
                            {passive: false}
                          );
                        }}
                        error={!!error}
                        helperText={!!error ? error?.message : ''}
                      />
                    )}
                  />
                  <Controller
                    name={`initiators.${index}.startDate`}
                    control={control}
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          open={openDatePicker[index] || false}
                          onClose={() =>
                            setOpenDatePicker((prev: boolean[]) => {
                              const update = [...prev];
                              update[index] = false;
                              return update;
                            })
                          }
                          disabled={
                            !Boolean(watch(`initiators.${index}.initiatorId`))
                          }
                          {...register(`initiators.${index}.startDate`, {
                            validate: {
                              ifValue: (value) => {
                                return (
                                  !!(
                                    getValues(
                                      `initiators.${index}.initiatorValue`
                                    ) && value
                                  ) ||
                                  !getValues(
                                    `initiators.${index}.initiatorValue`
                                  ) ||
                                  'Start Date is required'
                                );
                              }
                            },
                            onChange: () => {
                              setIsDirty(true);
                            }
                          })}
                          label='Start Date *'
                          inputFormat='MMM YYYY'
                          views={['year', 'month']}
                          openTo='year'
                          value={value || null}
                          disableMaskedInput
                          minDate={moment(
                            `1-Apr-${scopeStart}`,
                            'DD-MMM-YYYY'
                          ).valueOf()}
                          maxDate={moment(
                            `31-Mar-${scopeEnd}`,
                            'DD-MMM-YYYY'
                          ).valueOf()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!error}
                              sx={{width: '280px'}}
                              onClick={() =>
                                setOpenDatePicker((prev) => {
                                  const update = [...prev];
                                  update[index] = true;
                                  return update;
                                })
                              }
                              InputLabelProps={{
                                shrink: value ? true : false
                              }}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true
                              }}
                              helperText={!!error ? error?.message : null}
                            />
                          )}
                          onChange={onChange}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Box>
              ))}
              <Box>
                <Button
                  onClick={() => {
                    append({
                      initiatorId: '',
                      initiatorValue: '',
                      startDate: '',
                      unit: ''
                    });
                  }}
                  className='btn-primary'
                  disabled={!!(watch('initiators').length === initiator.length)}
                >
                  Add Initiator
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className='qr-section'>
            <Box
              className='formData-view formSection'
              sx={{width: '100% !important', display: 'flex'}}
            >
              <Box className='data-title'>Carbon Offsets</Box>
              <br></br>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '720px',
                  justifyContent: 'space-between'
                  //   margin: '55px 0px 0px 0px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Tooltip message={tooltipMsg.reductions.offset} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      margin: '0px 0px 0px 5px'
                    }}
                  >
                    <Typography
                      sx={{font: 'normal normal 600 15px/19px Quicksand'}}
                    >{`Carbon Credits Purchased in ${scopeStart}-${scopeEnd}`}</Typography>
                    <Typography
                      sx={{
                        font: 'normal normal 600 15px/19px Quicksand',
                        color: '#99B1C7'
                      }}
                    >{`Tco2`}</Typography>
                  </Box>
                </Box>
                <Box>
                  <Controller
                    name={'creditPurchased'}
                    control={control}
                    render={({field: {value}, fieldState: {error}}) => (
                      <TextField
                        type='number'
                        label={`Qty in Tco2`}
                        variant='outlined'
                        value={value || ''}
                        sx={{marginRight: '10px'}}
                        {...register('creditPurchased', {
                          valueAsNumber: true,
                          validate: {
                            reg: (value: any) => {
                              if (!value) return;
                              return (
                                RegExp('^[0-9]+$').test(value) ||
                                'Decimal and negative numbers are not allowed.'
                              );
                            }
                          },
                          onChange: () => {
                            setIsDirty(true);
                          }
                        })}
                        onKeyDown={(event) => {
                          if (
                            event.code === 'ArrowDown' ||
                            event.code === 'ArrowUp' ||
                            event.code === 'Enter'
                          ) {
                            event.preventDefault();
                          }
                        }}
                        onFocus={(e) => {
                          e.target.addEventListener(
                            'wheel',
                            function (e) {
                              e.preventDefault();
                            },
                            {passive: false}
                          );
                        }}
                        error={!!error}
                        helperText={error ? error?.message : ''}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className='df-right'>
        {/* <Button onClick={handleDraft} className="btn secondary-btn ">
        Save as Draft
      </Button> */}
        <Button
          type='submit'
          id='submit'
          sx={{cursor: 'none', pointerEvents: 'none'}}
        ></Button>
        <Button
          type='submit'
          className='btn secondary-btn'
          onClick={() => {
            setIsSubmit(true);
          }}
        >
          Finish
        </Button>
        {submitted && isSubmit && (
          <Modal
            open={submitted}
            setOpen={setSubmitted}
            children={
              <CancelConfirmModal
                setOpen={setSubmitted}
                textProps={{
                  mainText: 'Your data has been submitted successfully.',
                  confirmText: 'OK',
                  subText: 'Thank you for taking the effort to save the planet!'
                }}
                handleOk={handleSubmitCloseModal}
              />
            }
          />
        )}
      </Box>
    </form>
  );
};

export default Reductions;
