import {Box, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ToggleButtonComp from '../../utility/ToggleButton';
import Tooltip from 'Shared/Layouts/Tooltip';

type singleFieldProps = {
  category: string;
  fieldData: {sub: string; unit: string; desc: string};
  locationId: string;
  scopeStart: string;
  scopeData: any;
  setData: any;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

const ScopeSingleField = ({
  category,
  fieldData,
  locationId,
  scopeStart,
  scopeData,
  setData,
  setIsDirty
}: singleFieldProps) => {
  const [yearly, setYearly] = React.useState<boolean>(false);

  const months = [
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar'
  ];

  const toggleValues = [
    {name: 'Monthly', value: false},
    {name: 'Yearly', value: true}
  ];

  const filterType = (
    cat: string,
    type: string,
    itemIndex?: string | number
  ) => {
    return scopeData?.data?.filter((value: any, index: number) => {
      return (
        value?.category === cat &&
        value?.type === type &&
        value?.index === itemIndex
      );
    });
  };

  const initialObj: any = {};
  const initialYearlyObj: any = {};

  const [monthValue, setMonthValue] = useState();
  const [yearValue, setYearValue] = useState();

  useEffect(() => {
    months.map((month, id) => {
      return (initialObj[`${category}-${fieldData?.sub}-${String(id)}`] =
        filterType(category, fieldData?.sub, String(id))?.[0]?.value);
    });
    initialYearlyObj[`${category}-${fieldData?.sub}-yearly`] = filterType(
      category,
      fieldData?.sub,
      'yearly'
    )?.[0]?.value;

    setMonthValue(initialObj);
    setYearValue(initialYearlyObj);
  }, [scopeData]);

  const handleMonthChange = (e: any, key: any) => {
    setMonthValue((prev: any) => {
      return {
        ...prev,
        [key]:
          e.target.value?.replace(/\D/g, '') === ''
            ? ''
            : Math.abs(Math.round(e.target.value))
      };
    });
  };
  const handleYearChange = (e: any, key: any) => {
    setYearValue((prev: any) => {
      return {
        ...prev,
        [key]:
          e.target.value?.replace(/\D/g, '') === ''
            ? ''
            : Math.abs(Math.round(e.target.value))
      };
    });
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean
  ) => {
    if (newAlignment !== null) {
      setYearly(newAlignment);
    }
  };

  useEffect(() => {
    setYearly(
      filterType(category, fieldData?.sub, 'yearly')?.length > 0 ? true : false
    );
  }, [scopeData]);

  return (
    <Box className='form-listing'>
      <Box className='info-label'>
        <Box className='info-tool'>
          <Tooltip
            message={`${fieldData?.desc}${
              fieldData?.desc?.endsWith('per ')
                ? yearly
                  ? 'year'
                  : 'month'
                : ''
            }`}
          />
        </Box>
        <Box>
          {fieldData?.sub?.charAt(0)?.toUpperCase() + fieldData?.sub?.slice(1)}
          <Box className='info-quantity'>Qty. in {fieldData?.unit}</Box>
        </Box>
      </Box>
      <Box className='mny-toggle'>
        <ToggleButtonComp
          handleAlignment={handleAlignment}
          alignment={yearly}
          toggleValues={toggleValues}
          type='scope'
        />
      </Box>
      <Box className='form-yearly'>
        {yearly && (
          <TextField
            type='number'
            variant='outlined'
            disabled={locationId && scopeStart ? false : true}
            label={'Yearly'}
            inputProps={{
              id: 'yearly',
              category: category,
              typename: fieldData?.sub,
              metric: fieldData?.unit,
              sub: '',
              yearly: `${yearly}`
            }}
            onKeyDown={(event) => {
              if (
                event.code === 'ArrowDown' ||
                event.code === 'ArrowUp' ||
                event.code === 'Enter'
              ) {
                event.preventDefault();
              }
            }}
            onFocus={(e) => {
              e.target.addEventListener(
                'wheel',
                function (e) {
                  e.preventDefault();
                },
                {passive: false}
              );
            }}
            value={yearValue?.[`${category}-${fieldData?.sub}-yearly`] || ''}
            InputLabelProps={
              !!filterType(category, fieldData?.sub, 'yearly')?.[0]?.value
                ? {shrink: true}
                : {}
            }
            onChange={(e) => {
              handleYearChange(e, [`${category}-${fieldData?.sub}-yearly`]);
              setIsDirty(true);
            }}
          />
        )}

        {!yearly &&
          months.map((month, id) => {
            initialObj[`${category}-${fieldData?.sub}-${String(id)}`] =
              filterType(category, fieldData?.sub, String(id))?.[0]?.value;

            return (
              <TextField
                key={id}
                type='number'
                variant='outlined'
                disabled={locationId && scopeStart ? false : true}
                label={month}
                inputProps={{
                  id: `${id}`,
                  category: category,
                  typename: fieldData?.sub,
                  metric: fieldData?.unit,
                  sub: '',
                  yearly: `${yearly}`
                }}
                onKeyDown={(event) => {
                  if (
                    event.code === 'ArrowDown' ||
                    event.code === 'ArrowUp' ||
                    event.code === 'Enter'
                  ) {
                    event.preventDefault();
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    'wheel',
                    function (e) {
                      e.preventDefault();
                    },
                    {passive: false}
                  )
                }
                InputLabelProps={
                  !!filterType(category, fieldData?.sub, String(id))?.[0]?.value
                    ? {shrink: true}
                    : {}
                }
                value={
                  monthValue?.[`${category}-${fieldData?.sub}-${String(id)}`] ||
                  ''
                }
                onChange={(e) => {
                  handleMonthChange(e, [
                    `${category}-${fieldData?.sub}-${String(id)}`
                  ]);
                  setIsDirty(true);
                }}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default ScopeSingleField;
