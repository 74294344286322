import * as React from 'react';
import {Tabs, Tab} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GeneralInfo from './GeneralInfo';
import FootPrintingView from './FootPrintingView';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      className='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

type viewProps = {
  customer: any;
};

export default function ViewTab({customer}: viewProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{width: '100%'}}>
      <Box>
        <Tabs
          className='tabui-roundbg'
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='General Info' {...a11yProps(0)} />
          <Tab label='Foot Print' {...a11yProps(1)} />
          {/* <Tab label='Analytics' {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <GeneralInfo customer={customer} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FootPrintingView customer={customer} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <Analytics />
      </TabPanel> */}
    </Box>
  );
}
