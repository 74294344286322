import {Box, Button, FormHelperText} from '@mui/material';
import React, {useState} from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LinearWithValueLabel from './LinearProgress';
import Aws from '../../../../Libraries/Aws';
import ViewDoc from './ViewDoc';
import Modal from './Modal';

interface UploadProps {
  filename: string;
  handleFile: (event: any) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fileView?: any;
}

const UploadDoc = ({filename, handleFile, setOpen, fileView}: UploadProps) => {
  const [fileUpload, setFileUpload] = useState(false);
  const [progressState, setProgressState] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openView, setOpenView] = useState(false);

  const handleImageChange = (e: any) => {
    const files = e.target.files;

    const file = files[0];

    setFileUpload(true);
    setFile(file);

    const fileSize = file.size / (1024 * 1024);
    const maxSize = 5;

    if (fileSize > maxSize) {
      setError(true);
      setErrorMessage('File Size should be less than or equal to 5mb');
      return;
    } else {
      setError(false);
      Aws.uploadImageToS3(file)
        .on('httpUploadProgress', (progress) => {
          setProgressState(
            Math.round((progress.loaded / progress.total) * 100)
          );
        })
        .send((err) => {});
    }
  };

  const handleSubmit = () => {
    if (error) {
      setOpen(false);
      return;
    } else {
      const url = `https://${process.env.REACT_APP_AMAZON_BUCKET}.s3.${process.env.REACT_APP_AMAZON_REGION}.amazonaws.com/${file.name}`;
      handleFile({url, file});
      return setOpen(false);
    }
  };

  return (
    <Box>
      <h4>{filename}</h4>
      <Box className='qr-section'>
        <Box className='formData-view'>
          <Box className=''>
            <Button component='label' className='drag-upload'>
              <input
                type='file'
                hidden
                accept='image/png, image/jpeg'
                onChange={handleImageChange}
              />
              <Box className='upload-icon'>
                <FileUploadIcon />
              </Box>
              <Box>Drag files to upload</Box>
            </Button>
          </Box>
        </Box>
        <Box className='formData-view'>
          <Box>
            <Button component='label' className='drag-upload'>
              <input
                type='file'
                hidden
                accept='image/png, image/jpeg'
                capture='environment'
              />
              <Box className='upload-icon'>
                <CameraAltIcon />
              </Box>
              <Box> Take a Picture</Box>
            </Button>
          </Box>
        </Box>
      </Box>
      {fileUpload && (
        <Box>
          <LinearWithValueLabel value={progressState} file={file} />
          <FormHelperText error={error}>
            {error ? errorMessage : null}
          </FormHelperText>
        </Box>
      )}
      <Box>
        <Button onClick={() => setOpenView(true)}>Last Upload</Button>
      </Box>
      {openView && (
        <Modal
          children={<ViewDoc file={fileView} fileType={filename} />}
          open={openView}
          setOpen={setOpenView}
        />
      )}

      <Box
        sx={{
          textAlign: 'center',
          marginTop: '40px'
        }}
      >
        <Button className='btn-primary' onClick={handleSubmit}>
          Upload
        </Button>
      </Box>
    </Box>
  );
};

export default UploadDoc;
