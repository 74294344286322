import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface Props {
  open: boolean;
  max?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BasicModal({
  open,
  max,
  setOpen,
  children,
}: React.PropsWithChildren<Props>) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className={`modal-popup ${max ? `modalpopupIMP` : null}`}
        >
          <>
            <Button className="modal-close" onClick={handleClose}>
              <CloseIcon />
            </Button>
            {children}
          </>
        </Box>
      </Modal>
    </div>
  );
}
