import QRCode from "qrcode.react";

interface RawMaterialQRCodeComponentProps {
  url: string;
}

const RawMaterialQRCode = ({ url }: RawMaterialQRCodeComponentProps) => {
  return <QRCode value={url} />;
};
export default RawMaterialQRCode;
