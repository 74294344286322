import {Box} from '@mui/material';
import React from 'react';

type ScopeSingleFieldViewProps = {
  title: string;
  subData: {sub: string; unit: string};
  scopeData: any;
};

const ScopeSingleFieldView = ({
  scopeData,
  subData,
  title
}: ScopeSingleFieldViewProps) => {
  const filterType = (
    cat: string,
    type: string,
    itemIndex?: string | number
  ) => {
    return scopeData?.data
      ?.filter((value: any, index: number) => {
        return value?.category === cat && value?.type === type;
      })
      .filter((value: any) => {
        return itemIndex ? value?.index === itemIndex : true;
      });
  };

  function AverageYearlyValue(arr: any, category: string, type: string) {
    let value = Number(arr?.[0]?.value);
    let index = arr?.[0]?.index;
    if (arr?.length === 0) {
      return false;
    } else {
      arr = Array.from({length: 12}, (_, i) => ({
        index: index,
        value: Math.round(value / 12),
        category,
        type
      }));
      return arr;
    }
  }

  AverageYearlyValue(
    filterType(title, subData?.sub, 'yearly'),
    title,
    subData?.sub
  );

  function addMissingData(arr: any, category: string, type: string) {
    if (arr?.length === 0) {
      arr = Array.from({length: 12}, (_, i) => ({
        index: i,
        value: '-',
        category,
        type
      }));
    } else if (!arr?.some((obj: any) => obj?.index === 'yearly')) {
      const newArray = Array.from({length: 12}, (_, i) => ({
        index: String(i),
        value: '-',
        category,
        type
      }));
      arr?.forEach(({index, value}: any) => {
        newArray[index] = {index, value, category, type};
      });
      arr = newArray;
    }
    return arr;
  }

  return (
    <Box className='cs-section'>
      <Box>{subData?.sub.charAt(0).toUpperCase() + subData?.sub.slice(1)}</Box>

      <Box>{subData?.unit}</Box>

      {AverageYearlyValue(
        filterType(title, subData?.sub, 'yearly'),
        title,
        subData?.sub
      )?.length > 0
        ? AverageYearlyValue(
            filterType(title, subData?.sub, 'yearly'),
            title,
            subData?.sub
          )?.map((value: any, id: number) => {
            return (
              <Box key={id}>
                {isNaN(Number(value.value))
                  ? '-'
                  : Number(value?.value).toLocaleString('en-IN')}
              </Box>
            );
          })
        : addMissingData(
            filterType(title, subData?.sub),
            title,
            subData?.sub
          )?.map((value: any, index: number) => {
            return (
              <Box key={index}>
                {isNaN(Number(value.value))
                  ? value?.value
                  : Number(value?.value).toLocaleString('en-IN')}
              </Box>
            );
          })}
    </Box>
  );
};

export default ScopeSingleFieldView;
