import './styles/style.scss';
import React, {useEffect, useState} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import NavbarCon from './Components/Consultants/ui/components/NavbarCon';
import DashCon from './Components/Consultants/ui/components/DashCon';
import Snackbars from './Components/Consultants/ui/utility/SnackBar';
import ConsumerPage from './Components/Consultants/ui/table/ConsumerPage';
import {useAuth0} from '@auth0/auth0-react';
import {Box} from '@mui/material';
import ViewComponent from './Components/Consultants/ui/components/Customer View/ViewComponent';
import AddCustomer from './Components/Consultants/ui/components/Customer View/AddCustomer';
import Setting from './Components/Consultants/ui/components/Setting';
import FootPrintingPage from './Components/Consultants/ui/components/Customer View/FootPrintingPage';
import PayNow from './Components/Consultants/ui/utility/PayNow';
import {AlertColor} from '@mui/material/Alert';
import {NoRouteMatch} from 'Components/Consultants/ui/utility/NoRouteMatch';
import Loader from 'Components/Consultants/ui/utility/Loading';
import TextfieldCustomer from 'Components/Consultants/ui/components/Customer View/TextfieldCustomer';

function App() {
  const [openSnackBar, setOpenSnackbar] = useState<{
    open: boolean;
    status: AlertColor;
    message: string;
  }>({
    open: false,
    status: 'success',
    message: ''
  });

  const navigate = useNavigate();

  const {
    isLoading,
    error,
    isAuthenticated,
    getIdTokenClaims,
    logout,
    user,
    loginWithRedirect
  } = useAuth0();

  React.useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getIdTokenClaims();
        const token = accessToken?.__raw;

        if (token) {
          localStorage.setItem('token', token);
        }

        return token;
      } catch (e: any) {}
    };
    getUserMetadata();
  });

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        if (user?.['bc/roles'][0] === 'partner') {
          if (!localStorage.getItem('isLogged')) {
            localStorage.setItem('isLogged', 'true');
            return setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: 'You have logged in successfully!'
              };
            });
          }
        } else {
          setOpenSnackbar((prev) => {
            return {
              ...prev,
              open: true,
              status: 'warning',
              message:
                'You are not an authorized person, Please Contact Admin for more details'
            };
          });
          setTimeout(() => {
            logout({
              returnTo: window.location.href
            });
          }, 4000);
        }
      } else {
        loginWithRedirect();
      }
    }
  }, [isAuthenticated, user, loginWithRedirect, isLoading]);

  if (error) {
    setTimeout(() => {
      navigate('/');
      if (!localStorage.getItem('token')) {
        loginWithRedirect();
        setOpenSnackbar((prev) => {
          return {
            ...prev,
            open: true,
            status: 'warning',
            message: 'Oops Something went wrong'
          };
        });
      }
    }, 5000);

    return (
      <Box>
        <Box>Oops... {error.message}</Box>
        <Box>Please Login Again</Box>
      </Box>
    );
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Box className='background'>
        {isAuthenticated &&
          !isLoading &&
          user?.['bc/roles'][0] === 'partner' && <NavbarCon />}
        {openSnackBar?.open && (
          <Snackbars open={openSnackBar} setOpen={setOpenSnackbar} />
        )}
        <Routes>
          {isAuthenticated &&
            !isLoading &&
            user?.['bc/roles'][0] === 'partner' && (
              <>
                <Route path='/' element={<DashCon />} />
                <Route path='/customer/list' element={<ConsumerPage />} />
                <Route path='/customer/:id' element={<ViewComponent />} />
                {/* <Route
                  path='/customer/add'
                  element={<AddCustomer setOpenSnackbar={setOpenSnackbar} />}
                /> */}
                <Route
                  path='/customer/add'
                  element={
                    <TextfieldCustomer setOpenSnackbar={setOpenSnackbar} />
                  }
                />
                {/* <Route
                  path='/customer/edit/:id'
                  element={<AddCustomer setOpenSnackbar={setOpenSnackbar} />}
                /> */}
                <Route
                  path='/customer/edit/:id'
                  element={
                    <TextfieldCustomer setOpenSnackbar={setOpenSnackbar} />
                  }
                />
                <Route path='/setting' element={<Setting />} />
                <Route path='/customer/:id/footprint'>
                  <Route
                    path=''
                    element={
                      <FootPrintingPage setOpenSnackbar={setOpenSnackbar} />
                    }
                  ></Route>
                  <Route
                    path=':index/:year/:locationId'
                    element={
                      <FootPrintingPage setOpenSnackbar={setOpenSnackbar} />
                    }
                  ></Route>
                </Route>
                <Route
                  path='/customer/:id/payment'
                  element={<PayNow setOpenSnackbar={setOpenSnackbar} />}
                />
                <Route path='*' element={<NoRouteMatch />} />
              </>
            )}
        </Routes>
      </Box>
    </>
  );
}

export default App;
