import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {Box, Button, InputAdornment, Paper, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import {useSearchParams} from 'react-router-dom';

import MainTab from './MainTab';
import debounce from 'Shared/Utils/debounce';

const ConsumerPage: React.FC = () => {
  const [, setSearchParam] = useSearchParams();
  const [search, setSearch] = useState('');

  const [searchClone, setSearchClone] = useState('');

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const debouce = debounce(() => {
      setSearch(searchClone);
    });

    return () => {
      clearTimeout(debouce);
    };
  }, [searchClone]);

  const buttonStyle = {
    animation: refresh ? 'spin 2s 1' : '',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(-360deg)'
      },
      '100%': {
        transform: 'rotate(-0deg)'
      }
    }
  };

  return (
    <Box className='pageBox'>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '1rem',
          mt: '1rem'
        }}
      >
        <Box
          className='page-header'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <h1 className='pageTitle'>Customers</h1>
          <Box
            className='search-bar'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={() => {
                setRefresh(true);
                setTimeout(() => {
                  setRefresh(false);
                }, 2000);
              }}
            >
              <RefreshOutlinedIcon sx={buttonStyle} />
            </Button>
            <TextField
              fullWidth
              className='search-input'
              placeholder='Search'
              type='search'
              value={searchClone}
              onChange={(e) => {
                setSearchParam({term: e.target.value});
                setSearchClone(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <Button
              component={Link}
              className='search-btn'
              to='/customer/add'
              variant='contained'
              sx={{ml: '1rem'}}
            >
              + Customer
            </Button>
          </Box>
        </Box>
        <MainTab search={search} refresh={refresh} />
      </Paper>
    </Box>
  );
};

export default ConsumerPage;
