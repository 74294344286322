import QRCode from "qrcode.react";

interface EmployeeQRCodeComponentProps {
  url: string;
}

const EmployeeQRCode = ({ url }: EmployeeQRCodeComponentProps) => {
  return <QRCode value={url} />;
};

export default EmployeeQRCode;
