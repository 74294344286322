import {Box, Button, FormControl, TextField, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {AlertColor} from '@mui/material/Alert';
import {Link, useParams} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import LocationPayment from './LocationPayment';
import Modal from './Modal';
import CancelConfirmModal from './CancelConfirmModal';
import Loader from './Loading';

type PayNowProps = {
  setOpenSnackbar: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      status: AlertColor;
      message: string;
    }>
  >;
};

const PayNow = ({setOpenSnackbar}: PayNowProps) => {
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;
  const [openDemo, setDemo] = useState<boolean>(false);
  const [onceApplied, setOnceApplied] = useState(false);

  const [data, setData] = useState<any>('');

  const [couponCode, setCouponCode] = useState<number>(0);

  const [discountApplied, setDiscountApplied] = useState<boolean>(false);

  const customerPortal = process.env.REACT_APP_CUSTOMER;

  const {id} = useParams();

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };
    const fetchApi = async () => {
      await axios
        .get(`${BACKEND_ENDPOINT}/checkout?companyId=${id}`, config)
        .then((res) => {
          return setData(res?.data?.data);
        })
        .catch((err) => {
          return console.log('err', err);
        });
    };
    fetchApi();
  }, []);

  const period =
    moment(data?.footprintingPeriod?.[1]).year() -
    moment(data?.footprintingPeriod?.[0]).year();

  const url: any = data
    ? `${customerPortal}/payment?companyId=${data._id}&invoiceId=${data.invoiceId}`
    : '';

  const onSubmit = async () => {
    setOpenSnackbar({
      open: true,
      status: 'success',
      message:
        'Payment request has been sent to the client! Confirm with the admin before proceeding!'
    });

    setTimeout(() => {
      setDemo(true);
    }, 2000);

    // navigate('/customer/list');
  };

  const applyDiscount = () => {
    if (!couponCode) {
      return;
    }
    const req = {
      invoiceId: data.invoiceId,
      discount: couponCode
    };
    setDiscountApplied(true);

    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      await axios
        .put(`${BACKEND_ENDPOINT}/discount`, req, config)
        .then((res) => {
          const resData = res.data.data;
          setOnceApplied(true);
          return setData((prev: any) => {
            return {
              ...prev,
              _id: resData.company,
              invoiceId: resData._id,
              discountPercentage: resData.discountPercentage,
              discountedAmount: resData.discountedAmount,
              price: resData.price,
              totalPrice: resData.totalPrice
            };
          });
        })
        .catch((err) => {
          return console.log('err', err);
        });
    };
    fetchApi();
  };

  const applyReset = () => {
    if (!onceApplied) {
      setOnceApplied(false);
      return setCouponCode(0);
    }
    const req = {
      invoiceId: data.invoiceId,
      discount: 0
    };
    setDiscountApplied(true);

    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      await axios
        .put(`${BACKEND_ENDPOINT}/discount`, req, config)
        .then((res) => {
          const resData = res.data.data;
          setOnceApplied(false);
          return setData((prev: any) => {
            return {
              ...prev,
              _id: resData.company,
              invoiceId: resData._id,
              discountPercentage: resData.discountPercentage,
              discountedAmount: resData.discountedAmount,
              price: resData.price,
              totalPrice: resData.totalPrice
            };
          });
        })
        .catch((err) => {
          return console.log('err', err);
        });
    };
    fetchApi();
  };

  const handleClose = () => {
    setDemo(false);
  };

  return !data ? (
    <Loader />
  ) : (
    <Box className='innerPage'>
      <Box className='back-btn'>
        <Button>
          <Link to={'/customer/list'}>
            <KeyboardArrowLeftIcon /> Back
          </Link>
        </Button>
      </Box>
      <Box className='billing-page'>
        {/* left section */}
        <Box className='left-section'>
          <h1 className='pageTitle'>Payment Details</h1>
          {/* <Box className='subTitle'>
            <h4>Basic Info</h4>
            <Box className='subHead'>Company Info</Box>
          </Box> */}
          <Box className='subTitle'>
            <h4>Company Info</h4>
            {/* <Box className='subHead'>Company Info</Box> */}
          </Box>
          <Box className='formData-view'>
            <Box className='form-view'>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Business Name:{' '}
                  <span>{`${data?.businessName?.replace(
                    /\b\w/g,
                    (match: string) => match.toUpperCase()
                  )}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Category:
                  <span>{`${data?.category?.replace(/\b\w/g, (match: string) =>
                    match.toUpperCase()
                  )}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Nature of Business:
                  <span>{`${data?.natureOfBusiness?.replace(
                    /\b\w/g,
                    (match: string) => match.toUpperCase()
                  )}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Type of Business:
                  <span>{`${data?.typeOfBusiness?.replace(
                    /\b\w/g,
                    (match: string) => match.toUpperCase()
                  )}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Footprinting Period:
                  <span>{`${period} ${period === 1 ? 'Year' : 'Years'}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Outlet Count{`${data?.locations?.length > 1 ? 's' : ''}`}:
                  <span>{`${data?.numberOfLocations}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Footprinting Year{`${period === 1 ? '' : 's'}`}:
                  <span>{`${moment(data?.footprintingPeriod?.[0]).year()} -
    ${moment(data?.footprintingPeriod?.[1]).year()}`}</span>
                </Typography>
              </Box>
              <Box className='form-data'>
                <Typography className='label-title'>
                  Total area of all Outlets:
                  <span>{`${data?.totalAreaForAllLocations.toLocaleString(
                    'en-IN'
                  )} Sq ft`}</span>
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <Box className='subTitle'>
            <h4>Locations Info</h4>
            <Box className='subHead'>Information based on location</Box>
          </Box> */}
          <Box className='subTitle'>
            <h4>{`Location${data?.locations?.length > 1 ? 's' : ''} Info`}</h4>
            {/* <Box className='subHead'>Information based on location</Box> */}
          </Box>
          <LocationPayment data={data?.locations} />
          <Box className='payGroup'>
            <Box className='payTotal'>
              <span>Total : </span>
              {`₹ ${
                Math.round(data?.price / period).toLocaleString('en-IN') || 0
              }`}
            </Box>

            <Box className='payTotal'>
              <span>
                Period x {period || 0} {period === 1 ? 'Yr' : 'Yrs'} :{' '}
              </span>
              {`₹ ${data?.price.toLocaleString('en-IN') || 0}`}
            </Box>
            <Box className='payTotal'>
              <span>Grand Total : </span>
              {`₹ ${data?.price.toLocaleString('en-IN') || 0}`}
            </Box>
          </Box>

          <Box className='note-data'>
            <Typography>
              Note: Please check the estimate and all its contents before
              sharing it with the customer.
            </Typography>
            {`Disclaimer: The pricing model includes variables that help us
            differentiate between small, medium & large customers. The price
            includes a base price upto 1000 Sq ft (Base: Rs ${data?.locations[0].basePrice.toLocaleString(
              'en-IN'
            )}) as well as an
            additional fee per additional square feet (Rs 5/ per Sq ft)`}
          </Box>
        </Box>
        {/* right section */}
        <Box className='right-section'>
          <Box className='payment-section'>
            <Box className='sub-title'>Bill Details</Box>

            {/* <Box>Discount</Box>
            <Box className='button-group location-s'>
              <ToggleButtonComp
                handleAlignment={handleDiscount}
                alignment={discountValue}
                toggleValues={discountValues}
              />
            </Box> */}
            <Box className='coupon'>
              <FormControl>
                <TextField
                  type='number'
                  placeholder='Custom Discount %'
                  value={couponCode === 0 ? '' : couponCode}
                  onChange={(e) => {
                    setCouponCode(Number(e.target.value));
                  }}
                  disabled={discountApplied}
                  inputProps={{max: 100, min: 0}}
                  error={couponCode > 100}
                  helperText={
                    couponCode > 100 ? 'Discount Cannot be more than 100%' : ''
                  }
                />
              </FormControl>
              <Box className='coupon-button'>
                <Button
                  disabled={
                    discountApplied
                      ? true
                      : couponCode
                      ? couponCode > 100
                        ? true
                        : false
                      : true
                  }
                  className={
                    couponCode ? (couponCode > 100 ? '' : 'btn-secondary') : ''
                  }
                  onClick={applyDiscount}
                >
                  Apply
                </Button>
                <Button
                  disabled={!!!couponCode}
                  onClick={() => {
                    applyReset();
                    setCouponCode(0);
                    setDiscountApplied(false);
                  }}
                  className={couponCode ? 'reset' : ''}
                >
                  Reset
                </Button>
              </Box>
            </Box>
            <Box className='amount-calc'>
              <Box>
                {`Price:`}
                <span>{`₹ ${data?.price.toLocaleString('en-IN')} /-`}</span>
              </Box>
              <Box>
                {`Discount:`}
                <span className='discount'>{`${
                  data.discountPercentage || 0
                } % | ₹ ${Math?.round(
                  data.discountedAmount || 0
                ).toLocaleString('en-IN')} /-`}</span>
              </Box>
              <Box>
                {`GST:`}
                <span>
                  {`${data?.gst} % | ₹ ${(data?.gstAmount).toLocaleString(
                    'en-IN'
                  )} /-`}{' '}
                </span>
              </Box>

              <Box className='total-amt'>
                {`Total Price:`}
                <span>{`₹ ${data.totalPrice
                  .toFixed(1)
                  .toLocaleString('en-IN')} /-`}</span>
              </Box>
            </Box>

            <Box>
              <Button
                className='btn-primary'
                onClick={() => {
                  onSubmit();
                }}
              >
                Invite to pay
              </Button>
            </Box>
            <Modal
              open={openDemo}
              setOpen={setDemo}
              children={
                <CancelConfirmModal
                  handleOk={async () => {
                    window.open(url, '_blank');
                    setDemo(false);
                  }}
                  handleCancel={handleClose}
                  textProps={{
                    mainText:
                      '---------------- This is for demo purpose only ----------------    Shall we redirect to Estimates?',
                    cancelText: 'No',
                    confirmText: 'Yes'
                  }}
                />
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PayNow;
