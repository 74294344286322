const PartnerConstant = {
  autoSaveInterval: 120000,
  APP_STATUS: {
    CALL_SCHEDULED: 'call scheduled',
    PAYMENT_PENDING: 'invite and pay',
    DOC_PENDING: 'documents pending',
    VERIFICATION_PENDING: 'verification pending',
    FOOTPRINTING_PENDING: 'footprinting pending',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled'
  },
  debounce_Interval: 500
};

export default PartnerConstant;
