const tooltipMsg = {
  dashboard: {
    badge:
      'Milestones achieved by a partner. We award the respective badges to partners based on merit & progress.',
    sales:
      'Graph represents total sales achieved by a partner over a selected period of time.',
    carbonOffsetAchieved:
      'Section represents total carbon offsets sold by a partner over a selected period of time with a breakdown of carbon offsets sold to each customer.',
    totalCustomers:
      'This represents the total number of customers registered on our platform.',
    totalPartners:
      'This represents the total number of partners registered on our platform.',
    leaderBoard:
      'This represents the partner rankings based on their level, carbon offsets sold & new customers onboarded.'
  },
  customerListing: {
    paymentPending: 'Follow up with customer and clear payment within 7 days.',
    documentPending:
      'Get in touch with the customer, request & upload documents within 5 days.',
    verificationPending:
      'Verification in progress. You will be notified once complete.',
    footprintPending:
      'Set up a meeting with the customer and upload all footprinting data within 5 days.',
    cancelled: 'Customer lead has been cancelled.'
  },
  scope1: {
    kitchen: {
      coal: 'Weight of coal used per ',
      LPG: 'Weight of LPG used per '
    },
    companyOwnedVehicle: {
      diesel: 'Diesel consumed per ',
      petrol: 'Petrol consumed per ',
      LPG: 'LPG consumed per ',
      CNG: 'CNG consumed per '
    },
    dieselGenerator: {
      diesel: 'Diesel consumed per '
    },
    refrigerant: {
      HCFC22: 'Chlorodifluoromethane',
      CFC11R11: 'Trichlorofluoromethane',
      CFC12R12: 'Dichlorodifluoromethane',
      CFC13: 'Chlorotrifluoromethane',
      CFC113: '1,1,2-Trichloroethane',
      CFC114: '1,2-Dichlorotetrafluoroethane',
      CFC115: 'Chloropentafluoroethane',
      Halon1211: 'Bromochlorodifluoromethane',
      Halon1301: 'Bromotrifluoromethane',
      Halon2402: 'Dibromotetrafluoroethane',
      CCl4: 'Carbon tetrachloride',
      CH3Br: 'Methyl Bromide',
      CH3CCl3: '1,1,1-Trichloroethane, Methyl chloroform',
      HCFC123: '1,2-Dichloro-1,1,2-trifluoroethane',
      HCFC141b: '1,1-Dichloro-1-fluoroethane',
      HCFC142b: '1-Chloro-1,1-difluoroethane',
      HCFC225ca: '1,1-Dichloro-2,2,3,3,3-pentafluoropropane',
      HCFC225cb: '1,3-Dichloro-1,2,2,3,3-pentafluoropropane',
      HCFC21: 'Dichlorofluoromethane',
      HFC23: 'Trifluoromethane',
      HFC32: 'Difluoromethane',
      HFC41: 'Fluoromethane',
      HFC125: 'Pentafluoroethane',
      HFC134: 'CHF2CHF2',
      HFC134a: '1,1,1,2-tetrafluoroethane',
      HFC143: 'CH2FCF3',
      HFC143a: '1,1,1-Trifluoroethane',
      HFC152: 'CH2FCH2F',
      HFC152a: '1,1-Difluoroethane',
      HFC161: 'CH3CH2F',
      HFC227ea: '1,1,1,2,3,3,3-Heptafluoropropane',
      HFC236cb: '1,1,1,2,3,3,3-heptafluoropropane',
      HFC236ea: '1,1,1,2,3,3-hexafluoropropane',
      HFC236fa: '1,1,1,3,3,3-Hexafluoropropane',
      HFC245ca: '1,1,2,2,3-pentafluoropropane',
      HFC245fa: 'Pentafluoropropane',
      HFC365mfc: '1,1,1,3,3-pentafluorobutane',
      HFC4310mee: '1,1,1,2,3,4,4,5,5,5-decafluoropentane',
      Sulfurhexafluoride: 'SF6',
      Nitrogentrifluoride: 'NF3',
      PFC14: 'Carbon tetrafluoromethane',
      PFC116: 'Hexafluoroethane',
      PFC218: 'Octafluoropropane',
      PFC318: 'Perfluorocyclobutane',
      PFC3110: 'Perfluorobutane',
      PFC4112: 'Perfluoropentane',
      PFC5114: 'Perfluorohexane',
      PCF9118: 'Perfluorodecalin',
      Trifluoromethylsulfurpentafluoride: 'CF3SF5',
      Perfluorocyclopropane: 'c-C3F6',
      HFE125: 'heptafluoropentan-2-one',
      HFE134: 'CHF2OCHF2',
      HFE143e: 'ethyl nonafluorobutyl ether',
      HCFE235da2: '2,2,2-Trifluoroethyl difluoromethyl ether',
      HFE245cb2: '1,1,1,2,3,3,3-heptafluoropropane',
      HFE245fa2: '2,2-difluoro-1-methoxy-1,1,3,3,3-pentafluoropropane',
      HFE347mcc3: '1,1,1,2,2-pentafluoropropane',
      HFE347pcf2: '1,1,1,2,3,3,3-heptafluoropropane',
      HFE356pcc3: '1,1,1,3,3-pentafluoropropane',
      HFE449sl_HFE7100: '1,1,1,2,2,3,4,5,5,5-decafluoropentane',
      HFE569sf2_HFE7200: '1,1,1,2,2,3,4,5,5,5-decafluoropentane',
      HFE4310pccc124_HGalden1040x:
        '1,1,1,2,2,3,3,4,4,5,5,6,6,6-tetradecafluoro-2-(trifluoromethyl) hexane',
      HFE236ca12_HG10:
        '1,1,2,2,3,3,4,4,5,5,6,6,7,7,8,8,8-heptadecafluorooctyl-1-(trifluoromethyl) nonylether',
      HFE338pcc13_HG01:
        '1,1,1,2,2,2-hexafluoro-2-(trifluoromethyl) ethyl difluoromethyl ether',
      HFE227ea: '1,1,1,2,3,3,3-heptafluoropropane',
      HFE236ea2: ',2,2-trifluoroethyl difluoromethyl ether',
      HFE236fa: '1,1,1,2,2,2-hexafluoropropane',
      HFE245fa1: '2,2,2-trifluoroethyl methyl ether',
      HFE263fb2: '2,2,2-trifluoroethyl methyl ether',
      HFE329mcc2: '1,1,1,2,2,3,3,4,4,4-decafluorobutyl trifluoromethyl ether',
      HFE338mcf2: '1,1,1,2,2,3,3,3-octafluoropropane',
      HFE347mcf2: '1,1,1,2,2,3,3,4,4,4-decafluorobutyl trifluoromethyl ether',
      HFE356mec3: '1,1,1,2,2,3,3,4,4-nonafluorobutyl methyl ether',
      HFE356pcf2: '1,1,1,2,2,3,3,4,4-nonafluorobutyl methyl ether',
      HFE356pcf3: '1,1,1,2,3,3-hexafluoro-2-(trifluoromethyl)propane',
      HFE365mcf3: '1,1,1,2,2,3,3,4,4-nonafluorobutyl methyl ether',
      HFE374pc2: '1,1,1,2,2,3,3,4,4-nonafluorobutyl methyl ether',
      PFPMIE: '1,1,1,2,2,3,3,4,4-nonafluorobutyl trifluoromethyl ether',
      Chloroform: 'Trichloromethane',
      Methylenechloride: 'Dichloromethane',
      Methylchloride: 'Chloromethane',
      Halon1201: 'Bromodifluoromethane',
      R32: 'Difluoromethane'
    },
    fireExtinguisher: {
      CO2: 'Carbon di oxide'
    }
  },
  scope2: {
    electricity: 'kWh per '
  },
  scope3: {
    empDetails:
      'Download the template and send to customer. The template requires employee full name & details, to track the number of employees registered in the organization. Once filled by the customer, please upload it and share a QR code to each employee registering their details for carbon accounting.',
    rawDetails:
      'Download the template and send to customer. The template requires vendor full name & details, to track the number of vendors registered in the organization. Once filled by the customer, please upload it and share a QR code to each vendors registering their details for carbon accounting.',
    freshWater: {
      ultra:
        'Water which has been purified to the highest standards by removing all contaminants such as organic and inorganic compounds; dissolved and particulate matter; volatile and non-volatile, reactive and inert; hydrophilic and hydrophobic; and dissolved gases.',
      decarb:
        'A process through which carbon dioxide (CO2) is removed from the water.',
      tap: 'Water that comes out of the faucets in a building that are connected to the main supply of the local water system.'
    },
    wasteWater:
      'Waste water is water generated after the use of freshwater, raw water, drinking water or saline water in a variety of deliberate applications or processes.',
    solidWaste:
      'Solid waste is the unwanted or useless solid materials generated from human activities in residential, industrial or commercial areas.',
    laundry: 'Average quantity of laundry processed per '
  },
  reductions: {
    reduction:
      'Input the initiatives taken by the customers to calculate the reduction in emissions fostered by the company.',
    offset:
      'Input the carbon offsets purchased by the customers to calculate the reduction in emissions fostered by the company.'
  }
};

export default tooltipMsg;
