import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Button, Tooltip as TooltipMui} from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import {Link, useNavigate} from 'react-router-dom';
import {ReactComponent as Crown} from '../../../../assets/crown.svg';
import {Data} from './MainTab';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from 'Shared/Layouts/Tooltip';
import Informatives from 'Shared/Informatives';
import LinearLoader from '../utility/LinearLoader';

interface DataGridProps {
  rows?: Data[];
  rowsPerPage: number;
}

export default function CustomerDataGrid({rows, rowsPerPage}: DataGridProps) {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: ' #',
      width: 20,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({id}) => {
        return <Box>{`${(id as number) + 1}`}</Box>;
      }
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 70,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({id}) => {
        return (
          <>
            {rows?.[id as number]?.canceled ? null : (
              <TooltipMui title='View'>
                <Link
                  to={`/customer/${rows?.[id as number]?.customerId}`}
                  state={rows?.[id as number]?.customerId}
                  style={
                    rows?.[id as number]?.canceled
                      ? {pointerEvents: 'none'}
                      : {flexWrap: 'nowrap'}
                  }
                >
                  <ArrowCircleRightOutlinedIcon sx={{color: '#677489'}} />
                </Link>
              </TooltipMui>
            )}
          </>
        );
      }
    },
    {
      field: 'none',
      headerName: '',
      width: 20,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      cellClassName: ({id}) =>
        rows?.[id as number]?.isGuest ? 'non-premium' : 'premium',
      renderCell: ({id}) => {
        return (
          <TooltipMui
            title={
              rows?.[id as number]?.canceled
                ? 'Cancelled'
                : rows?.[id as number]?.isGuest
                ? 'Guest'
                : 'Premium'
            }
          >
            <Crown />
          </TooltipMui>
        );
      }
    },
    {
      field: 'businessName',
      headerName: 'Company Name',
      width: 160,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      disableColumnMenu: true,
      renderCell: ({id}) => {
        return (
          <Box>
            <Box>{rows?.[id as number]?.businessName}</Box>
          </Box>
        );
      }
    },
    {
      field: ' ',
      headerName: ' ',
      width: 120,
      renderCell: ({id}) => {
        return (
          <>
            {(rows?.[id as number]?.paymentPending ||
              rows?.[id as number]?.callScheduled) &&
            !rows?.[id as number]?.canceled &&
            !rows?.[id as number]?.isDraft ? (
              <Button
                className='itp'
                sx={{
                  backgroundColor: '#A1D887',
                  width: '100%',
                  color: '#FFFFFF',
                  height: '22px',
                  padding: '4px 14px',
                  m: '2px',
                  font: 'normal normal 600 12px/16px Quicksand',
                  '&:hover': {
                    background:
                      'transparent linear-gradient(265deg, #3BB3C1 0%, #A1D887 100%) 0% 0% no-repeat padding-box'
                  },
                  transition: 'backgroundColor 1s'
                }}
                onClick={() => {
                  navigate(
                    `/customer/${rows?.[id as number]?.companyId}/payment`
                  );
                }}
              >
                Invite To Pay
              </Button>
            ) : null}
          </>
        );
      },
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center'
    },
    // {
    //   field: "Shared",
    //   headerName: "Shared",
    //   type: "number",
    //   width: 70,
    //   sortable: false,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: ({ id }) => {
    //     return (
    //       <ThumpUpIcon
    //         sx={{
    //           fontSize:"20px", color: rows?.[id as number]?.shared ? "#3BB3C1" : "#05406529",
    //         }}
    //       />
    //     );
    //   },
    //   disableColumnMenu: true,
    // },
    {
      field: 'entities',
      headerName: 'Outlets',
      width: 100,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true
    },
    {
      field: 'financialYear',
      headerName: 'Financial Years',
      width: 140,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      align: 'center'
    },
    {
      field: 'payment',
      headerName: 'Payment',
      width: 130,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      description: Informatives.customerListing.paymentPending,
      renderCell: ({id}) => {
        return rows?.[id as number]?.canceled ? (
          <CloseIcon color='error' />
        ) : !rows?.[id as number]?.paymentPending &&
          !rows?.[id as number]?.callScheduled ? (
          <DoneIcon color='success' />
        ) : (
          <DoneIcon color='disabled' />
        );
      }
    },
    {
      field: 'documents',
      headerName: 'Documentation',
      width: 130,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      description: Informatives.customerListing.documentPending,
      renderCell: ({id}) => {
        return rows?.[id as number]?.canceled ? (
          <CloseIcon color='error' />
        ) : rows?.[id as number]?.documentsPending ? (
          <DoneIcon color='disabled' />
        ) : (
          <DoneIcon color='success' />
        );
      }
    },
    {
      field: 'verified',
      headerName: 'Verification',
      width: 110,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      description: Informatives.customerListing.verificationPending,
      renderCell: ({id}) => {
        return rows?.[id as number]?.canceled ? (
          <CloseIcon color='error' />
        ) : !rows?.[id as number]?.verificationPending ? (
          <DoneIcon color='success' />
        ) : (
          <DoneIcon color='disabled' />
        );
      }
    },
    {
      field: 'footprint',
      headerName: 'Foot Printing',
      width: 120,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      description: Informatives.customerListing.footprintPending,
      renderCell: ({id}) => {
        return rows?.[id as number]?.canceled ? (
          <CloseIcon color='error' />
        ) : !rows?.[id as number]?.footprintPending ? (
          <DoneIcon color='success' />
        ) : (
          <DoneIcon color='disabled' />
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 90,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'statusMsg',
      disableColumnMenu: true,
      renderCell: ({id}) => {
        return (
          <Box
            sx={{
              backgroundColor: rows?.[id as number]?.canceled
                ? '#F9E3E2'
                : rows?.[id as number]?.status
                ? '#DEE9FC'
                : '#F8FAFC',
              color: rows?.[id as number]?.canceled
                ? '#8C2822'
                : rows?.[id as number]?.status
                ? '#2343A9'
                : 'black'
            }}
          >
            {rows?.[id as number]?.canceled
              ? 'Canceled'
              : rows?.[id as number]?.status
              ? 'Completed'
              : rows?.[id as number]?.paymentPending ||
                rows?.[id as number]?.documentsPending ||
                rows?.[id as number]?.verificationPending
              ? 'Pending'
              : 'In Progress'}
          </Box>
        );
      }
    },
    {
      field: 'Update',
      headerName: '',
      width: 10,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: ({id}) => {
        return (
          <Tooltip
            message={
              rows?.[id as number]?.canceled
                ? Informatives.customerListing.cancelled
                : rows?.[id as number]?.isDraft
                ? 'Saved As Draft'
                : rows?.[id as number]?.status
                ? 'Completed'
                : `${Math.floor(Math.random() * 5)} days to update Documents`
            }
          />
        );
      }
    },
    {
      field: 'FP',
      headerName: '',
      width: 90,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      renderCell: ({id}) => {
        return (
          <>
            {rows?.[id as number]?.canceled ? null : rows?.[id as number]
                ?.isDraft ? null : (
              <Button variant='text' className='btn-view'>
                <Link
                  to={{
                    pathname: `/customer/${
                      rows?.[id as number]?.customerId
                    }/footprint/`
                  }}
                  state={rows?.[id as number]?.customerId}
                >
                  {!!rows?.[id as number]?.isEdit ? <EditIcon /> : <AddIcon />}
                  {!!rows?.[id as number]?.isEdit ? 'Edit' : 'Add'}
                </Link>
              </Button>
            )}
          </>
        );
      }
    }
  ];

  return (
    <Box sx={{width: '100%'}}>
      {!rows ? (
        <LinearLoader />
      ) : (
        <DataGrid
          rows={rows as readonly any[]}
          columns={columns}
          className='dataGrid'
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 30
              }
            }
          }}
          localeText={{noRowsLabel: 'No Results Found'}}
          disableRowSelectionOnClick
          pagination
          disableColumnSelector
          // pageSizeOptions={[5, 10, 20]}
          autoHeight
        />
      )}
    </Box>
  );
}
