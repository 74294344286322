import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; file: any }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        {props.value === 100 ? (
          <Typography variant="body2" color="text.secondary">
            File Ready to Upload
          </Typography>
        ) : (
          <LinearProgress variant="determinate" {...props} />
        )}
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${
          props?.value ? props.value : 0
        }%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel(value: any, file: any) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={value.value} file={file} />
    </Box>
  );
}
