import React, {memo, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import axios from 'axios';
import ScopeFieldsView from './ScopeFieldsView';

type scope3ViewProps = {
  locationId: any;
  year?: any;
  customer: any;
  openOn: boolean;
};
const Scope3View = ({locationId, year, customer, openOn}: scope3ViewProps) => {
  const [scope3ViewData, setScope3ViewData] = useState<any>();

  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };
    if (openOn) {
      const fetchApi = async () => {
        if (locationId && year) {
          await axios
            .get(
              `${BACKEND_ENDPOINT}/footprinting/scope3?locationId=${locationId}&fromDate=${year?.slice(
                0,
                4
              )}-04-01 00:00:00&toDate=${year?.slice(5, 9)}-03-31 00:00:00`,
              config
            )
            .then((res) => {
              setScope3ViewData(res.data.scope3);
            });
        }
      };

      fetchApi().catch((err) => {
        console.log('errScope3View:', err);
      });
    }
  }, [locationId, year, openOn]);

  type Scope3TitleArrayType = {
    title: string;
    type: string[];
    subData: {sub: string; unit: string}[];
  }[];

  const Scope3TitleArray: Scope3TitleArrayType = [
    {
      title: 'Fresh water consumption',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {sub: 'Tap water', unit: 'Ltr'},
        {sub: 'Ultra pure water', unit: 'Ltr'},
        {sub: 'Decarbonised water', unit: 'Ltr'}
      ]
    },
    {
      title: 'Waste water generation',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [{sub: 'Waste water', unit: 'Ltr'}]
    },
    {
      title: 'Solid waste generation',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [{sub: 'Solid waste', unit: 'Kg'}]
    },
    {
      title: 'Laundry cleaned',
      type: ['hotel'],
      subData: [{sub: 'laundry cleaned', unit: 'Kg'}]
    }
  ];

  const category = customer?.category;

  return (
    <Box>
      <Box>
        {Scope3TitleArray.map((value, index) => {
          return (
            value?.type?.includes(category) && (
              <ScopeFieldsView
                key={index}
                year={year}
                title={value?.title}
                type={value?.type}
                subData={value?.subData}
                locationId={locationId}
                customer={customer}
                scopeData={scope3ViewData}
                scopeType='scope3'
              />
            )
          );
        })}
      </Box>
    </Box>
  );
};

export default memo(Scope3View);
