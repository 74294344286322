import {Box, Button, Tooltip} from '@mui/material';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {Link} from 'react-router-dom';
import LinearLoader from '../../utility/LinearLoader';
import moment from 'moment';

type reduction = {
  locationId: any;
  year?: any;
  customer: any;
  openOn: boolean;
};

const ReductionView = ({locationId, year, customer, openOn}: reduction) => {
  const [reductionData, setReductionData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;
  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    if (openOn) {
      const fetchApi = async () => {
        if (locationId && year) {
          await axios
            .get(
              `${BACKEND_ENDPOINT}/footprinting/reductions?type=locationYear&locationId=${locationId}&fromYear=${year?.slice(
                0,
                4
              )}&toYear=${year?.slice(5, 9)}`,
              config
            )
            .then((res) => {
              setIsLoading(true);
              setReductionData(res.data.data);
            })
            .then((res) => {
              setIsLoading(false);
            });
        }
      };

      fetchApi().catch((err) => {
        console.log('errScope1View:', err);
      });
    }
  }, [locationId, year, openOn]);

  return isLoading ? (
    <LinearLoader />
  ) : (
    <>
      <Box className='formData-view formSection'>
        <Box className='title-w-btn'>
          <Box className='data-title' id='fuleC'>
            Reduction
          </Box>
          <Box className='mline'></Box>
          <Button
            component={Link}
            to={`/customer/${customer?._id}/footprint/3/${year}/${locationId}`}
            state={{
              year,
              locationId,
              index: 3,
              customer
            }}
            disabled={
              customer?.appStatus === 'call scheduled' ||
              customer?.appStatus === 'invite and pay'
            }
          >
            <Tooltip
              title={
                customer?.appStatus === 'call scheduled' ||
                customer?.appStatus === 'invite and pay'
                  ? 'Please pay before you proceed'
                  : ''
              }
            >
              <>
                <EditIcon color='inherit' /> Edit Item
              </>
            </Tooltip>
          </Button>
        </Box>
        <Box className='carbon-section'>
          <Box className='cs-section head'>
            <Box>Initiator</Box>
            <Box>Unit</Box>
            <Box>Reduction</Box>
            <Box>Start Date</Box>
          </Box>
          {reductionData?.initiators.length === 0 ? (
            <Box className='cs-section'>
              <Box>No Reductions Done</Box>
            </Box>
          ) : (
            reductionData?.initiators?.map((value: any, index: number) => {
              return (
                <Box className='cs-section'>
                  <Box>{value?.name || index + 1}</Box>
                  <Box>{value?.unit || 'Kg'}</Box>
                  <Box>
                    {value?.initiatorValue.toLocaleString('en-IN') || 0}
                  </Box>
                  <Box>
                    {moment(value?.startDate).format('MMMM YYYY') || 'Apr 2023'}
                  </Box>
                </Box>
              );
            })
          )}
        </Box>
      </Box>
      <Box className='formData-view formSection'>
        <Box className='title-w-btn'>
          <Box className='data-title' id='fuleC'>
            Carbon Purchased
          </Box>
          <Box className='mline'></Box>
          <Button
            component={Link}
            to={`/customer/${customer?._id}/footprint/3/${year}/${locationId}`}
            state={{
              year,
              locationId,
              index: 3,
              customer
            }}
            disabled={
              customer?.appStatus === 'call scheduled' ||
              customer?.appStatus === 'invite and pay'
            }
          >
            <Tooltip
              title={
                customer?.appStatus === 'call scheduled' ||
                customer?.appStatus === 'invite and pay'
                  ? 'Please pay before you proceed'
                  : ''
              }
            >
              <>
                <EditIcon color='inherit' /> Edit Item
              </>
            </Tooltip>
          </Button>
        </Box>
        <Box className='carbon-section'>
          <Box className='cs-section head'>
            <Box>Initiator</Box>
            <Box>Unit</Box>
            <Box>Offsets</Box>
          </Box>
          <Box className='cs-section'>
            <Box>Carbon Purchased in the year</Box>
            <Box>{'Tco2'}</Box>
            <Box>{reductionData?.creditPurchased || 0}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReductionView;
