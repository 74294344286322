import {LinearProgress} from '@mui/material';
import React from 'react';

const LinearLoader = () => {
  return (
    <LinearProgress
      color='success'
      sx={{
        marginTop: '15px',
        backgroundColor: 'white',
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#a2d87b'
        }
      }}
    />
  );
};

export default LinearLoader;
