import {TextField} from '@mui/material';
import formattedAddress from 'Shared/Utils/getFormattedAddress';
import React from 'react';
import {usePlacesWidget} from 'react-google-autocomplete';

const apiKey = process.env.REACT_APP_GA;

const GoogleMaps: React.FC<{setState: any; index: number}> = ({
  setState,
  index
}) => {
  const handlePlaceSelected = (place: any) => {
    const {formatted_address, name, address_components} = place;
    setState(
      formattedAddress(formatted_address, name, address_components),
      index
    );
  };

  const {ref: materialRef} = usePlacesWidget({
    apiKey: apiKey,
    onPlaceSelected: (place) => {
      handlePlaceSelected(place);
    },
    options: {
      types: ['establishment'],
      fields: ['name', 'address_components', 'formatted_address'],
      componentRestrictions: {country: ['in']}
    }
  });

  return (
    <TextField
      inputRef={materialRef}
      sx={{width: '400px'}}
      label='Search Location'
      variant='outlined'
      defaultValue={''}
      onKeyDown={(event) => {
        if (
          event.code === 'ArrowDown' ||
          event.code === 'ArrowUp' ||
          event.code === 'Enter'
        ) {
          event.preventDefault();
        }
      }}
    />
  );
};

export default GoogleMaps;
