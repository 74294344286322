import {
  AlertColor,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from '@mui/material';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Scope1 from '../Scopes/Scope1';
import Scope2 from '../Scopes/Scope2';
import Scope3 from '../Scopes/Scope3';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {Link, useParams, useNavigate} from 'react-router-dom';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Year from '../../utility/Year';
import axios from 'axios';
import CancelConfirmModal from '../../utility/CancelConfirmModal';
import Modal from '../../utility/Modal';
import Reductions from '../Scopes/Reductions';
import LinearLoader from '../../utility/LinearLoader';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      className='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}} className='tab-container'>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

type FootPrintingPageProps = {
  setOpenSnackbar: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      status: AlertColor;
      message: string;
    }>
  >;
};

export default function FootPrintingPage({
  setOpenSnackbar
}: FootPrintingPageProps) {
  const [toggleChange, setToggleChange] = React.useState(true);

  const [value, setValue] = React.useState<number>();
  const [nextValue, setNextValue] = React.useState<number>();
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  const [footprintingStart, setFootprintingStart] = React.useState();
  const [footprintingEnd, setFootprintingEnd] = React.useState();
  const [citySelected, setCitySelected] = React.useState('');
  const [scopeStart, setScopeStart] = React.useState();
  const [scopeEnd, setScopeEnd] = React.useState();
  const [locationState, setLocationState] = React.useState<any>();

  const [isEdit, setEdit] = React.useState<boolean>();

  const [otherData, setOtherData] = React.useState<any>();
  const [locationIdState, setLocationId] = React.useState('');
  const [showDialog, setShowDialog] = React.useState(false);
  let navigate = useNavigate();

  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  const {id, locationId, index, year} = useParams();

  const submitClick = document?.getElementById('submit');
  React.useEffect(() => {
    setEdit(index ? true : false);
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      await axios
        .get(`${BACKEND_ENDPOINT}/user?customerId=${id}`, config)
        .then((res) => {
          setLocationState(res?.data);
        });
    };

    fetchApi().catch((err) => {
      console.log(err?.message);
    });
  }, []);

  React.useEffect(() => {
    setOtherData({
      locationId: locationId,
      year: year,
      index: index
    });
  }, []);

  React.useEffect(() => {
    setValue(otherData?.index ? otherData?.index : 0);
    setFootprintingStart(locationState?.footprintingPeriod[0]);
    setFootprintingEnd(locationState?.footprintingPeriod[1]);
    if (isEdit) {
      filterBasedOnLocationId(otherData?.locationId);
    }
  }, [locationState, otherData]);

  const filterBasedOnLocationId = (locationId: string) => {
    if (isEdit) {
      const city = locationState?.addresses?.filter((location: any) => {
        return location?._id === locationId;
      });
      setLocationId(locationId);
      return setCitySelected(city?.[0]?.city);
    }
  };

  const selectedCityFilter = locationState?.addresses
    ?.filter((location: any) => {
      return location?.city?.includes(citySelected ? citySelected : null);
    })
    .sort((a: any, b: any) => b.includeInScope - a.includeInScope);

  const cities: any = Array.from(
    new Set(
      locationState?.addresses?.map((el: any) => {
        return el.city;
      })
    )
  );

  React.useEffect(() => {
    if (!isEdit && selectedCityFilter?.[0]) {
      setLocationId(
        selectedCityFilter[0]?.includeInScope
          ? selectedCityFilter?.[0]?._id
          : ''
      );
    }
  }, [citySelected]);

  const handleSelect = (event: any) => {
    setCitySelected(event.target.value);
    setEdit(false);
  };

  const handleLocation = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | any
  ) => {
    if (newAlignment !== null) {
      setLocationId(newAlignment);
    }
  };

  const handleStartEnd = (start: any, end: any) => {
    setScopeStart(start);

    setScopeEnd(end);
  };

  const handleToggle = (event: React.SyntheticEvent, newValue: number) => {
    setNextValue(newValue);
    if (isDirty && toggleChange) {
      setShowDialog(true);
    } else {
      setValue(newValue);
      if (!toggleChange) {
        setToggleChange(true);
      }
      setIsDirty(false);
    }
  };
  React.useEffect(() => {
    if (
      id &&
      (value || value === 0) &&
      scopeStart &&
      scopeEnd &&
      locationIdState
    ) {
      navigate(
        `/customer/${id}/footprint/${value}/${scopeStart}-${scopeEnd}/${locationIdState}`
      );
    }
  }, [id, value, scopeStart, scopeEnd, locationIdState]);

  const handleCancel = () => {
    setShowDialog(false);
    if (toggleChange) {
      setToggleChange(false);
      setValue(nextValue);
      return;
    } else {
      navigate(isEdit ? `/customer/${id}` : '/customer/list');
    }
    return;
  };

  const handleTabToggle = () => {
    setIsDirty(false);
    if (submitClick) {
      submitClick.click();
    }
    setValue(nextValue);
    return setShowDialog(false);
  };

  const handleSave = () => {
    navigate(isEdit ? `/customer/${id}` : '/customer/list');
    if (submitClick) {
      submitClick.click();
    }
    setIsDirty(false);
    return setShowDialog(false);
  };

  return !locationState ? (
    <LinearLoader />
  ) : (
    <Box className='innerPage'>
      <Modal
        open={isDirty && showDialog}
        setOpen={setShowDialog}
        children={
          toggleChange ? (
            <CancelConfirmModal
              handleOk={handleTabToggle}
              handleCancel={handleCancel}
              textProps={{
                mainText:
                  'All unsaved data will be lost if not saved! Save the form before you leave!',
                cancelText: 'Do not save',
                confirmText: 'Save'
              }}
            />
          ) : (
            <CancelConfirmModal
              handleOk={handleSave}
              handleCancel={handleCancel}
              textProps={{
                mainText:
                  'All unsaved data will be lost if not saved! Save the form before you leave!',
                cancelText: 'Do not save',
                confirmText: 'Save'
              }}
            />
          )
        }
      />
      <Box>
        <Button
          className='back-btn'
          sx={{padding: '0', marginBottom: '10px'}}
          onClick={() => {
            setToggleChange(false);
            setShowDialog(true);
          }}
        >
          <Link
            to={isDirty ? '#' : !!isEdit ? `/customer/${id}` : '/customer/list'}
            // state={!!isEdit ? locationState : null}
          >
            <KeyboardArrowLeftIcon /> Back
          </Link>
        </Button>
      </Box>
      <h1 className='pageTitle' style={{marginBottom: '20px'}}>
        {!!isEdit ? 'Edit Foot Print' : 'Add Foot Print'}
      </h1>

      <Box sx={{display: 'flex'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0px 20px 0px 0px'
          }}
        >
          <Box
            sx={{
              margin: '0px 0px 10px 0px',
              fontSize: '16px',
              color: 'red'
            }}
          >
            {citySelected && locationState ? null : 'Select a City First'}
          </Box>
          <FormControl>
            <InputLabel id='demo-simple-select-label'>City</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={citySelected || ''}
              label='City'
              onChange={handleSelect}
              sx={{width: '150px', mb: '20px'}}
            >
              {!!cities[0] ? (
                cities.map((city: any, i: any) => {
                  return (
                    <MenuItem key={i} value={city}>
                      {city}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem key={0} value={''} disabled>
                  No City is Added
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        >
          <Box
            sx={{margin: '0px 0px 10px 0px', color: 'red', fontSize: '16px'}}
          >
            {scopeStart && scopeEnd && locationState ? null : 'Select a Year'}
          </Box>
          <Year
            fullYear={otherData?.year}
            startPeriod={footprintingStart}
            endPeriod={footprintingEnd}
            handleStartEnd={handleStartEnd}
          />
        </Box>
      </Box>
      <Box>
        {citySelected && (
          <ToggleButtonGroup
            color='primary'
            className='location-s'
            value={locationIdState || ''}
            exclusive
            onChange={handleLocation}
            aria-label='Platform'
          >
            {selectedCityFilter?.map((value: any, i: number) => {
              return (
                <Tooltip
                  key={i}
                  title={
                    value?.includeInScope ? (
                      `${value.title} ${value.addressLine1} ${value.addressLine2} ${value.city} ${value.state} ${value.zipCode}`
                    ) : (
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                      >
                        <span>{`Not Included in Scope `}</span>
                        <span>{`${value.title} ${value.addressLine1} ${value.addressLine2} ${value.city} ${value.state} ${value.zipCode}`}</span>
                      </Box>
                    )
                  }
                >
                  {value?.includeInScope ? (
                    <ToggleButton
                      key={i}
                      value={value._id}
                      disabled={!value?.includeInScope}
                      selected={
                        value?.includeInScope && locationIdState === value?._id
                      }
                    >
                      <Box>
                        <PinDropIcon />
                        <Typography>{value?.title}</Typography>
                      </Box>
                    </ToggleButton>
                  ) : (
                    <span>
                      <ToggleButton key={i} value={''} disabled={true}>
                        <Box>
                          <PinDropIcon />
                          <Typography>{value?.title}</Typography>
                        </Box>
                      </ToggleButton>
                    </span>
                  )}
                </Tooltip>
              );
            })}
          </ToggleButtonGroup>
        )}
      </Box>

      <Box className='tabs-default'>
        <Tabs
          className='tabui-rounded'
          value={Number(value)}
          onChange={(event, newValue) => {
            handleToggle(event, newValue);
          }}
        >
          <Tab
            label='Scope I'
            {...a11yProps(0)}
            disabled={!(locationId && scopeStart)}
          />
          <Tab
            label='Scope II'
            {...a11yProps(1)}
            disabled={!(locationId && scopeStart)}
          />
          <Tab
            label='Scope III'
            {...a11yProps(2)}
            disabled={!(locationId && scopeStart)}
          />
          <Tab
            label='Reductions'
            {...a11yProps(3)}
            disabled={!(locationId && scopeStart)}
          />
        </Tabs>
        <TabPanel value={Number(value)} index={0}>
          <Scope1
            locationId={locationIdState}
            companyId={locationState.companyId}
            scopeStart={scopeStart}
            scopeEnd={scopeEnd}
            setValue={setValue}
            toggleChange={toggleChange}
            natureOfbusiness={locationState?.category}
            setOpenSnackbar={setOpenSnackbar}
            setIsDirty={setIsDirty}
            isDirty={isDirty}
          />
        </TabPanel>
        <TabPanel value={Number(value)} index={1}>
          <Scope2
            locationId={locationIdState}
            companyId={locationState.companyId}
            scopeStart={scopeStart}
            scopeEnd={scopeEnd}
            setValue={setValue}
            natureOfbusiness={locationState?.category}
            setOpenSnackbar={setOpenSnackbar}
            setIsDirty={setIsDirty}
            isDirty={isDirty}
          />
        </TabPanel>
        <TabPanel value={Number(value)} index={2}>
          <Scope3
            setValue={setValue}
            otherData={otherData}
            locationId={locationIdState}
            scopeStart={scopeStart}
            scopeEnd={scopeEnd}
            natureOfbusiness={locationState?.category}
            setOpenSnackbar={setOpenSnackbar}
            locationState={locationState}
            setIsDirty={setIsDirty}
            isDirty={isDirty}
          />
        </TabPanel>
        <TabPanel value={Number(value)} index={3}>
          <Reductions
            locationId={locationIdState}
            scopeStart={scopeStart}
            scopeEnd={scopeEnd}
            natureOfbusiness={locationState?.category}
            setOpenSnackbar={setOpenSnackbar}
            locationState={locationState}
            setIsDirty={setIsDirty}
            isDirty={isDirty}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
