import React, {useEffect, useState} from 'react';
import {Box, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EmpRawCommute from '../table/EmpRawCommute';
import axios from 'axios';
import RawSupplyCommute from '../table/RawSupplyCommute';

type EmployeeProps = {
  empData: any;
  locationId: string;
  type: string;
  scopeEnd: string;
  scopeStart: string;
};

const EmpCommuteModal = ({
  empData,
  locationId,
  type,
  scopeEnd,
  scopeStart
}: EmployeeProps) => {
  const [updated, setUpdated] = useState(false);
  const [search, setSearch] = useState('');
  const [empRawDetails, setEmpRawDetails] = useState<any>('');

  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      await axios
        .get(
          `${BACKEND_ENDPOINT}/${
            type === 'emp' ? 'employeeCommute' : 'supplyChain'
          }?locationId=${locationId}&term=${search}&fromYear=${scopeStart}&toYear=${scopeEnd}`,
          config
        )
        .then((res) => {
          setEmpRawDetails(
            type === 'emp'
              ? res.data?.employeeCommute
                ? res.data?.employeeCommute
                : []
              : res?.data?.supplyChain
              ? res?.data?.supplyChain
              : []
          );
        });
    };

    fetchApi().catch((err) => {
      console.log('errEmp:', err);
    });
  }, [updated, search]);

  return (
    <Box className='emp-modal-popup'>
      <Box className='emp-title'>
        <Box>
          {`${
            empData?.employeeCommuteCount >= 0
              ? 'Employee Details List'
              : 'Supply Chain List'
          }`}
          <span className='sub-title'>
            {type === 'emp'
              ? `${empData?.employeeCommuteCount}/${empData?.totalCount} Employees Added`
              : `${empData?.supplyChainCount} Supply Chain Added`}
          </span>
        </Box>
        <Box>
          <TextField
            fullWidth
            className='search-input'
            placeholder='Search'
            type='search'
            value={search}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>
      {type === 'emp' ? (
        <EmpRawCommute empRawDetails={empRawDetails} setUpdated={setUpdated} />
      ) : (
        <RawSupplyCommute
          empRawDetails={empRawDetails}
          setUpdated={setUpdated}
        />
      )}
    </Box>
  );
};

export default EmpCommuteModal;
