import AWS from "aws-sdk/global";
import KMS from "aws-sdk/clients/kms";
import s3 from "aws-sdk/clients/s3";

/**
 * This class is used as a service of AWS
 *
 * @class Aws
 */
class Aws {
  /**
   *
   * Initialize the AWS common config
   * @private
   * @static
   * @memberof Aws
   */
  private awsConfig = {
    accessKeyId: process.env.REACT_APP_AMAZON_KEY,
    secretAccessKey: process.env.REACT_APP_AMAZON_SECRET,
    region: process.env.REACT_APP_AMAZON_REGION,
  };
  /**
   *
   * Initialize the S3 config
   * @private
   * @static
   * @memberof Aws
   */
  private s3Config = {
    params: { Bucket: process.env.REACT_APP_AMAZON_BUCKET },
    region: process.env.REACT_APP_AMAZON_REGION,
  };

  /**
   * This method is used to encrypt the text
   * @function encrypt
   * @memberof Aws
   */

  public encrypt = (buffer: String) => {
    AWS.config.update({ ...this.awsConfig });
    const kms = new KMS();

    return new Promise((resolve, reject) => {
      const params = {
        KeyId: "965d2884-b2cd-4d79-8773-6b1f57133300", // The identifier of the CMK to use for encryption. You can use the key ID or Amazon Resource Name (ARN) of the CMK, or the name or ARN of an alias that refers to the CMK.
        Plaintext: buffer, // The data to encrypt.
      };
      kms.encrypt(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.CiphertextBlob);
        }
      });
    });
  };

  /**
   * This method is used to decrypt the text
   * @function decrypt
   * @memberof Aws
   */

  public decrypt = (buffer: String) => {
    AWS.config.update({ ...this.awsConfig });
    const kms = new KMS();

    return new Promise((resolve, reject) => {
      const params = {
        CiphertextBlob: buffer, // The data to dencrypt.
      };
      kms.decrypt(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Plaintext);
        }
      });
    });
  };

  /**
   *
   * @param file
   */

  public uploadImageToS3 = (file: File) => {
    AWS.config.update({ ...this.awsConfig });
    const myBucket = new s3({
      ...this.s3Config,
    });

    const params: any = {
      ACL: "public-read",
      Body: file,
      Bucket: process.env.REACT_APP_AMAZON_BUCKET,
      Key: file.name,
    };

    return myBucket.putObject(params);
  };
}

export default new Aws();
