import {Box, Button, Menu, MenuItem, Typography} from '@mui/material';
import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import FileIcon from '../../../../../assets/document.svg';
import Modal from '../../utility/Modal';
import moment from 'moment';
import ViewDoc from '../../utility/ViewDoc';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CanceledModal from '../../utility/CanceledModal';
import LinearLoader from '../../utility/LinearLoader';
import getAppStatus from 'Shared/Utils/getAppStatus';
import PartnerConstant from 'Shared/Constants/PartnerConstant';

type InfoProp = {
  customer: any;
};

const GeneralInfo = ({customer}: InfoProp) => {
  const [open, setOpen] = React.useState(false);
  const [openCancel, setCancel] = useState(false);
  const [fileView, setFileView] = React.useState();
  const [typeName, setTypeName] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openAnchor = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (!openCancel) {
      setAnchorEl(null);
    }
  };

  const capitalizeSentence = (sentence: string) => {
    return sentence
      ?.split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const fileViewObject = [
    {
      name: 'Incorporation Certificate',
      file: customer?.incorporateCertificate
    },
    {
      name: 'Company PAN',
      file: customer?.identityProofDocument
    }
  ];

  const handleFileView = (Type: any) => {
    setOpen(true);
    setFileView(Type.file);
    setTypeName(Type.name);
  };

  const status = PartnerConstant.APP_STATUS;

  const PAYMENT_PENDING = getAppStatus(
    customer?.appStatus,
    status.PAYMENT_PENDING
  );

  const DOC_PENDING = getAppStatus(customer?.appStatus, status.DOC_PENDING);

  const VERIFICATION_PENDING = getAppStatus(
    customer?.appStatus,
    status.VERIFICATION_PENDING
  );

  const FOOTPRINTING_PENDING = getAppStatus(
    customer?.appStatus,
    status.FOOTPRINTING_PENDING
  );

  const COMPLETED = getAppStatus(customer?.appStatus, status.COMPLETED);

  const CANCELLED = getAppStatus(customer?.appStatus, status.CANCELLED);

  return !customer ? (
    <LinearLoader />
  ) : (
    <Box>
      <Box className='formData-view'>
        <Box className='data-title'>
          <Typography variant='h4'>Personal Info</Typography>
          <Button component={Link} to={`/customer/edit/${customer?._id}`}>
            <EditIcon color='inherit' /> Edit Item
          </Button>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '250px',
            right: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {customer?.appStatus === 'invite and pay' && !customer?.isDraft ? (
            <Button
              sx={{
                backgroundColor: '#A1D887',
                width: '121px',
                color: '#FFFFFF',
                height: '22px',
                padding: '4px 14px',
                font: 'normal normal 600 12px/16px Quicksand',
                '&:hover': {
                  background:
                    'transparent linear-gradient(265deg, #3BB3C1 0%, #A1D887 100%) 0% 0% no-repeat padding-box'
                },
                transition: 'backgroundColor 1s'
              }}
              onClick={() =>
                navigate(`/customer/${customer?.companyId}/payment`)
              }
            >
              Invite To Pay
            </Button>
          ) : null}

          <Box>
            <Button
              id='basic-button'
              aria-controls={openAnchor ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openAnchor ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon color='info' />
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem onClick={() => setCancel(true)}>
                Cancel the lead
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <Box className='form-view'>
          <Box className='form-data'>
            <Box className='label-title'>
              Name :{' '}
              <span>{`${customer?.firstName} ${customer?.lastName}`}</span>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>
              Mobile Number : <span>{customer?.phone}</span>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>
              Email ID : <span>{customer?.email}</span>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>
              Business Name : <span>{customer?.businessName}</span>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>
              Start Date :{' '}
              <span>
                {customer?.footprintingPeriod[0]
                  ? moment(customer?.footprintingPeriod[0]).format(
                      'Do MMMM YYYY'
                    )
                  : '-'}
              </span>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>
              End Date :{' '}
              <span>
                {customer?.footprintingPeriod[1]
                  ? moment(customer?.footprintingPeriod[1]).format(
                      'Do MMMM YYYY'
                    )
                  : '-'}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className='formData-view'>
        <Box className='data-title'>
          <Typography variant='h4'>Business Details</Typography>
          <Button
            component={Link}
            to={`/customer/edit/${customer?._id}`}
            state={customer}
          >
            <EditIcon color='inherit' /> Edit Item
          </Button>
        </Box>
        <Box className='form-view'>
          <Box className='form-data'>
            <Box className='label-title'>
              GST Number :{' '}
              <span>
                {customer?.taxIdentityNumber ? customer?.taxIdentityNumber : ''}
              </span>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>
              Category :{' '}
              <span>
                {customer?.category
                  ? capitalizeSentence(customer?.category)
                  : ''}
              </span>
            </Box>
          </Box>
          {customer?.category === 'hotel' && (
            <Box className='form-data'>
              <Box className='label-title'>
                Hotel Rating :{' '}
                <span>
                  {customer?.hotelRating
                    ? capitalizeSentence(customer?.hotelRating)
                    : ''}
                </span>
              </Box>
            </Box>
          )}
          <Box className='form-data'>
            <Box className='label-title'>
              Type of Business :{' '}
              <span>
                {customer?.typeOfBusiness
                  ? capitalizeSentence(customer?.typeOfBusiness)
                  : ''}
              </span>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>
              Nature of Business :{' '}
              <span>
                {customer?.natureOfBusiness === 'Pub or Bar'
                  ? customer?.natureOfBusiness || ''
                  : capitalizeSentence(customer?.natureOfBusiness) || ''}
              </span>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>
              Outlet Count :{' '}
              <span>
                {customer?.addresses ? customer?.addresses?.length : 0}
              </span>
            </Box>
          </Box>

          <Box className='form-data'>
            <Box className='label-title'>
              Pan Number : <span>{customer?.identityProof || ''}</span>
            </Box>
          </Box>
          <Box className='address-container'>
            {customer?.addresses
              ? customer?.addresses?.map((value: any, i: number) => {
                  return (
                    <Box key={i} className='address-data'>
                      <Box className='address-title'>
                        <h4>{`Outlet ${i + 1}`}</h4>
                      </Box>
                      <Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            Included in Scope :{' '}
                            <span>{value.includeInScope ? 'Yes' : 'No'}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            Outlet GST : <span>{value.branchGst || '-'}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            Outlet Name :{' '}
                            <span>{capitalizeSentence(value.title) || ''}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            Average Patrons per Month :{' '}
                            <span>{value.averageCustomer || ''}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            Address Line 1 :{' '}
                            <span>{value.addressLine1 || ''}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            Address Line 2 :{' '}
                            <span>{value.addressLine2 || ''}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            City : <span>{value.city || ''}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            State : <span>{value.state || ''}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            Country : <span>{value.country || ''}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            Pin Code : <span>{value.zipCode || ''}</span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            Built up Area :{' '}
                            <span>
                              {value?.builtUpArea
                                ? `${value?.builtUpArea} Sq mts`
                                : ''}
                            </span>
                          </Box>
                        </Box>
                        <Box className='form-data'>
                          <Box className='label-title'>
                            No of Employees :{' '}
                            <span>{value?.empCount || ''}</span>
                          </Box>
                        </Box>
                        {customer?.category === 'hotel' ? (
                          <>
                            <Box className='form-data'>
                              <Box className='label-title'>
                                Occupancy Rate per Month :{' '}
                                <span>{value?.occupancyRate || ''} %</span>
                              </Box>
                            </Box>
                            <Box className='form-data'>
                              <Box className='label-title'>
                                Total Number of Rooms :{' '}
                                <span>{value?.totalRooms || ''}</span>
                              </Box>
                            </Box>
                            <Box className='form-data'>
                              <Box className='label-title'>
                                Average Room Size :{' '}
                                <span>
                                  {value?.averageRoomBuildUp
                                    ? `${value?.averageRoomBuildUp} Sq mts`
                                    : ''}
                                </span>
                              </Box>
                            </Box>
                          </>
                        ) : customer.category === 'retail' ? (
                          <Box className='form-data'>
                            <Box className='label-title'>
                              Air Conditioned Area :{' '}
                              <span>
                                {value?.airConditioned === ''
                                  ? ''
                                  : value?.airConditioned === 'moreThan50'
                                  ? 'More than 50%'
                                  : 'Less then 50%'}
                              </span>
                            </Box>
                          </Box>
                        ) : customer.category === 'restaurant' ? (
                          <Box className='form-data'>
                            <Box className='label-title'>
                              Average Meals per Year:{' '}
                              <span>{value?.averageMeals || ''}</span>
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>
                  );
                })
              : null}
          </Box>
        </Box>
      </Box>
      <Box className='formData-view'>
        <Box className='data-title'>
          <Typography variant='h4'>Document Details</Typography>
          <Button
            component={Link}
            to={`/customer/edit/${customer?._id}`}
            state={customer}
          >
            <EditIcon color='inherit' /> Edit Item
          </Button>
        </Box>
        <Box className='form-view upload-doc'>
          {fileViewObject.map((Type, i) => {
            return (
              <Box key={i}>
                <Button
                  onClick={() => {
                    handleFileView(Type);
                  }}
                  className='form-data'
                >
                  <Box className='fileUpload'>
                    <Box>
                      <img src={FileIcon} alt='fileIcon' />
                    </Box>
                    <Box>
                      <Box className='fileTitle'>{Type?.name}</Box>
                      <Box className='fileType'>
                        {Type?.file
                          ? Type?.file?.split('/').pop()
                          : 'No Documents'}
                      </Box>
                    </Box>
                    <Box className='fileIcon'>
                      <UploadFileRoundedIcon color='inherit' />
                      <Box className='fileType'>Max size 5mb</Box>
                    </Box>
                  </Box>
                </Button>
              </Box>
            );
          })}
        </Box>
      </Box>
      {openCancel && (
        <Modal
          children={
            <CanceledModal
              setCancel={setCancel}
              companyId={customer?.companyId}
            />
          }
          open={openCancel}
          setOpen={setCancel}
        />
      )}
      {open && (
        <Modal
          children={<ViewDoc file={fileView} fileType={typeName} />}
          open={open}
          setOpen={setOpen}
        />
      )}
      <Box className='formData-view'>
        <Box className='data-title'>
          <Typography variant='h4'>Authentication Info</Typography>
        </Box>

        <Box className='form-view'>
          <Box className='form-data'>
            <Box className='label-title'>Payment :</Box>

            <Box
              sx={{
                backgroundColor: PAYMENT_PENDING ? '#F8FAFC' : '#DEE9FC'
              }}
            >
              <Box
                className='label-indicator'
                sx={{
                  color: PAYMENT_PENDING ? '#000000' : '#2343A9'
                }}
              >
                {PAYMENT_PENDING ? (
                  <>
                    <CloseIcon />
                    Pending
                  </>
                ) : (
                  <>
                    <DoneIcon />
                    Completed
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>Documentation :</Box>
            <Box
              sx={{
                backgroundColor: DOC_PENDING ? '#F8FAFC' : '#DEE9FC'
              }}
            >
              <Box
                className='label-indicator'
                sx={{
                  color: DOC_PENDING ? '#000000' : '#2343A9'
                }}
              >
                {DOC_PENDING ? (
                  <>
                    <CloseIcon />
                    Pending
                  </>
                ) : (
                  <>
                    <DoneIcon />
                    Completed
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>Verification :</Box>
            <Box
              sx={{
                backgroundColor: VERIFICATION_PENDING ? '#F8FAFC' : '#DEE9FC'
              }}
            >
              <Box
                className='label-indicator'
                sx={{
                  color: VERIFICATION_PENDING ? '#000000' : '#2343A9'
                }}
              >
                {VERIFICATION_PENDING ? (
                  <>
                    <CloseIcon />
                    Pending
                  </>
                ) : (
                  <>
                    <DoneIcon />
                    Completed
                  </>
                )}
              </Box>
            </Box>
          </Box>

          <Box className='form-data'>
            <Box className='label-title'>Foot Print :</Box>

            <Box
              sx={{
                backgroundColor: FOOTPRINTING_PENDING ? '#F8FAFC' : '#DEE9FC'
              }}
            >
              <Box
                className='label-indicator'
                sx={{
                  color: FOOTPRINTING_PENDING ? '#000000' : '#2343A9'
                }}
              >
                {FOOTPRINTING_PENDING ? (
                  <>
                    <CloseIcon />
                    Pending
                  </>
                ) : (
                  <>
                    <DoneIcon />
                    Completed
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>Status :</Box>
            <Box
              sx={{
                backgroundColor: COMPLETED ? '#DEE9FC' : '#F8FAFC'
              }}
            >
              <Box
                className='label-indicator'
                sx={{
                  color: COMPLETED ? '#2343A9' : '#000000'
                }}
              >
                {COMPLETED ? (
                  <Box className='label-indicator'>
                    <DoneIcon />
                    Completed
                  </Box>
                ) : (
                  <Box className='label-indicator'>
                    <CloseIcon />
                    Pending
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box className='form-data'>
            <Box className='label-title'>Canceled :</Box>
            <Box
              sx={{
                backgroundColor: CANCELLED ? '#FDD7D0' : '#F8FAFC'
              }}
            >
              <Box
                className='label-indicator'
                sx={{
                  color: CANCELLED ? '#BA3A21' : '#000000'
                }}
              >
                {CANCELLED ? (
                  <>
                    <DoneIcon />
                    Yes
                  </>
                ) : (
                  <>
                    <CloseIcon />
                    No
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralInfo;
