import React, {useEffect, useState} from 'react';
import {AlertColor, Box, Button, LinearProgress} from '@mui/material';
import axios from 'axios';
import ScopeFields from './ScopeFields';
import Informatives from 'Shared/Informatives';
import PartnerConstant from 'Shared/Constants/PartnerConstant';

type scope2Props = {
  locationId: string;
  companyId: string;
  scopeStart: any;
  scopeEnd: any;
  setValue: any;
  natureOfbusiness: string;
  setOpenSnackbar: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      status: AlertColor;
      message: string;
    }>
  >;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  isDirty: boolean;
};

const Scope2 = ({
  locationId,
  companyId,
  scopeStart,
  scopeEnd,
  setValue,
  natureOfbusiness,
  setOpenSnackbar,
  setIsDirty,
  isDirty
}: scope2Props) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  useEffect(() => {
    if (isDirty) {
      const autosave = setInterval(() => {
        document.getElementById('submit')?.click();
        setIsUpdate(true);
      }, PartnerConstant.autoSaveInterval);
      return () => {
        clearInterval(autosave);
      };
    }
  }, [isDirty]);

  type dataType = {
    _id?: string;
    index: string;
    category: string;
    subCategory?: string;
    type: string;
    value: string;
    metric: string;
    fromDate: string;
    toDate: string;
  };

  type scope2DataType = {
    locationId: string;
    companyId: string;
    data: dataType[];
  };

  const sampleScope2Data = {
    locationId: locationId,
    companyId: companyId,
    data: [
      {
        index: '',
        category: '',
        subCategory: '',
        type: '',
        value: '',
        metric: '',
        fromDate: '',
        toDate: ''
      }
    ]
  };

  const [scope2Data, setScope2Data] =
    useState<scope2DataType>(sampleScope2Data);

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };
    if (locationId && scopeStart) {
      const fetchApi = async () => {
        await axios
          .get(
            `${BACKEND_ENDPOINT}/footprinting/scope2?locationId=${locationId}&fromDate=${scopeStart}-04-01 00:00:00&toDate=${scopeEnd}-03-31 00:00:00`,
            config
          )
          .then((res) => {
            setIsUpdate(res?.data?.scope2 !== null);

            setScope2Data(isUpdate ? res?.data?.scope2 : sampleScope2Data);
          })
          .then(() => setIsLoading(false));
      };

      fetchApi().catch((err) => {
        console.log('errScope2:', err);
      });
    }
  }, [locationId, scopeStart, scopeEnd, isUpdate]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    scope2Data.data = [];
    scope2Data.companyId = companyId;

    for (let i = 0; i < e.target.length; i++) {
      if (
        e.target.elements[i].getAttribute('type') === 'number' &&
        e.target.elements[i].value
      ) {
        const getAttribute = (prop: string) => {
          return e.target.elements[i].getAttribute(prop);
        };
        const input: any = {
          index: getAttribute('id'),
          category: getAttribute('category'),
          subCategory: getAttribute('sub'),
          type: getAttribute('typename'),
          value: e.target.elements[i].value,
          metric: getAttribute('metric'),
          fromDate:
            getAttribute('yearly') === 'true'
              ? `1-${months[0]}-${scopeStart}`
              : getAttribute('id') >= 9
              ? `1-${months[getAttribute('id')]}-${scopeEnd}`
              : `1-${months[getAttribute('id')]}-${scopeStart}`,
          toDate:
            getAttribute('yearly') === 'true'
              ? `${date[11]}-${months[11]}-${scopeEnd}`
              : getAttribute('id') >= 9
              ? `${date[getAttribute('id')]}-${
                  months[getAttribute('id')]
                }-${scopeEnd}`
              : `${date[getAttribute('id')]}-${
                  months[getAttribute('id')]
                }-${scopeStart}`
        };

        let isExist = scope2Data?.data?.findIndex(function (currentValue) {
          return (
            currentValue?.category === input?.category &&
            currentValue?.type === input?.type &&
            currentValue?.index === input?.index
          );
        });

        if (isExist !== -1) {
          scope2Data.data[isExist] = input;
        } else {
          scope2Data?.data?.push(input);
        }
      }
    }

    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      const method = isUpdate ? 'put' : 'post';

      await axios
        .request({
          method: method,
          url: `${BACKEND_ENDPOINT}/footprinting/scope2`,
          data: scope2Data,
          headers: config.headers
        })
        .then(() => {
          // setSubmitted(true);
          if (isSubmit) {
            setValue(2);
            setIsDirty(false);
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: 'Scope 2 fields added successfully!'
              };
            });
          } else {
            setIsDirty(false);
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: 'Scope 2 fields Auto Saved successfully!'
              };
            });
          }
        });
    };
    fetchApi().catch((err) =>
      setOpenSnackbar((prev) => {
        return {
          ...prev,
          open: true,
          status: 'error',
          message: err?.message
        };
      })
    );
  };

  const date = [
    '30',
    '31',
    '30',
    '31',
    '31',
    '30',
    '31',
    '30',
    '31',
    '30',
    '28',
    '30'
  ];

  const months = [
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar'
  ];

  type Scope2TitleArrayType = {
    title: string;
    type: string[];
    subData: {sub: string; unit: string; desc: string}[];
  }[];

  const Scope2TitleArray: Scope2TitleArrayType = [
    {
      title: 'Electricity',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'electricity',
          unit: 'kWh',
          desc: Informatives?.scope2?.electricity
        }
      ]
    }
  ];

  return isLoading && locationId && scopeStart ? (
    <LinearProgress
      color='success'
      sx={{
        marginTop: '15px',
        backgroundColor: 'white',
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#a2d87b'
        }
      }}
    />
  ) : (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          {Scope2TitleArray.map((value, index) => {
            return (
              value?.type?.includes(natureOfbusiness) && (
                <ScopeFields
                  key={index}
                  title={value?.title}
                  type={value?.type}
                  subData={value?.subData}
                  locationId={locationId}
                  scopeStart={scopeStart}
                  scopeData={scope2Data}
                  setData={setScope2Data}
                  setIsDirty={setIsDirty}
                  loading={isLoading}
                />
              )
            );
          })}
        </Box>
        <Box className='df-right'>
          <Button
            type='submit'
            id='submit'
            sx={{cursor: 'none', pointerEvents: 'none'}}
          />
          <Button
            type='submit'
            className='btn secondary-btn'
            onClick={() => setIsSubmit(true)}
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Scope2;
