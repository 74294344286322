import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps, AlertColor} from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';

interface SnackbarProps {
  open: {open: boolean; status: AlertColor; message: string};
  setOpen: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      status: AlertColor;
      message: string;
    }>
  >;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function Snackbars({open, setOpen}: SnackbarProps) {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen((value) => {
      return {
        ...value,
        open: false
      };
    });
  };
  return (
    <Stack spacing={2} sx={{width: '100%'}}>
      <Snackbar
        open={open?.open}
        autoHideDuration={5000}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        TransitionComponent={Slide}
        onClose={() => {
          handleClose();
        }}
      >
        <Alert
          icon={
            open?.status === 'success' ? (
              <CheckIcon fontSize='inherit' />
            ) : open?.status === 'error' ? (
              <CloseIcon fontSize='inherit' />
            ) : open?.status === 'warning' ? (
              <ErrorOutlineIcon fontSize='inherit' />
            ) : (
              <InfoIcon fontSize='inherit' />
            )
          }
          severity={open?.status || 'info'}
          sx={{width: '100%'}}
        >
          {open?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
