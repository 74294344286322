import {CircularProgress} from '@mui/material';
import React from 'react';

const Loader: React.FC = () => {
  return (
    <div className='loader'>
      <CircularProgress color='success' className='flex' />
    </div>
  );
};

export default Loader;
