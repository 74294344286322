import React, { useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

// import {useForm} from 'react-hook-form';

const MyProfileModal = () => {
  // const [partnerProfileData, setPartnerProfileData] = useState<any>();
  const { user } = useAuth0();
  const userProfile = user?.picture;
  // const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  // const {register} = useForm({
  //   defaultValues: {
  //     email: '',
  //     mobile: ''
  //   }
  // });
  useEffect(() => {
    // let config = {
    //   headers: {
    //     authorization: `Bearer ${
    //       localStorage.getItem('token') ? localStorage.getItem('token') : ''
    //     }`
    //   }
    // };
    // const fetchApi = async () => {
    //   await axios
    //     .get(`${BACKEND_ENDPOINT}/partner/edit`, config)
    //     .then((res) => {
    //       setPartnerProfileData(res?.data);
    //     });
    // };
  }, []);
  return (
    <>
      <Box className='profilePopup'>
        <Box className='userCard'>
          <img
            src={userProfile}
            alt='avatorImg'
            className='userImg'
            referrerPolicy='no-referrer'
          />
          <Box>UserName</Box>
          <Typography>Id: 998787</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <TextField name='email' label='Email'></TextField>
          <TextField name='mobile' label='Mobile Number'></TextField>
          <Button className='secondary-btn'>Save</Button>
        </Box>
      </Box>
    </>
  );
};

export default MyProfileModal;
