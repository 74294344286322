import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const NotificationPopper = () => {
  return (
    <Paper className="notificationDropdown">
      <Box position="sticky" className="notification-title" sx={{ top: 0 }}>
        Recent Activity
      </Box>
      <Box sx={{}} className="notification-listing">
        <Box className="notification-list">
          {/* <img src="" alt="" /> */}
          <Box className="notification-data">
            <Box className="nd-heading">Heading</Box>
            <Box className="nd-subhead">News</Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default NotificationPopper;
