const removeFalsyKeys = (obj: any) => {
  return Object.entries(obj)
    .filter(([_, val]) => {
      if (Array.isArray(val)) {
        const filteredArr = val.filter((el) => {
          if (typeof el === 'object' && el !== null) {
            const filteredObj = removeFalsyKeys(el);
            if (Object.keys(filteredObj).length === 0) return false;
            return true;
          } else {
            return !!el;
          }
        });
        if (filteredArr.length === 0) return false;
        return true;
      } else if (typeof val === 'object' && val !== null) {
        const filteredObj = removeFalsyKeys(val);
        if (Object.keys(filteredObj).length === 0) return false;
        return true;
      } else {
        return !!val;
      }
    })
    .reduce((acc: any, [key, val]) => {
      if (Array.isArray(val)) {
        acc[key] = val.filter((el) => !!el);
      } else if (typeof val === 'object' && val !== null) {
        acc[key] = removeFalsyKeys(val);
      } else {
        acc[key] = val;
      }
      return acc;
    }, {});
};

export default removeFalsyKeys;
