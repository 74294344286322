import React, {useEffect, useState} from 'react';
import {AlertColor, Box, Button, LinearProgress} from '@mui/material';
import axios from 'axios';
import Modal from '../../utility/Modal';
import CancelConfirmModal from '../../utility/CancelConfirmModal';
import ScopeFields from './ScopeFields';
import Informatives from 'Shared/Informatives';
import PartnerConstant from 'Shared/Constants/PartnerConstant';
import {ScopeTitleArrayType} from './Interface/Scope1';

type scope1Props = {
  locationId: string;
  companyId: string;
  scopeStart: any;
  scopeEnd: any;
  setValue: any;
  toggleChange: boolean;
  natureOfbusiness: string;
  setOpenSnackbar: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      status: AlertColor;
      message: string;
    }>
  >;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  isDirty: boolean;
};

const Scope1 = ({
  locationId,
  companyId,
  scopeStart,
  scopeEnd,
  setValue,
  toggleChange,
  natureOfbusiness,
  setOpenSnackbar,
  setIsDirty,
  isDirty
}: scope1Props) => {
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [refrigerant, setRefrigerant] = React.useState<
    {sub: string; unit: string; desc: string}[]
  >([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isUpdate, setIsUpdate] = useState(false);

  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  type dataType = {
    index: string | number;
    category: string;
    subCategory?: string;
    type: string;
    value: string;
    metric: string;
    fromDate: string;
    toDate: string;
  };

  type scope1DataType = {
    locationId: string;
    companyId: string;
    data: dataType[];
  };

  useEffect(() => {
    if (isDirty) {
      const autosave = setInterval(() => {
        document.getElementById('submit')?.click();
        setIsUpdate(true);
      }, PartnerConstant.autoSaveInterval);
      return () => {
        clearInterval(autosave);
      };
    }
  }, [isDirty]);

  const sampleScope1Data = {
    locationId: locationId,
    companyId: companyId,
    data: [
      {
        index: '',
        category: '',
        subCategory: '',
        type: '',
        value: '',
        metric: '',
        fromDate: '',
        toDate: ''
      }
    ]
  };

  const [scope1Data, setScope1Data] =
    useState<scope1DataType>(sampleScope1Data);

  const date = [
    '30',
    '28',
    '30',
    '31',
    '31',
    '30',
    '31',
    '30',
    '31',
    '30',
    '28',
    '30'
  ];

  const months = [
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar'
  ];

  const refrigerantOptions = [
    {
      sub: 'HCFC-22/R22',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HCFC22
    },
    {
      sub: 'CFC-11/R11',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.CFC11R11
    },
    {
      sub: 'CFC-12/R12',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.CFC12R12
    },
    {sub: 'CFC-13', unit: 'Kg', desc: Informatives?.scope1?.refrigerant?.CFC13},
    {
      sub: 'CFC-113',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.CFC113
    },
    {
      sub: 'CFC-114',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.CFC114
    },
    {
      sub: 'CFC-115',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.CFC115
    },
    {
      sub: 'Halon-1211',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Halon1211
    },
    {
      sub: 'Halon-1301',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Halon1301
    },
    {
      sub: 'Halon-2402',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Halon2402
    },
    {sub: 'CCl₄', unit: 'Kg', desc: Informatives?.scope1?.refrigerant?.CCl4},
    {sub: 'CH3Br', unit: 'Kg', desc: Informatives?.scope1?.refrigerant?.CH3Br},
    {
      sub: 'CH3CCl3',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.CH3CCl3
    },
    {
      sub: 'HCFC-123',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HCFC123
    },
    {
      sub: 'HCFC-141b',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HCFC141b
    },
    {
      sub: 'HCFC-142b',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HCFC142b
    },
    {
      sub: 'HCFC-225ca',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HCFC225ca
    },
    {
      sub: 'HCFC-225cb',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HCFC225cb
    },
    {
      sub: 'HCFC 21',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HCFC21
    },
    {
      sub: 'HFC-23',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC23
    },
    {
      sub: 'HFC-32',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC32
    },
    {
      sub: 'HFC-41',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC41
    },
    {
      sub: 'HFC-125',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC125
    },
    {
      sub: 'HFC-134',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC134
    },
    {
      sub: 'HFC-134a',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC134a
    },
    {
      sub: 'HFC-143',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC143
    },
    {
      sub: 'HFC-143a',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC143a
    },
    {
      sub: 'HFC-152',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC152
    },
    {
      sub: 'HFC-152a',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC152a
    },
    {
      sub: 'HFC-161',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC161
    },
    {
      sub: 'HFC-227ea',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC227ea
    },
    {
      sub: 'HFC-236cb',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC236cb
    },
    {
      sub: 'HFC-236ea',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC236ea
    },
    {
      sub: 'HFC-236fa',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC236fa
    },
    {
      sub: 'HFC-245ca',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC245ca
    },
    {
      sub: 'HFC-245fa',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC245fa
    },
    {
      sub: 'HFC-365mfc',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC365mfc
    },
    {
      sub: 'HFC-43-10mee',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFC4310mee
    },
    {
      sub: 'Sulfur hexafluoride',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Sulfurhexafluoride
    },
    {
      sub: 'Nitrogen trifluoride',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Nitrogentrifluoride
    },
    {
      sub: 'PFC-14',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.PFC14
    },
    {
      sub: 'PFC-116',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.PFC116
    },
    {
      sub: 'PFC-218',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.PFC218
    },
    {
      sub: 'PFC-318',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.PFC318
    },
    {
      sub: 'PFC-31-10',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.PFC3110
    },
    {
      sub: 'PFC-41-12',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.PFC4112
    },
    {
      sub: 'PFC-51-14',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.PFC5114
    },
    {
      sub: 'PCF-91-18',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.PCF9118
    },
    {
      sub: 'Trifluoromethyl sulfur pentafluoride',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant
        ?.Trifluoromethylsulfurpentafluoride
    },
    {
      sub: 'Perfluorocyclopropane',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Perfluorocyclopropane
    },
    {
      sub: 'HFE-125',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE125
    },
    {
      sub: 'HFE-134',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE134
    },
    {
      sub: 'HFE-143e',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE143e
    },
    {
      sub: 'HCFE-235da2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HCFE235da2
    },
    {
      sub: 'HFE-245cb2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE245cb2
    },
    {
      sub: 'HFE-245fa2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE245fa2
    },
    {
      sub: 'HFE-347mcc3',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE347mcc3
    },
    {
      sub: 'HFE-347pcf2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE347pcf2
    },
    {
      sub: 'HFE-356pcc3',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE356pcc3
    },
    {
      sub: 'HFE-449sl (HFE-7100)',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE449sl_HFE7100
    },
    {
      sub: 'HFE-569sf2 (HFE-7200)',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE569sf2_HFE7200
    },
    {
      sub: 'HFE-43-10pccc124 (H-Galden 1040x)',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE4310pccc124_HGalden1040x
    },
    {
      sub: 'HFE-236ca12 (HG-10)',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE236ca12_HG10
    },
    {
      sub: 'HFE-338pcc13 (HG-01)',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE338pcc13_HG01
    },
    {
      sub: 'HFE-227ea',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE227ea
    },
    {
      sub: 'HFE-236ea2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE236ea2
    },
    {
      sub: 'HFE-236fa',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE236fa
    },
    {
      sub: 'HFE-245fa1',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE245fa1
    },
    {
      sub: 'HFE 263fb2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE263fb2
    },
    {
      sub: 'HFE-329mcc2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE329mcc2
    },
    {
      sub: 'HFE-338mcf2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE338mcf2
    },
    {
      sub: 'HFE-347mcf2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE347mcf2
    },
    {
      sub: 'HFE-356mec3',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE356mec3
    },
    {
      sub: 'HFE-356pcf2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE356pcf2
    },
    {
      sub: 'HFE-356pcf3',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE356pcf3
    },
    {
      sub: 'HFE 365mcf3',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE365mcf3
    },
    {
      sub: 'HFE-374pc2',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.HFE374pc2
    },
    {
      sub: 'PFPMIE',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.PFPMIE
    },
    {
      sub: 'Chloroform',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Chloroform
    },
    {
      sub: 'Methylene chloride',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Methylenechloride
    },
    {
      sub: 'Methyl chloride',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Methylchloride
    },
    {
      sub: 'Halon-1201',
      unit: 'Kg',
      desc: Informatives?.scope1?.refrigerant?.Halon1201
    },
    {sub: 'R-32', unit: 'Kg', desc: Informatives?.scope1?.refrigerant?.R32}
  ];

  const findRefrigerant = (data: any) => {
    const filteredData = data?.filter((value: any, id: number) => {
      return value?.category === 'Fuel consumption by refrigerant';
    });

    const set = [
      ...new Set(
        filteredData?.map((item: any) => {
          const desc = refrigerantOptions.find(
            (option) => item.type === option.sub
          );

          return JSON.stringify({
            sub: item?.type,
            unit: item?.metric,
            desc: desc?.desc
          });
        })
      )
    ].map((item: any) => JSON.parse(item));

    return set;
  };

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    if (locationId && scopeStart) {
      const fetchApi = async () => {
        await axios
          .get(
            `${BACKEND_ENDPOINT}/footprinting/scope1?locationId=${locationId}&fromDate=${scopeStart}-04-01 00:00:00&toDate=${scopeEnd}-03-31 23:59:59`,
            config
          )
          .then((res) => {
            setIsUpdate(res?.data?.scope1 !== null);
            setScope1Data(isUpdate ? res?.data?.scope1 : sampleScope1Data);
            setRefrigerant(findRefrigerant(res?.data?.scope1?.data));
          })
          .then(() => setIsLoading(false));
      };
      fetchApi().catch((err) => {
        console.log('errScope1:', err);
      });
    } else {
      setScope1Data(sampleScope1Data);
    }
  }, [locationId, scopeStart, isUpdate]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    scope1Data.data = [];
    scope1Data.companyId = companyId;

    for (let i = 0; i < e.target.length; i++) {
      if (
        e.target.elements[i].getAttribute('type') === 'number' &&
        e.target.elements[i].value
      ) {
        const getAttribute = (prop: string) => {
          return e.target.elements[i].getAttribute(prop);
        };

        const input: any = {
          index: getAttribute('id'),
          category: getAttribute('category'),
          subCategory: getAttribute('sub'),
          type: getAttribute('typename'),
          value: e.target.elements[i].value,
          metric: getAttribute('metric'),
          fromDate:
            getAttribute('yearly') === 'true'
              ? `1-${months[0]}-${scopeStart}`
              : getAttribute('id') >= 9
              ? `1-${months[getAttribute('id')]}-${scopeEnd}`
              : `1-${months[getAttribute('id')]}-${scopeStart}`,
          toDate:
            getAttribute('yearly') === 'true'
              ? `${date[11]}-${months[11]}-${scopeEnd}`
              : getAttribute('id') >= 9
              ? `${date[getAttribute('id')]}-${
                  months[getAttribute('id')]
                }-${scopeEnd}`
              : `${date[getAttribute('id')]}-${
                  months[getAttribute('id')]
                }-${scopeStart}`
        };

        let isExist = scope1Data?.data?.findIndex(function (currentValue) {
          return (
            currentValue?.category === input?.category &&
            currentValue?.type === input?.type &&
            currentValue?.index === input?.index
          );
        });

        if (isExist !== -1) {
          scope1Data.data[isExist] = input;
        } else {
          scope1Data?.data?.push(input);
        }
      }
    }

    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      const method = isUpdate ? 'put' : 'post';

      await axios
        .request({
          method: method,
          url: `${BACKEND_ENDPOINT}/footprinting/scope1`,
          data: scope1Data,
          headers: config.headers
        })
        .then(() => {
          if (isSubmit) {
            setValue(1);
            setIsDirty(false);
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: 'Scope 1 fields added successfully!'
              };
            });
          } else {
            setIsDirty(false);
            setOpenSnackbar((prev) => {
              return {
                ...prev,
                open: true,
                status: 'success',
                message: 'Scope 1 fields Auto Saved successfully!'
              };
            });
          }
        });
    };
    fetchApi().catch((err) =>
      setOpenSnackbar((prev) => {
        return {
          ...prev,
          open: true,
          status: 'error',
          message: err?.message
        };
      })
    );
  };

  const Scope1TitleArray: ScopeTitleArrayType = [
    {
      title: 'Fuel consumption in kitchen',
      type: ['hotel', 'restaurant'],
      subData: [
        {sub: 'coal', unit: 'Kg', desc: Informatives?.scope1?.kitchen?.coal},
        {sub: 'LPG', unit: 'Kg', desc: Informatives?.scope1?.kitchen?.LPG}
      ]
    },
    {
      title: 'Fuel consumption by company owned vehicle',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'diesel',
          unit: 'Ltr',
          desc: Informatives?.scope1?.companyOwnedVehicle?.diesel
        },
        {
          sub: 'petrol',
          unit: 'Ltr',
          desc: Informatives?.scope1?.companyOwnedVehicle?.petrol
        },
        {
          sub: 'LPG',
          unit: 'Kg',
          desc: Informatives?.scope1?.companyOwnedVehicle?.LPG
        },
        {
          sub: 'CNG',
          unit: 'Ltr',
          desc: Informatives?.scope1?.companyOwnedVehicle?.CNG
        }
      ]
    },
    {
      title: 'Fuel consumption by diesel generator',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: 'diesel',
          unit: 'Ltr',
          desc: Informatives?.scope1?.dieselGenerator?.diesel
        }
      ]
    },
    {
      title: 'Fuel consumption by refrigerant',
      type: ['hotel', 'restaurant', 'retail'],
      selectOption: {
        options: refrigerantOptions,
        setData: setRefrigerant,
        label: 'Choose Refrigerant'
      },
      subData: refrigerant
    },
    {
      title: 'Fuel consumption by fire extinguisher (CO2 type)',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [
        {
          sub: `CO2`,
          unit: 'Kg',
          desc: Informatives?.scope1?.fireExtinguisher?.CO2
        }
      ]
    }
  ];

  // const handleChange = (event: SelectChangeEvent<typeof refrigerant>) => {
  //   const {
  //     target: {value}
  //   } = event;
  //   setRefrigerant(typeof value === 'string' ? value.split(',') : value);
  // };

  return isLoading && locationId && scopeStart ? (
    <LinearProgress
      color='success'
      sx={{
        marginTop: '15px',
        backgroundColor: 'white',
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#a2d87b'
        }
      }}
    />
  ) : (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          {Scope1TitleArray.map((value, index) => {
            return (
              value?.type?.includes(natureOfbusiness) && (
                <ScopeFields
                  key={index}
                  title={value?.title}
                  type={value?.type}
                  subData={value?.subData}
                  locationId={locationId}
                  scopeStart={scopeStart}
                  scopeData={scope1Data}
                  setData={setScope1Data}
                  setIsDirty={setIsDirty}
                  loading={isLoading}
                  value={value}
                />
              )
            );
          })}
        </Box>
        <Box className='df-right'>
          <Button
            type='submit'
            id='submit'
            sx={{cursor: 'none', pointerEvents: 'none'}}
          />
          <Button
            type='submit'
            className='btn secondary-btn'
            onClick={() => setIsSubmit(true)}
          >
            Save
          </Button>
        </Box>
        {openSaveModal && (
          <Modal
            open={openSaveModal}
            setOpen={setOpenSaveModal}
            children={
              <CancelConfirmModal
                setOpen={setOpenSaveModal}
                textProps={{
                  mainText: `All unsaved data will be lost if not saved! Save the form before you leave! `,
                  cancelText: `Do not save`,
                  confirmText: `Save`
                }}
                handleOk={() => handleSubmit}
              />
            }
          />
        )}
      </form>
    </Box>
  );
};

export default Scope1;
