import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import {Box} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Link, NavLink} from 'react-router-dom';
import {Badge, Button, Menu, MenuItem, Popover} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LOGO from '../../../../assets/logo.svg';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import NotificationPopper from '../utility/NotificationPopper';
import MenuIcon from '@mui/icons-material/Menu';
import Modal from '../utility/Modal';
import LogoutImg from '../../../../assets/LogOut.svg';
import CancelConfirmModal from '../utility/CancelConfirmModal';
import {useAuth0} from '@auth0/auth0-react';
import MyProfileModal from '../utility/MyProfileModal';

const NavbarCon: React.FC = () => {
  const [openLogOut, setOpenLogOut] = useState<boolean>(false);
  const [, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openProfile, setOpenProfile] = useState<boolean>(false);

  const {logout} = useAuth0();
  const [anchorElSetting, setAnchorElSetting] =
    React.useState<null | HTMLElement>(null);

  const openSetting = Boolean(anchorElSetting);

  const handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSetting(event.currentTarget);
  };
  const handleCloseSetting = () => {
    setAnchorElSetting(null);
  };

  const handlePopper = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const logoutHandle = () => {
    localStorage.removeItem('isLogged');
    localStorage.removeItem('token');
    logout({returnTo: window.location.origin});
  };

  return (
    <>
      <AppBar position='sticky' className='header'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <Box className='logo'>
            <Link to='/'>
              <img src={LOGO} alt='LOGO' />
            </Link>
          </Box>

          <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleDrawerToggle}
              color='default'
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: {xs: 'none', md: 'flex'},
              justifyContent: 'space-evenly',
              gap: 2
            }}
          >
            <NavLink
              className='header-buttons'
              to='/'
              style={{
                textDecoration: 'none'
              }}
            >
              <IconButton size='large'>
                <HomeSharpIcon />
              </IconButton>
            </NavLink>
            <NavLink
              className='header-buttons'
              to={'/customer/list'}
              style={{
                textDecoration: 'none'
              }}
            >
              <IconButton size='large'>
                <PersonAddIcon />
              </IconButton>
            </NavLink>
            <Link
              className='header-buttons'
              to={'#'}
              style={{
                textDecoration: 'none'
              }}
            >
              <IconButton size='large'>
                <Badge badgeContent={null} color='error'>
                  <NotificationsIcon onClick={handlePopper} />
                </Badge>
              </IconButton>
            </Link>
            <Link
              className='header-buttons'
              to={'#'}
              style={{
                textDecoration: 'none'
              }}
            >
              <>
                <Button
                  id='basic-button'
                  aria-controls={openSetting ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={openSetting ? 'true' : undefined}
                  onClick={(e) => {
                    handleClickSetting(e);
                  }}
                >
                  <SettingsIcon />
                </Button>
                <Menu
                  id='basic-menu'
                  className='siteMenu-dropdown'
                  anchorEl={anchorElSetting}
                  open={openSetting}
                  onClose={handleCloseSetting}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  sx={{whiteSpace: 'nowrap'}}
                >
                  <MenuItem
                    sx={{
                      backgroundColor: '#3BB3C1',
                      color: '#FFFFFF',
                      '&.Mui-disabled': {
                        opacity: 1
                      }
                    }}
                    disabled
                    disableRipple
                  >
                    Setting
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to='#'
                    onClick={() => {
                      handleCloseSetting();
                      setOpenProfile(true);
                    }}
                  >
                    My Profile
                  </MenuItem>
                  {/* <MenuItem
                    component={Link}
                    to="/billing"
                    onClick={handleCloseSetting}
                  >
                    Billing
                  </MenuItem> */}
                </Menu>
              </>
            </Link>
            <Link
              className='header-buttons'
              to={'#'}
              style={{
                textDecoration: 'none'
              }}
            >
              <IconButton
                size='large'
                onClick={() => {
                  setOpenLogOut(true);
                }}
              >
                <PowerSettingsNewIcon />
              </IconButton>
            </Link>
          </Box>
        </Box>
      </AppBar>
      <Box component='nav'>
        {/* <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          logout({ returnTo: window.location.origin })
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none", zIndex: "2" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "240",
            },
          }}
        >
          {navLink.map((item, i) => (
            <Link
              className="header-buttons"
              key={i}
              to={item.link}
              style={{
                textDecoration: "none",
                color: activeNav === item.id ? "#024064" : "#99B1C7",
              }}
            >
              <IconButton
                key={i}
                size="large"
                value={item.id}
                onClick={handleNav}
                sx={{}}
              >
                <Badge badgeContent={item.id === 3 ? 0 : null} color="error">
                  {item.icon}
                </Badge>
              </IconButton>
            </Link>
          ))}
        </Drawer> */}
      </Box>

      <Modal
        open={openLogOut}
        setOpen={setOpenLogOut}
        children={
          <CancelConfirmModal
            setOpen={setOpenLogOut}
            img={LogoutImg}
            textProps={{
              mainText: `Ready to leave? Please check any pending tasks or unsaved documents
          before leaving!`,
              cancelText: `Cancel`,
              confirmText: `Sign Out`
            }}
            handleOk={logoutHandle}
          />
        }
      />

      {openProfile && (
        <Modal
          open={openProfile}
          setOpen={setOpenProfile}
          children={<MyProfileModal />}
        />
      )}
      {open && (
        <Popover
          id='simple-popover'
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <NotificationPopper />
        </Popover>
      )}
    </>
  );
};
export default NavbarCon;
