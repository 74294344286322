import {ToggleButtonGroup, ToggleButton} from '@mui/material';
import React from 'react';

type ToggleButtonProps = {
  handleAlignment: any;
  alignment: any;
  toggleValues: any[];
  type?: string;
};

const ToggleButtonComp = ({
  handleAlignment,
  alignment,
  toggleValues,
  type
}: ToggleButtonProps) => {
  return (
    <ToggleButtonGroup
      className='location-s'
      value={type ? alignment : alignment.value}
      exclusive
      onChange={handleAlignment}
      aria-label='text alignment'
    >
      {toggleValues.map((value, i) => {
        return (
          <ToggleButton
            key={i}
            value={type ? value.value : value}
            aria-label='centered'
            selected={
              type ? alignment === value.value : alignment.value === value.value
            }
          >
            {value?.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default ToggleButtonComp;
