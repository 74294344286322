import {
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useForm, useFieldArray, Controller} from 'react-hook-form';
import LinearLoader from '../utility/LinearLoader';

type EmpRawProps = {
  empRawDetails: any;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
};

const EmpRawCommute = ({empRawDetails, setUpdated}: EmpRawProps) => {
  const [editIndex, setEditIndex] = useState<any>(null);
  let config = {
    headers: {
      authorization: `Bearer ${
        localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }`
    }
  };
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  const {control, register, setValue, watch, trigger} = useForm({
    defaultValues: {
      empRawDetails: [
        {
          employeeId: '',
          firstName: '',
          lastName: '',
          dateOfJoining: '',
          phone: '',
          email: '',
          mode: '',
          workingDays: '',
          homeAddress: '',
          officeAddress: ''
        }
      ]
    },
    shouldFocusError: false,
    mode: 'onChange'
  });

  useEffect(() => {
    setValue('empRawDetails', empRawDetails);
  }, [empRawDetails]);

  const modeOfTransport = [
    {
      name: 'Regular Bus'
    },
    {
      name: 'By Walk / Stay on Property / Bicycle'
    },
    {
      name: 'Auto Petrol'
    },
    {
      name: 'Auto CNG'
    },
    {
      name: 'Auto LPG'
    },
    {
      name: 'Passenger Train'
    },
    {
      name: 'Local Train'
    },
    {
      name: 'Bike or Scooter'
    },
    {
      name: 'Electric Scooter'
    },
    {
      name: 'Small Car on Petrol'
    },
    {
      name: 'Small Car on CNG'
    },
    {
      name: 'Medium Car on Petrol'
    },
    {
      name: 'Medium Car on CNG'
    },
    {
      name: 'Medium Car on LPG'
    },
    {
      name: 'Medium Car on Diesel'
    },
    {
      name: 'Large Car on Petrol'
    },
    {
      name: 'Large Car on CNG'
    },
    {
      name: 'Large Car on Diesel'
    }
  ];
  const workingInWeeks = [
    {
      name: '1'
    },
    {
      name: '2'
    },
    {
      name: '3'
    },
    {
      name: '4'
    },
    {
      name: '5'
    },
    {
      name: '6'
    },
    {
      name: '7'
    }
  ];

  const handleDeleteEmp = (data: any, i: number) => {
    const fetchApi = async () => {
      await axios
        .delete(`${BACKEND_ENDPOINT}/employeeCommute`, {
          data: {_id: data?._id},
          headers: config.headers
        })
        .then((res) => {
          remove(i);
          setUpdated((prev) => !prev);
        });
    };
    fetchApi().catch((err) => {
      console.log('err:', err);
    });
    setEditIndex(null);
  };

  const handleUpdateEmp = (data: any, i: number) => {
    const empData = data?.empRawDetails?.[i];

    if (!trigger()) {
      return;
    }

    const fetchApi = async () => {
      await axios
        .put(`${BACKEND_ENDPOINT}/employeeCommute`, empData, config)
        .then((res) => {
          setUpdated((prev) => !prev);
        });
    };
    fetchApi().catch((err) => {
      console.log('err:', err);
    });

    setEditIndex(null);
  };

  const {fields, remove} = useFieldArray({control, name: 'empRawDetails'});

  return !empRawDetails ? (
    <LinearLoader />
  ) : (
    <TableContainer sx={{width: '100%'}}>
      <Table stickyHeader>
        <TableHead>
          <TableRow style={{backgroundColor: '#f5f5f5', height: '35px'}}>
            <TableCell>#</TableCell>
            <TableCell>Employee Code</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Date of Joining</TableCell>
            <TableCell>Working days in a week</TableCell>
            <TableCell>Mode of Transport</TableCell>
            <TableCell>Home Address</TableCell>
            <TableCell>Office Address</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item: any, index: number) => {
            return editIndex === index ? (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <Controller
                    name={`empRawDetails.${index}.employeeId`}
                    control={control}
                    defaultValue={item.employeeId}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        {...register(`empRawDetails.${index}.employeeId`)}
                        label='Employee Code'
                        variant='outlined'
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`empRawDetails.${index}.firstName`}
                    control={control}
                    defaultValue={item.firstName}
                    rules={{
                      required: 'First Name is Required'
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        {...register(`empRawDetails.${index}.firstName`)}
                        label='First Name *'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`empRawDetails.${index}.lastName`}
                    control={control}
                    defaultValue={item.lastName}
                    rules={{
                      required: 'Last Name is Required'
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        {...register(`empRawDetails.${index}.lastName`)}
                        label='Last Name *'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`empRawDetails.${index}.email`}
                    control={control}
                    defaultValue={item.lastName}
                    rules={{
                      required: false,
                      minLength: {
                        value: 5,
                        message: 'Email ID Should be more than 4 letters'
                      },
                      maxLength: {
                        value: 320,
                        message: 'Email ID Should not be more than 320 letters'
                      },
                      validate: {
                        reg: (value) => {
                          if (!value) return;
                          return (
                            RegExp(
                              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
                            ).test(value) || 'Please Enter Valid Email ID'
                          );
                        }
                      }
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        {...register(`empRawDetails.${index}.email`)}
                        label='Email'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`empRawDetails.${index}.phone`}
                    control={control}
                    rules={{
                      required: false,
                      validate: {
                        reg: (value) => {
                          if (!value) return;
                          return (
                            RegExp(/^[6-9][0-9]{9}$/).test(value) ||
                            'Please Enter 10 Digit Mobile Number'
                          );
                        }
                      }
                    }}
                    defaultValue={item.phone}
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <TextField
                        type='number'
                        value={value?.replace(/\D/g, '')}
                        onChange={onChange}
                        label='Mobile'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>

                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name={`empRawDetails.${index}.dateOfJoining`}
                      control={control}
                      rules={{
                        required: 'Date of Joining is Required'
                      }}
                      render={({
                        field: {onChange, value},
                        fieldState: {error}
                      }) => (
                        <DesktopDatePicker
                          value={moment(value).format() || ''}
                          label='Date of joining *'
                          inputFormat='MMM YYYY'
                          openTo='year'
                          views={['year', 'month']}
                          onChange={onChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </TableCell>

                <TableCell>
                  <Controller
                    name={`empRawDetails.${index}.workingDays`}
                    control={control}
                    defaultValue=''
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <FormControl error={!!error ? true : false}>
                        <InputLabel id='category'>
                          Working Days In A Week *
                        </InputLabel>
                        <Select
                          id='typeOfBusiness'
                          labelId='category'
                          label='Working Days In A Week *'
                          value={value || ''}
                          {...register(`empRawDetails.${index}.workingDays`, {
                            validate: {
                              noValue: (value) => {
                                return (
                                  value !== '' || 'Working Days is Required'
                                );
                              }
                            }
                          })}
                          onChange={onChange}
                          variant='outlined'
                        >
                          {workingInWeeks.map((item, i) => {
                            return (
                              <MenuItem key={i} value={item.name}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {!!error ? error?.message : null}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`empRawDetails.${index}.mode`}
                    control={control}
                    defaultValue=''
                    render={({
                      field: {onChange, value},
                      fieldState: {error}
                    }) => (
                      <FormControl error={error ? true : false}>
                        <InputLabel id='category'>
                          Mode of Transport *
                        </InputLabel>
                        <Select
                          id='typeOfBusiness'
                          labelId='category'
                          label='Mode of Transport *'
                          value={value || ''}
                          {...register(`empRawDetails.${index}.mode`, {
                            validate: {
                              noValue: (value) => {
                                return (
                                  value !== '' ||
                                  'Mode of Transport is Required'
                                );
                              }
                            }
                          })}
                          onChange={onChange}
                          variant='outlined'
                        >
                          {modeOfTransport.map((item, i) => {
                            return (
                              <MenuItem key={i} value={item.name}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          {error ? error?.message : null}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`empRawDetails.${index}.homeAddress`}
                    control={control}
                    defaultValue={item.homeAddress}
                    rules={{
                      required: 'Home Address is Required'
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        label='Home Address *'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={`empRawDetails.${index}.officeAddress`}
                    control={control}
                    defaultValue={item.officeAddress}
                    rules={{
                      required: 'Office Address is Required'
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        type='text'
                        {...field}
                        label='Office Address *'
                        variant='outlined'
                        error={!!error}
                        helperText={!!error ? error?.message : null}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    onClick={async () => {
                      if (await trigger()) {
                        handleUpdateEmp(watch(), index);
                      }
                    }}
                  >
                    Save
                  </Button>
                </TableCell>
                <TableCell>
                  <Button onClick={() => setEditIndex(null)}>Cancel</Button>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.employeeId}</TableCell>
                <TableCell>{item.firstName}</TableCell>
                <TableCell>{item.lastName}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>
                  {moment(item.dateOfJoining).format('MMM YYYY')}
                </TableCell>
                <TableCell>{item.workingDays}</TableCell>
                <TableCell>{item.mode}</TableCell>
                <TableCell>{item.homeAddress}</TableCell>
                <TableCell>{item.officeAddress}</TableCell>
                <TableCell>
                  <Button onClick={() => setEditIndex(index)}>Edit</Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      handleDeleteEmp(item, index);
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          {empRawDetails?.length === 0 && (
            <TableRow key={0}>
              <TableCell style={{textAlign: 'center'}} colSpan={12}>
                No Records Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmpRawCommute;
