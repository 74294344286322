import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ViewTab from './ViewTab';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const ViewComponent = () => {
  const { id } = useParams();

  const [locationState, setLocationState] = useState();

  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };

    const fetchApi = async () => {
      await axios
        .get(`${BACKEND_ENDPOINT}/user?customerId=${id}`, config)
        .then((res) => {
          setLocationState(res.data);
        });
    };

    fetchApi().catch((err) => {
      console.log(err?.message);
    });
  }, []);

  return (
    <Box className='innerPage'>
      <Box>
        <Button
          className='back-btn'
          sx={{ padding: '0', marginBottom: '10px' }}
        >
          <Link to='/customer/list'>
            <KeyboardArrowLeftIcon /> Back
          </Link>
        </Button>
      </Box>
      <h1 className='pageTitle'>Consumer Details</h1>
      <Box className='viewTabs'>
        <ViewTab customer={locationState} />
      </Box>
    </Box>
  );
};

export default ViewComponent;
