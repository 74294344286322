/* eslint-disable array-callback-return */
import React, {memo, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import axios from 'axios';
import ScopeFieldsView from './ScopeFieldsView';

type scope2ViewProps = {
  locationId: any;
  year?: string;
  customer: any;
  openOn: boolean;
};
const Scope2View = ({locationId, year, customer, openOn}: scope2ViewProps) => {
  const [scope2ViewData, setScope2ViewData] = useState<any>();
  const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND;

  // const valueCheck = (arr: any[]) => {
  //   let mainArr: any[] = [];
  //   mainArr = arr.map((x: any) => x);

  //   return (mainArr = Array.from({ length: 12 }, (_, i) => {
  //     const obj = mainArr[i] ?? { value: "-" };
  //     obj.value = obj.value ?? "-";
  //     return obj;
  //   }));
  // };

  useEffect(() => {
    let config = {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }`
      }
    };
    if (openOn) {
      const fetchApi = async () => {
        if (locationId && year) {
          await axios
            .get(
              `${BACKEND_ENDPOINT}/footprinting/scope2?locationId=${locationId}&fromDate=${year?.slice(
                0,
                4
              )}-04-01 00:00:00&toDate=${year?.slice(5, 9)}-03-31 00:00:00`,
              config
            )
            .then((res) => {
              setScope2ViewData(res.data.scope2);
            });
        }
      };

      fetchApi().catch((err) => {
        console.log('errScope2View:', err);
      });
    }
  }, [locationId, year, openOn]);

  type Scope2TitleArrayType = {
    title: string;
    type: string[];
    subData: {sub: string; unit: string}[];
  }[];

  const Scope2TitleArray: Scope2TitleArrayType = [
    {
      title: 'Electricity',
      type: ['hotel', 'restaurant', 'retail'],
      subData: [{sub: 'electricity', unit: 'kWh'}]
    }
  ];

  const category = customer?.category;

  return (
    <Box>
      <Box>
        {Scope2TitleArray.map((value, index) => {
          return (
            value?.type?.includes(category) && (
              <ScopeFieldsView
                key={index}
                year={year}
                title={value?.title}
                type={value?.type}
                subData={value?.subData}
                locationId={locationId}
                customer={customer}
                scopeData={scope2ViewData}
                scopeType='scope2'
              />
            )
          );
        })}
      </Box>
    </Box>
  );
};

export default memo(Scope2View);
