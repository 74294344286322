import React, {useEffect, useState} from 'react';
import Scope1View from '../Scopes/Scope1View';
import Scope3View from '../Scopes/Scope3View';
import Scope2View from '../Scopes/Scope2View';
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PinDropIcon from '@mui/icons-material/PinDrop';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';

import moment from 'moment';
import ScopeInfo from './ScopeInfo';
import ReductionView from '../Scopes/ReductionView';

type footprintingProps = {
  customer: any;
};

const FootPrintingView = ({customer}: footprintingProps) => {
  const [citySelected, setCitySelected] = React.useState('');
  const [startDate, setStartDate] = React.useState<any>();
  const [endDate, setEndDate] = React.useState<any>();
  const [locationId, setLocationId] = React.useState('');
  const [openOnClick, setOpenOnClick] = React.useState<boolean[]>([]);
  const [openDatePicker, setOpenDatePicker] = useState<{
    from: boolean;
    to: boolean;
  }>({
    from: false,
    to: false
  });
  const [selectedYear, setSelectedYear] = React.useState<string>();
  const [selectYearOptions, setSelectYearOptions] = useState<string[]>([]);

  const selectedCityFilter: any = customer?.addresses
    .filter((location: any) => {
      return location?.city?.includes(citySelected ? citySelected : null);
    })
    .sort((a: any, b: any) => b.includeInScope - a.includeInScope);

  const cities: any = Array.from(
    new Set(
      customer?.addresses?.map((el: any) => {
        return el.city;
      })
    )
  );

  const handleSelectCity = (event: any) => {
    setCitySelected(event.target.value);
  };

  React.useEffect(() => {
    if (selectedCityFilter?.[0]) {
      setLocationId(selectedCityFilter[0]?._id || '');
    }
    if (selectYearOptions) {
      setSelectedYear(selectYearOptions[0]);
    }
  }, [citySelected && selectYearOptions]);

  useEffect(() => {
    if (selectedCityFilter[0]) {
      setLocationId(
        selectedCityFilter[0]?.includeInScope ? selectedCityFilter[0]?._id : ''
      );
    }
  }, [citySelected]);

  useEffect(() => {
    setSelectedYear(selectYearOptions[0]);
  }, [selectYearOptions]);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | any
  ) => {
    if (newAlignment !== null) {
      setLocationId(newAlignment);
    }
  };

  const handleSelect = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | any
  ) => {
    if (newAlignment !== null) {
      setSelectedYear(newAlignment);
    }
  };

  useEffect(() => {
    let years = [];
    let StartDate = moment(startDate).year();

    let EndDate = moment(endDate).year();

    if (startDate && endDate) {
      for (let year: any = StartDate; year <= EndDate - 1; year++) {
        let value = `${year}-${year + 1}`;
        years.push(value);
      }
      setSelectYearOptions(years);
    }
  }, [startDate, endDate]);

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      className='footprint-view'
    >
      <Box display='flex' flexDirection='column' className='leftColum'>
        {/* {!selectedYear && <Box>Please Select Year </Box>}
        {!locationId && <Box>Please Select Location </Box>} */}
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          marginBottom='20px'
          mr='10px'
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Footprinting From'
              open={customer?.isDraft ? false : openDatePicker?.from}
              onClose={() =>
                setOpenDatePicker({...openDatePicker, from: false})
              }
              inputFormat='YYYY'
              openTo='year'
              views={['year']}
              disabled={
                !customer?.isDraft || !!customer?.footprintingPeriod[1]
                  ? false
                  : true
              }
              minDate={customer?.footprintingPeriod[0] || ''}
              maxDate={
                moment(endDate).subtract(1, 'year') ||
                customer?.footprintingPeriod[1] ||
                ''
              }
              value={startDate || ''}
              onChange={(event) => {
                setStartDate(event?.toString());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onClick={() =>
                    setOpenDatePicker({...openDatePicker, from: true})
                  }
                  error={false}
                  InputLabelProps={{
                    shrink: startDate ? true : false
                  }}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              open={openDatePicker?.to}
              onClose={() => setOpenDatePicker({...openDatePicker, to: false})}
              label='Footprinting To'
              inputFormat='YYYY'
              openTo='year'
              views={['year']}
              disabled={
                !!customer?.footprintingPeriod[1] && startDate ? false : true
              }
              minDate={
                moment(startDate).add(1, 'year') ||
                customer?.footprintingPeriod[0]
              }
              maxDate={moment() || ''}
              value={endDate || ''}
              onChange={(event) => {
                setEndDate(event?.toString());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onClick={() =>
                    setOpenDatePicker({...openDatePicker, to: true})
                  }
                  error={false}
                  InputLabelProps={{
                    shrink: endDate ? true : false
                  }}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <FormControl>
            <InputLabel id='demo-simple-select-label'>City</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              defaultValue=''
              value={citySelected || ''}
              label='City'
              onChange={handleSelectCity}
              sx={{width: '150px', mb: '20px'}}
            >
              {!!cities[0] ? (
                cities?.map((city: any, i: any) => {
                  return (
                    <MenuItem key={i} value={city}>
                      {city}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem key={0} value={''} disabled>
                  No City is Added
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>

        <Box>
          {citySelected && (
            <ToggleButtonGroup
              color='primary'
              className='location-s'
              value={locationId}
              exclusive
              onChange={handleAlignment}
              aria-label='Platform'
              sx={{textTransform: 'none'}}
            >
              {selectedCityFilter?.map((value: any, i: number) => {
                return (
                  <Tooltip
                    key={i}
                    title={
                      value?.includeInScope ? (
                        `${value.title} ${value.addressLine1} ${value.addressLine2} ${value.city} ${value.state} ${value.zipCode}`
                      ) : (
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='center'
                        >
                          <span>{`Not Included in Scope `}</span>
                          <span>{`${value.title} ${value.addressLine1} ${value.addressLine2} ${value.city} ${value.state} ${value.zipCode}`}</span>
                        </Box>
                      )
                    }
                  >
                    {value?.includeInScope ? (
                      <ToggleButton
                        key={i}
                        value={value._id}
                        disabled={!value?.includeInScope}
                        selected={
                          value?.includeInScope && locationId === value?._id
                        }
                      >
                        <Box>
                          <PinDropIcon />
                          <Typography>{value?.title}</Typography>
                        </Box>
                      </ToggleButton>
                    ) : (
                      <span>
                        <ToggleButton key={i} value={''} disabled={true}>
                          <Box>
                            <PinDropIcon />
                            <Typography>{value?.title}</Typography>
                          </Box>
                        </ToggleButton>
                      </span>
                    )}
                  </Tooltip>
                );
              })}
            </ToggleButtonGroup>
          )}
        </Box>
        <ScopeInfo />
      </Box>
      <Box className='rightColum'>
        {selectYearOptions && (
          <ToggleButtonGroup
            color='primary'
            className='location-s'
            value={selectedYear || ''}
            exclusive
            onChange={handleSelect}
            aria-label='Platform'
          >
            {selectYearOptions?.map((value: any, i: number) => {
              return (
                <ToggleButton key={i} value={value}>
                  <Box>
                    <Typography>{value}</Typography>
                  </Box>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        )}
        <Accordion
          disabled={!!locationId && !!selectedYear ? false : true}
          onChange={() => {
            setOpenOnClick((prev) => {
              const updated = [...prev];
              updated[0] = !prev[0];
              return updated;
            });
          }}
          sx={{mb: '20px'}}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            sx={{backgroundColor: '#99B1C7', color: '#FFFFFF'}}
          >
            <Typography>Scope 1</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Scope1View
              locationId={locationId}
              year={selectedYear}
              customer={customer}
              openOn={openOnClick[0]}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!!locationId && !!selectedYear ? false : true}
          onChange={() => {
            setOpenOnClick((prev) => {
              const updated = [...prev];
              updated[1] = !prev[1];
              return updated;
            });
          }}
          sx={{mb: '20px'}}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
            sx={{backgroundColor: '#99B1C7', color: '#FFFFFF'}}
          >
            <Typography>Scope 2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Scope2View
              locationId={locationId}
              year={selectedYear}
              customer={customer}
              openOn={openOnClick[1]}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!!locationId && !!selectedYear ? false : true}
          onChange={() => {
            setOpenOnClick((prev) => {
              const updated = [...prev];
              updated[2] = !prev[2];
              return updated;
            });
          }}
          sx={{mb: '20px'}}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel3a-content'
            id='panel3a-header'
            sx={{backgroundColor: '#99B1C7', color: '#FFFFFF'}}
          >
            <Typography>Scope 3</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Scope3View
              locationId={locationId}
              year={selectedYear}
              customer={customer}
              openOn={openOnClick[2]}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!!locationId && !!selectedYear ? false : true}
          onChange={() => {
            setOpenOnClick((prev) => {
              const updated = [...prev];
              updated[3] = !prev[3];
              return updated;
            });
          }}
          sx={{mb: '20px'}}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel3a-content'
            id='panel3a-header'
            sx={{backgroundColor: '#99B1C7', color: '#FFFFFF'}}
          >
            <Typography>Reduction</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReductionView
              locationId={locationId}
              year={selectedYear}
              customer={customer}
              openOn={openOnClick[3]}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default FootPrintingView;
