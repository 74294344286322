import React from 'react';
import ReactDOM from 'react-dom/client';
// import "./index.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Auth0Provider} from '@auth0/auth0-react';
import {getConfig} from './config';
import {CssBaseline} from '@mui/material';
import history from './utils/history';

const onRedirectCallback = (appState: any) => {
  history.push(appState?.returnTo || window.location.pathname);
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  redirectUri: `${window.location.origin}`,
  responseType: 'token id_token',
  onRedirectCallback
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <CssBaseline />
    <Auth0Provider {...providerConfig} userType='Partner'>
      <App />
    </Auth0Provider>
  </BrowserRouter>
);

reportWebVitals();
