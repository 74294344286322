import {Box, Autocomplete, TextField} from '@mui/material';
import React from 'react';
import ScopeSingleField from './ScopeSingleField';
import {singleTitleArray} from './Interface/Scope1';

type ScopeFieldsProps = {
  title: string;
  type: string[];
  subData: {sub: string; unit: string; desc: string}[];
  locationId: string;
  scopeStart: string;
  scopeData: any;
  setData: any;
  refrigerantOptions?: {sub: string; unit: string; desc: string}[];
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  value?: singleTitleArray;
};

const ScopeFields = ({
  title,
  type,
  subData,
  locationId,
  scopeStart,
  scopeData,
  setData,
  setIsDirty,
  loading,
  value
}: ScopeFieldsProps) => {
  return (
    <Box className='formData-view formSection'>
      <Box className='data-title'>{title}</Box>
      <Box className='carbon-section'>
        {value?.selectOption && (
          <Autocomplete
            multiple
            id='tags-standard'
            options={value?.selectOption?.options || []}
            sx={{width: '500px'}}
            getOptionLabel={(option) => option?.sub}
            onChange={(_, event) => {
              value.selectOption?.setData([...event]);
            }}
            isOptionEqualToValue={(option, value) =>
              option?.sub === (value?.sub || '')
            }
            disableCloseOnSelect={true}
            disableClearable
            value={value?.subData}
            filterSelectedOptions
            blurOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label={value?.selectOption?.label}
              />
            )}
          />
        )}
        {subData?.map(
          (
            subdataSingle: {sub: string; unit: string; desc: string},
            index: number
          ) => (
            <ScopeSingleField
              key={index}
              category={title}
              fieldData={subdataSingle}
              locationId={locationId}
              scopeStart={scopeStart}
              scopeData={scopeData}
              setData={setData}
              setIsDirty={setIsDirty}
              // loading={loading}
            />
          )
        )}
      </Box>
    </Box>
  );
};

export default ScopeFields;
