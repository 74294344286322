import {Box, Button, Typography} from '@mui/material';
import React from 'react';

type CancelConfirmProps = {
  setOpen?: any;
  img?: any;
  textProps: {
    mainText: string;
    cancelText?: String;
    confirmText: string;
    subText?: string;
  };
  handleOk?: any;
  handleCancel?: any;
};

const CancelConfirmModal = ({
  setOpen,
  img,
  textProps,
  handleOk,
  handleCancel
}: CancelConfirmProps) => {
  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
      >
        <img src={img} alt={img} />
        <Typography
          className='lo-title'
          sx={{
            textAlign: 'center',
            margin: textProps.subText
              ? '10px 0px 0px 0px !important'
              : '30px 0px'
          }}
        >
          {textProps.mainText}
        </Typography>
        <Typography className='lo-subtitle' sx={{textAlign: 'center'}}>
          {textProps?.subText}
        </Typography>
        <Box>
          <Button className='secondary-btn' onClick={handleOk}>
            {textProps.confirmText}
          </Button>
          {textProps.cancelText && (
            <Button
              className='btn-primary'
              onClick={() => {
                !!setOpen && setOpen(false);
                handleCancel();
              }}
            >
              {textProps.cancelText}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CancelConfirmModal;
